import React, { useState } from "react";
import { makePath } from "@admin/utils/makePath";
import { MainBlockWithFewBannerBannersModel, MainPageModel } from "@admin/domain/uniquePages/models/MainPageModel";
import { v4 as uuid } from "uuid";
import { type UniquePageProps } from "../../index";
import Sortable from "@admin/components/Sortable";
import AddBannerModal from "../Common/AddBannerModal";
import { Language } from "@admin/domain/languages/Languages";
import Input from "@admin/components/Input";
import MultiLanguages from "@admin/components/MultiLanguage";

export default function MainPageMainBlockWithFewBanners({ page }: UniquePageProps<MainPageModel>) {
    const bannersInit = (page.contentObject.main_block_with_few_banner__banners || []).map((value) => {
        return { ...value, id: uuid() };
    });

    const [showModal, setShowModal] = useState(false);
    const [banners, setBanners] = useState(bannersInit);
    const [editableBanner, setEditableBanner] = useState<MainBlockWithFewBannerBannersModel | undefined>();

    const addHandle = (data: MainBlockWithFewBannerBannersModel) => {
        setBanners((prev) => [...prev, data]);
    };

    const editHandle = (data: MainBlockWithFewBannerBannersModel) => {
        setBanners((prev) =>
            prev.map((value) => {
                if (value.id === data.id) value = data;
                return value;
            }),
        );
    };

    return (
        <div className="mb-10">
            <h4 className="mb-5">Основной блок со слайдером с двойными баннерами</h4>

            <div>
                <div className="form-check form-switch mb-5">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        name={`content[main_block_with_few_banner__is_public]`}
                        defaultValue="1"
                        defaultChecked={page.contentObject.main_block_with_few_banner__is_public}
                    />
                    <label className="form-check-label">Скрыть / Показать</label>
                </div>
            </div>
            <Sortable list={banners} setList={setBanners} className="row row-cols-auto">
                {banners.map((banner: MainBlockWithFewBannerBannersModel, index: number) => (
                    <div
                        key={banner.id}
                        className="mb-5"
                        style={{
                            width: `fit-content`,
                            height: `fit-content`,
                        }}
                    >
                        <div className="card border">
                            <img
                                style={{ width: `660px`, height: `375px` }}
                                className="card-img-top img_placeholder img-thumbnail"
                                src={makePath(banner.file?.path)}
                                alt=""
                            />

                            <input
                                type="hidden"
                                name={`content[main_block_with_few_banner__banners][${index}][file_id]`}
                                value={banner.file?.id}
                            />

                            <div className="card-body">
                                <div className="card-text mb-3">
                                    <div>
                                        <MultiLanguages
                                            languageElement={(language: Language) => (
                                                <Input
                                                    label="Заголовок"
                                                    key={language}
                                                    name={`content[main_block_with_few_banner__banners][${index}][title][${language}]`}
                                                    value={banner.title[language]}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div>
                                        <MultiLanguages
                                            languageElement={(language: Language) => (
                                                <Input
                                                    label="Описание"
                                                    key={language}
                                                    name={`content[main_block_with_few_banner__banners][${index}][description][${language}]`}
                                                    value={banner.description[language]}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div>
                                        <MultiLanguages
                                            languageElement={(language: Language) => (
                                                <Input
                                                    label="Текст кнопки"
                                                    key={language}
                                                    name={`content[main_block_with_few_banner__banners][${index}][button_text][${language}]`}
                                                    value={banner.button_text[language]}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div>
                                        <span className="fw-bolder">Ссылка:{` `}</span>
                                        {` `}
                                        {banner.url}
                                        <input
                                            type="hidden"
                                            name={`content[main_block_with_few_banner__banners][${index}][url]`}
                                            value={banner.url}
                                        />
                                    </div>

                                    <div>
                                        <MultiLanguages
                                            languageElement={(language: Language) => (
                                                <Input
                                                    label="Тэг"
                                                    key={language}
                                                    name={`content[main_block_with_few_banner__banners][${index}][tag][${language}]`}
                                                    value={banner.tag[language]}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <a
                                        className="card-link text-success"
                                        onClick={() => {
                                            setEditableBanner(banner);
                                            setShowModal(true);
                                        }}
                                    >
                                        Редактировать
                                    </a>

                                    <a
                                        className="card-link text-danger"
                                        onClick={() => {
                                            setBanners((prev) => prev.filter((value) => value.id !== banner.id));
                                        }}
                                    >
                                        Удалить
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Sortable>
            <i
                className="bi bi-plus-square text-primary fs-2hx cursor-pointer ms-3 align-self-center"
                onClick={() => {
                    setEditableBanner(undefined);
                    setShowModal(true);
                }}
            />

            {showModal && (
                <AddBannerModal<MainBlockWithFewBannerBannersModel>
                    showModal={showModal}
                    closeModal={() => setShowModal(false)}
                    onSubmit={editableBanner ? editHandle : addHandle}
                    editableBanner={editableBanner}
                />
            )}
        </div>
    );
}
