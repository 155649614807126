import { post } from "../api";
import { type RubricModel } from "@admin/domain/rubrics/models/RubricModel";

const createRubric = async (body: FormData): Promise<RubricModel> => {
    return await post({
        url: `/admin/rubrics/`,
        body: body,
    });
};

const updateRubric = async (id: RubricModel[`id`], body: FormData): Promise<RubricModel> => {
    return await post({
        url: `/admin/rubrics/${id}`,
        body: body,
    });
};

export const saveRubric = async (rubric: RubricModel | null, body: FormData): Promise<RubricModel> => {
    return rubric?.id ? updateRubric(rubric.id, body) : createRubric(body);
};
