import React from "react";

import type { BlockProps } from "@admin/components/Content/ContentBlocks";
import type { ButtonBlockValueModel } from "@admin/domain/content/models/ContentBlockValueModel";
import MultiLanguages from "@admin/components/MultiLanguage";

const defaultValue: ButtonBlockValueModel = {
    button_text: {},
    url: ``,
};

type Props = BlockProps<ButtonBlockValueModel>;

export default function ButtonBlock({ index, value = structuredClone(defaultValue) }: Props) {
    return (
        <>
            <MultiLanguages
                languageElement={(language) => (
                    <div>
                        <div className="mb-5">
                            <label className="form-label">Текст кнопки</label>
                            <input
                                name={`blocks[${index}][value][button_text][${language}]`}
                                defaultValue={value.button_text[language] || ``}
                                className="form-control"
                            />
                        </div>
                    </div>
                )}
            />

            <div className="mb-5">
                <label className="form-label">Ссылка для перехода</label>
                <input name={`blocks[${index}][value][url]`} defaultValue={value.url || ``} className="form-control" />
            </div>
        </>
    );
}
