import React from "react";

import type ComplexPromotionModel from "@admin/domain/complexPromotions/models/ComplexPromotionModel";
import { useIsInvalid } from "@admin/components/FormErrorsContext";
import Input from "@admin/components/Input";
import TextArea from "@admin/components/TextArea";
import DateTimeInput from "@admin/components/DateTimeInput";
import CheckBox from "@admin/components/CheckBox";
import MultiLanguages from "@admin/components/MultiLanguage";

type Props = {
    type: `amount` | `content`;
    promotion: ComplexPromotionModel | null;
};

const Common: React.FC<Props> = ({ type, promotion }) => (
    <>
        <input type="hidden" name="type" value={type} />

        <MultiLanguages
            hasErrors={(language) => useIsInvalid(`texts[${language}]`)}
            languageElement={(language) => (
                <>
                    <Input
                        label="Название"
                        defaultValue={promotion?.texts[language]?.title}
                        name={`texts[${language}][title]`}
                    />

                    <TextArea
                        label="Описание"
                        defaultValue={promotion?.texts[language]?.description}
                        name={`texts[${language}][description]`}
                    />
                </>
            )}
        />

        <DateTimeInput label="Начало акции" name="active_from" defaultValue={promotion?.active_from || ``} />

        <DateTimeInput label="Конец акции" name="active_to" defaultValue={promotion?.active_to || ``} />

        <CheckBox
            name="only_for_site"
            label="Применять только для сайта"
            defaultChecked={promotion ? promotion.only_for_site : true}
        />

        <CheckBox
            label="Разрешить добавлять несколько копий вознаграждения"
            name="use_multiplier"
            defaultChecked={promotion ? promotion.use_multiplier : true}
        />

        {type === `content` ? (
            <CheckBox
                name="insert_products_automatically"
                label="Автоматически добавлять товары в корзину"
                defaultChecked={promotion ? promotion.insert_products_automatically : true}
            />
        ) : (
            <input type="hidden" name="insert_products_automatically" value="0" />
        )}
    </>
);

export default Common;
