export const chooseDeclension = (count: number, one = `штука`, three = `штуки`, five = `штук`): string => {
    count %= 100;

    if (count >= 11 && count <= 19) {
        return five;
    }

    count %= 10;

    if (count === 1) {
        return one;
    }

    if (count > 0 && count <= 4) {
        return three;
    }

    return five;
};
