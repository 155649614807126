import React from "react";

import type { BlockProps } from "@admin/components/Content/ContentBlocks";
import type { TextBlockValueModel } from "@admin/domain/content/models/ContentBlockValueModel";
import MultiLanguages from "@admin/components/MultiLanguage";

const defaultValue: TextBlockValueModel = {
    text: {},
};

type Props = BlockProps<TextBlockValueModel>;

export default function BGColorTextBlock({ index, value = structuredClone(defaultValue) }: Props) {
    return (
        <MultiLanguages
            languageElement={(language) => (
                <div className="mb-5">
                    <textarea
                        className="form-control"
                        name={`blocks[${index}][value][text][${language}]`}
                        defaultValue={value.text[language] || ``}
                    />
                </div>
            )}
        />
    );
}
