import React from "react";
import Input from "@admin/components/Input";
import { updateDeliveryMethodRegionConfig } from "@admin/domain/deliveryMethods/api";
import Editor from "@admin/components/Editor";
import Conditions from "@admin/pages/DeliveryMethods/DeliveryMethodRegionEditor/Conditions";
import CheckBox from "@admin/components/CheckBox";
import isNumber from "lodash-es/isNumber";

export default function DeliveryMethodRegionEditor() {
    const { deliveryMethodRegionConfig } = window.CMS;

    const execute = async (body: FormData): Promise<void> => {
        await updateDeliveryMethodRegionConfig(deliveryMethodRegionConfig.id, body);
    };

    return (
        <>
            <Editor onSubmit={execute}>
                <Input
                    label="Стоимость"
                    name="cost"
                    type="number"
                    defaultValue={
                        isNumber(deliveryMethodRegionConfig.cost) ? deliveryMethodRegionConfig?.cost / 100 : ``
                    }
                />

                <div className="d-flex">
                    <Input
                        label="Минимальный срок доставки"
                        name="delivery_time_min_days"
                        defaultValue={deliveryMethodRegionConfig.delivery_time_min_days}
                    />
                    <Input
                        className={{ block: `ms-4` }}
                        label="Максимальный срок доставки"
                        name="delivery_time_max_days"
                        defaultValue={deliveryMethodRegionConfig.delivery_time_max_days}
                    />
                </div>
                <Conditions />
                <CheckBox label="Активен" defaultChecked={deliveryMethodRegionConfig.is_active} name="is_active" />
            </Editor>
        </>
    );
}
