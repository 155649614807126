import React from "react";
import Ingredients from "./Ingredients";
import Input from "@admin/components/Input";
import { ProductSpecificationsModel } from "@admin/domain/products/models/edit/ProductSpecificationsModel";
import ActiveComponents from "./ActiveComponents";
import DosageForm from "./DosageForm";

export default function ProductSpecifications({ specifications }: { specifications: ProductSpecificationsModel }) {
    return (
        <div className="rounded border p-10 mb-5">
            <h2>Характеристики</h2>
            {specifications.ingredients.length > 0 && <Ingredients ingredients={specifications.ingredients} />}
            {specifications.active_components.length > 0 && (
                <ActiveComponents activeComponents={specifications.active_components} />
            )}
            {specifications.dosage_form && <DosageForm dosageForm={specifications.dosage_form} />}
            <Input label="Масса нетто" name="specifications[net_weight]" defaultValue={specifications.net_wight} />
            <Input
                label="Масса брутто"
                name="specifications[gross_weight]"
                defaultValue={specifications.gross_weight}
            />
            <div>
                <h5>Габариты</h5>
                <div className="d-flex">
                    <Input
                        className={{ block: `mb-5 me-5` }}
                        label="Длина"
                        name="specifications[dimensions][length]"
                        defaultValue={specifications.dimensions.length}
                    />
                    <Input
                        className={{ block: `mb-5 me-5` }}
                        label="Высота"
                        name="specifications[dimensions][height]"
                        defaultValue={specifications.dimensions.height}
                    />
                    <Input
                        className={{ block: `mb-5 me-5` }}
                        label="Ширина"
                        name="specifications[dimensions][width]"
                        defaultValue={specifications.dimensions.width}
                    />
                </div>
            </div>
        </div>
    );
}
