import { post } from "../api";
import { ThemeModel } from "@admin/domain/theme/models/ThemeModel";

const createTheme = async (body: FormData): Promise<ThemeModel> => {
    return await post({
        url: `/admin/themes`,
        body: body,
    });
};

const updateTheme = async (id: ThemeModel[`id`], body: FormData): Promise<ThemeModel> => {
    return await post({
        url: `/admin/themes/${id}`,
        body: body,
    });
};

export const saveTheme = async (theme: ThemeModel | null, body: FormData): Promise<ThemeModel> => {
    return theme && theme.id ? updateTheme(theme.id, body) : createTheme(body);
};
