import { post } from "../api";
import { type NewsModel } from "./models/NewsModel";

const createNewsArticle = async (body: FormData): Promise<NewsModel> => {
    return await post({
        url: `/admin/news`,
        body,
    });
};

const updateNewsArticle = async (id: NewsModel[`id`], body: FormData): Promise<NewsModel> => {
    return await post({
        url: `/admin/news/${id}`,
        body,
    });
};

export const saveNewsArticle = async (article: NewsModel | null, body: FormData): Promise<NewsModel> => {
    return article && article.id ? updateNewsArticle(article.id, body) : createNewsArticle(body);
};
