import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import { type FaqBannersModel } from "@admin/domain/articles/models/ArticleBlockValueModel";
import { type ArticleContentBlockModel } from "@admin/domain/articles/models/ArticleContentBlockModel";
import MultiLanguages from "@admin/components/MultiLanguage";
import { ArticleBlockType } from "@admin/domain/articles/enums/ArticleBlockType";

type Banner = Partial<FaqBannersModel[`faq_banners`][0]>;

type Props = {
    block: ArticleContentBlockModel & {
        type: ArticleBlockType.Faq;
    };
    index: number;
};

export default function FaqBlock({ block: { id, value }, index }: Props) {
    const initBanners = (value?.faq_banners || []).map((value) => ({
        ...value,
        id: uuid(),
    }));

    const [banners, setBanners] = useState<Banner[]>(initBanners);

    return (
        <>
            <div>
                <input type="hidden" name={`blocks[${index}][value][id]`} value={id} />

                <MultiLanguages
                    languageElement={(language) => (
                        <div className="mb-5">
                            <label className="form-label">Заголовок</label>
                            <input
                                className="form-control"
                                name={`blocks[${index}][value][title][${language}]`}
                                defaultValue={value?.title?.[language] || ``}
                            />
                        </div>
                    )}
                />
            </div>

            <div className="row row-cols-auto">
                {banners.map((banner, itemIndex) => (
                    <div key={banner.id} className="col mb-7">
                        <MultiLanguages
                            languageElement={(language) => (
                                <>
                                    <div className="mb-7">
                                        <label className="form-label">Вопрос</label>
                                        <textarea
                                            className="form-control"
                                            rows={5}
                                            cols={50}
                                            name={`blocks[${index}][value][faq_banners][${itemIndex}][question][${language}]`}
                                            defaultValue={banner?.question?.[language] || ``}
                                        />
                                    </div>
                                    <div className="mb-7">
                                        <label className="form-label">Ответ</label>
                                        <textarea
                                            className="form-control"
                                            rows={5}
                                            cols={50}
                                            name={`blocks[${index}][value][faq_banners][${itemIndex}][answer][${language}]`}
                                            defaultValue={banner?.answer?.[language] || ``}
                                        />
                                    </div>
                                </>
                            )}
                        />

                        <div>
                            <a
                                className="card-link text-danger"
                                onClick={() => {
                                    setBanners((prev) => prev.filter((value) => value.id !== banner.id));
                                }}
                            >
                                Удалить
                            </a>
                        </div>
                    </div>
                ))}

                <div className="mb-5 d-flex">
                    <div className="me-5">
                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={() =>
                                setBanners((prevState) => [
                                    ...prevState,
                                    {
                                        id: uuid(),
                                        question: undefined,
                                        answer: undefined,
                                    },
                                ])
                            }
                        >
                            Добавить
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
