import React from "react";
import Modal from "react-bootstrap/Modal";

type Props = {
    closeModal: () => void;
    title: string;
    subTitle?: string | React.ReactNode;
    body: React.ReactNode;
    footer: React.ReactNode;
    size?: `sm` | `lg` | `xl`;
};

export default function BaseModal({ closeModal, title, subTitle, body, footer, size = `lg` }: Props) {
    return (
        <Modal centered={true} show onHide={closeModal} size={size}>
            <Modal.Header>
                <Modal.Title className="h6">
                    <div className="modal-title h4">{title}</div>
                </Modal.Title>
                {subTitle}
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            <Modal.Footer>{footer}</Modal.Footer>
        </Modal>
    );
}
