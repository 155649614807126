import { EditOrderData } from "@admin/domain/orders/dto/EditOrderData";
import React, { useContext } from "react";
import { PaymentMethodModel } from "@admin/domain/payments/models/PaymentMethodModel";
import { CountryModel } from "@admin/domain/countries/models/CountryModel";
import { DeliveryMethodModel } from "@admin/domain/deliveryMethods/models/DeliveryMethodModel";
import { toDictionary } from "@admin/utils/converters";

type OrderContextProps = {
    order: EditOrderData;
    setOrder: React.Dispatch<React.SetStateAction<EditOrderData>>;
    paymentMethods: Map<number, PaymentMethodModel>;
    countries: Map<number, CountryModel>;
    deliveryMethods: Map<number, DeliveryMethodModel>;
};

type Props = {
    order: EditOrderData;
    paymentMethods: PaymentMethodModel[];
    countries: CountryModel[];
    deliveryMethods: DeliveryMethodModel[];
    children: React.ReactNode;
};

const OrderContext = React.createContext<OrderContextProps>(undefined as any);

export default function OrderContextWrapper(props: Props) {
    const [order, setOrder] = React.useState(props.order);
    const paymentMethods = toDictionary(props.paymentMethods);
    const countries = toDictionary(props.countries);
    const deliveryMethods = toDictionary(props.deliveryMethods);

    return (
        <OrderContext.Provider
            value={{
                order,
                setOrder,
                paymentMethods,
                countries,
                deliveryMethods,
            }}
        >
            {props.children}
        </OrderContext.Provider>
    );
}

export const useOrderContext = (): OrderContextProps => useContext(OrderContext);
