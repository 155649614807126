import React, { useState } from "react";
import { initLanguageKeyObject, LanguageKeyObject } from "@admin/domain/languages/Languages";
import MultiLanguages from "@admin/components/MultiLanguage";

type Props = {
    speakers: LanguageKeyObject[];
    broadcastIndex: number;
};

export default function Speakers({ speakers: initSpeakers, broadcastIndex }: Props) {
    const [speakers, setSpeakers] = useState<LanguageKeyObject[]>(initSpeakers);

    const addSpeaker = (): void => setSpeakers([...speakers, initLanguageKeyObject()]);

    const deleteSpeaker = (speakerIndex: number): void =>
        setSpeakers(speakers.filter((_speaker, index) => speakerIndex !== index));

    return (
        <>
            <h4>Спикеры</h4>

            {speakers.length > 0 && (
                <div className="rounded border p-10 mb-5">
                    {speakers.map((speaker: LanguageKeyObject, speakerIndex: number) => (
                        <MultiLanguages
                            key={speakerIndex}
                            languageElement={(language) => (
                                <div className="mb-5">
                                    <label className="form-label">Описание спикера</label>
                                    <input
                                        className="form-control mb-5"
                                        name={`content[broadcasts][${broadcastIndex}][speakers][${speakerIndex}][${language}]`}
                                        defaultValue={speaker[language]}
                                    />

                                    <button
                                        type="button"
                                        className="btn btn-danger"
                                        onClick={() => deleteSpeaker(speakerIndex)}
                                    >
                                        Удалить спикера
                                    </button>
                                </div>
                            )}
                        />
                    ))}

                    <button type="button" className="btn btn-primary" onClick={addSpeaker}>
                        Добавить спикера
                    </button>
                </div>
            )}
        </>
    );
}
