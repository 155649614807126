import React from "react";

import { saveChapter } from "@admin/domain/chapters/api";
import Editor from "@admin/components/Editor";
import Stories from "./Stories";
import Switch from "@admin/components/Switch";
import MultiLanguages from "@admin/components/MultiLanguage";
import Input from "@admin/components/Input";
import ImageInput from "@admin/components/ImageInput";

const LIST_URL = `/admin/chapters` as const;

export default function ChapterEditor() {
    const chapter = window.CMS.chapter || null;

    const execute = async (body: FormData) => {
        await saveChapter(chapter, body);
    };

    return (
        <Editor backUrl={LIST_URL} onSubmit={execute}>
            {chapter && (
                <>
                    <div className="mb-5">
                        <label className="form-label">Дата создания</label>
                        <input className="form-control" type="datetime" defaultValue={chapter?.created_at} disabled />
                    </div>

                    <div className="mb-5">
                        <label className="form-label">Дата обновления</label>
                        <input className="form-control" type="datetime" defaultValue={chapter?.updated_at} disabled />
                    </div>
                </>
            )}

            <Input label="Порядок отображения" defaultValue={chapter?.order || 1} type="number" min="1" name="order" />

            <Switch label="Скрыть / Показать" name="is_public" defaultChecked={chapter?.is_public} />

            <MultiLanguages
                languageElement={(language) => (
                    <>
                        <input type="hidden" name={`texts[${language}][language]`} defaultValue={language} />

                        <Input
                            label="Заголовок страницы"
                            name={`texts[${language}][title]`}
                            defaultValue={chapter?.texts[language]?.title}
                        />
                    </>
                )}
            />

            <ImageInput label="" name="file_id" defaultValue={chapter?.file} height="400px" thumbnailHeight="400px" />

            <Stories chapter={chapter} />
        </Editor>
    );
}
