import React from "react";
import { v4 as uuid } from "uuid";

import type { MainPageModel } from "@admin/domain/uniquePages/models/MainPageModel";
import type { UniquePageProps } from "../index";
import VideoInput from "../../../components/VideoInput";
import MultiLanguages from "@admin/components/MultiLanguage";
import ImageInput from "@admin/components/ImageInput";

export default function MainPageHowToEarn({ page: { contentObject } }: UniquePageProps<MainPageModel>) {
    const banners: MainPageModel[`how_to_earn__banners`] = contentObject.how_to_earn__banners || [
        { id: uuid(), file: null },
    ];

    return (
        <div className="mb-10">
            <h4 className="mb-5">Как зарабатывать с компанией Тенториум</h4>

            <MultiLanguages
                languageElement={(language) => (
                    <>
                        <div className="mb-5">
                            <label className="form-label">Заголовок</label>
                            <input
                                className="form-control"
                                name={`content[how_to_earn__title][${language}]`}
                                defaultValue={contentObject.how_to_earn__title[language] || ``}
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Описание (Текст под заголовком)</label>
                            <textarea
                                className="form-control"
                                name={`content[how_to_earn__description][${language}]`}
                                defaultValue={contentObject.how_to_earn__description[language] || ``}
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Текст кнопки 1</label>
                            <input
                                className="form-control"
                                name={`content[how_to_earn__business_button__text][${language}]`}
                                defaultValue={contentObject.how_to_earn__business_button__text[language] || ``}
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Текст кнопки 2</label>
                            <input
                                className="form-control"
                                name={`content[how_to_earn__video_button__text][${language}]`}
                                defaultValue={contentObject.how_to_earn__video_button__text[language] || ``}
                            />
                        </div>
                    </>
                )}
            />

            <div className="mb-5">
                <VideoInput
                    name="content[how_to_earn__video]"
                    defaultValue={{
                        file: contentObject.how_to_earn__video,
                        url: contentObject.how_to_earn__video_url,
                    }}
                />
            </div>

            <div className="row row-cols-auto">
                {banners.map((banner, bannerIndex) => (
                    <div key={bannerIndex} className="col mb-5">
                        <ImageInput
                            label="Изображение"
                            name={`content[how_to_earn__banners][${bannerIndex}][file_id]`}
                            defaultValue={banner.file}
                            width="800px"
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}
