import { post, request } from "../api";
import { ArticleModel } from "./models/ArticleModel";
import { ArticleRubricModel } from "./models/ArticleRubricModel";
import { ArticleThemeModel } from "./models/ArticleThemeModel";

const createArticle = async (body: FormData): Promise<ArticleModel> => {
    return await post({
        url: `/admin/articles`,
        body,
    });
};

const updateArticle = async (id: ArticleModel[`id`], body: FormData): Promise<ArticleModel> => {
    return await post({
        url: `/admin/articles/${id}`,
        body,
    });
};

export const saveArticle = async (article: ArticleModel | null, body: FormData): Promise<ArticleModel> => {
    return article ? updateArticle(article.id, body) : createArticle(body);
};

export const getRubrics = async (): Promise<Array<ArticleRubricModel>> => {
    return await request({
        url: `/admin/rubrics/get_for_articles`,
    });
};

export const getThemes = async (): Promise<Array<ArticleThemeModel>> => {
    return await request({
        url: `/admin/themes/get_for_articles`,
    });
};
