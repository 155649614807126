export const enum DeliveryPointProvider {
    Tentorium = `tentorium`,
    Boxberry = `boxberry`,
    Cdek = `cdek`,
}

export type DeliveryPointModel = {
    id: number;
    name: string;
    provider: DeliveryPointProvider;
    phones: ReadonlyArray<string> | null;
    comment: string | null;
    country_id: number;
    region_name: string;
    postcode: string | null;
    locality_name: string;
    address: string;
    email: string | null;
    external_id: string;
    distributor_personal_number: number | null;
    is_hidden: boolean;
    phones_is_hidden: boolean;
    created_at: string;
    updated_at: string;
};
