import React from "react";

import DateTimeInput from "@admin/components/DateTimeInput";

type Props = {
    defaultValue: string | undefined | null;
};

const PublishedAtInput: React.FC<Props> = ({ defaultValue }) => {
    return (
        <DateTimeInput label="Дата публикации" defaultValue={defaultValue || ``} name="published_at" showNowButton />
    );
};

export default PublishedAtInput;
