import React from "react";

import type { BlockProps } from "@admin/components/Content/ContentBlocks";
import type { BannerBlockValueModel } from "@admin/domain/content/models/ContentBlockValueModel";
import MultiLanguages from "@admin/components/MultiLanguage";
import ImageInput from "@admin/components/ImageInput";

const defaultValue: BannerBlockValueModel = {
    button_text: {},
    description: {},
    title: {},
    file: null,
    url: ``,
};

type Props = BlockProps<BannerBlockValueModel>;

export default function BannerBlock({ index, value = structuredClone(defaultValue) }: Props) {
    return (
        <>
            <MultiLanguages
                languageElement={(language) => (
                    <>
                        <div className="mb-5">
                            <label className="form-label">Заголовок</label>
                            <input
                                className="form-control"
                                name={`blocks[${index}][value][title][${language}]`}
                                defaultValue={value.title[language] || ``}
                            />
                        </div>

                        <div className="mb-5">
                            <label className="form-label">Текст</label>
                            <input
                                className="form-control"
                                name={`blocks[${index}][value][description][${language}]`}
                                defaultValue={value.description[language] || ``}
                            />
                        </div>

                        <div className="mb-5">
                            <label className="form-label">Текст кнопки</label>
                            <input
                                className="form-control"
                                name={`blocks[${index}][value][button_text][${language}]`}
                                defaultValue={value.button_text[language] || ``}
                            />
                        </div>
                    </>
                )}
            />

            <div className="mb-5">
                <label className="form-label">Ссылка</label>
                <input className="form-control" name={`blocks[${index}][value][url]`} defaultValue={value.url || ``} />
            </div>

            <div className="mb-5">
                <ImageInput
                    label="Изображение"
                    name={`blocks[${index}][value][file_id]`}
                    defaultValue={value.file}
                    width="800px"
                    height="200px"
                />
            </div>
        </>
    );
}
