import React from "react";

export default function DeliveryPage() {
    return (
        <div className="rounded border p-10 my-5">
            <p>
                <a href="/admin/delivery_methods/settings/notifications" target="_blank">
                    Блок с уведомлениями
                </a>
            </p>
            <p>
                <a href="/admin/delivery_methods/settings/general_conditions" target="_blank">
                    Блок общих условий доставки
                </a>
            </p>
            <p>
                <a href="/admin/delivery_methods/settings/group" target="_blank">
                    Блок обобщенных способов (групп) доставки
                </a>
            </p>
            <p>
                <a href="/admin/delivery_methods" target="_blank">
                    Блок со списком способов доставки
                </a>
            </p>
        </div>
    );
}
