import React, { useContext, useEffect } from "react";
import { AccessGroupModel } from "@admin/domain/access/models/AccessGroupModel";
import { AccessObjectModel } from "@admin/domain/access/models/AccessObjectModel";
import { SetState } from "@admin/utils/types";
import { AccessPermissionModel } from "@admin/domain/access/models/AccessPermissionModel";
import { getAccessMatrix } from "@admin/domain/access/api";

type AccesPermissionsContextProps = {
    selectedAccessGroup: AccessGroupModel | null;
    setSelectedAccessGroup: SetState<AccessGroupModel | null>;
    selectedAccessObject: AccessObjectModel | null;
    setSelectedAccessObject: SetState<AccessObjectModel | null>;
    accessPermissions: AccessPermissionModel[];
    accessGroups: AccessGroupModel[];
    accessObjects: AccessObjectModel[];
    setAccessPermissions: SetState<AccessPermissionModel[]>;
};

type Props = {
    children: React.ReactNode;
};

const AccessPermissionsContext = React.createContext<AccesPermissionsContextProps>(undefined as any);

export default function AccessPermissionsContextWrapper(props: Props) {
    const [selectedAccessGroup, setSelectedAccessGroup] = React.useState<AccessGroupModel | null>(null);
    const [selectedAccessObject, setSelectedAccessObject] = React.useState<AccessObjectModel | null>(null);
    const [accessPermissions, setAccessPermissions] = React.useState<AccessPermissionModel[]>([]);
    const [accessGroups, setAccessGroups] = React.useState<AccessGroupModel[]>([]);
    const [accessObjects, setAccessObjects] = React.useState<AccessObjectModel[]>([]);

    useEffect((): void => {
        // noinspection JSIgnoredPromiseFromCall
        (async (): Promise<void> => {
            const data = await getAccessMatrix();

            setAccessPermissions(data.access_permissions);
            setAccessGroups(data.access_groups);
            setAccessObjects(data.access_objects);
        })();

        return;
    }, []);

    return (
        <AccessPermissionsContext.Provider
            value={{
                selectedAccessGroup,
                setSelectedAccessGroup,
                selectedAccessObject,
                setSelectedAccessObject,
                accessPermissions,
                accessGroups,
                accessObjects,
                setAccessPermissions,
            }}
        >
            {props.children}
        </AccessPermissionsContext.Provider>
    );
}

export const useAccessPermissionsContext = (): AccesPermissionsContextProps => useContext(AccessPermissionsContext);
