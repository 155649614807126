import { post, request } from "@admin/domain/api";
import { AccessGroupModel } from "@admin/domain/access/models/AccessGroupModel";
import { AccessObjectModel } from "@admin/domain/access/models/AccessObjectModel";
import { AccessAction, AccessPermissionModel } from "@admin/domain/access/models/AccessPermissionModel";

export type AccessMatrix = {
    access_groups: AccessGroupModel[];
    access_objects: AccessObjectModel[];
    access_permissions: AccessPermissionModel[];
};

export const getAccessMatrix = async (): Promise<AccessMatrix> => {
    return await request({
        url: `/admin/access_permissions/matrix`,
    });
};

export const storeAccessPermission = async (body: {
    access_object_id: number;
    access_group_id: number;
    is_enabled: boolean;
    action: AccessAction;
}): Promise<AccessPermissionModel> => {
    return await post({
        url: `/admin/access_permissions`,
        body,
    });
};

export const updateAccessPermission = async (id: number, isEnabled: boolean): Promise<AccessPermissionModel> => {
    return await post({
        url: `/admin/access_permissions/${id}`,
        body: {
            is_enabled: isEnabled,
        },
    });
};
