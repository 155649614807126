import React from "react";

import type { Language } from "@admin/domain/languages/Languages";
import { saveTheme } from "@admin/domain/theme/api";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import Editor from "@admin/components/Editor";
import Input from "@admin/components/Input";
import Switch from "@admin/components/Switch";
import Select from "@admin/components/Select";
import MultiLanguages from "@admin/components/MultiLanguage";

const LIST_URL = `/admin/themes` as const;

export default function ThemeEditor() {
    const theme = window.CMS.theme || null;

    const currentLanguage = useLanguageContext().currentLanguage;

    const execute = async (body: FormData) => {
        const response = await saveTheme(theme, body);

        return LIST_URL + (response.parent_id ? `/${response.parent_id}` : ``);
    };

    return (
        <Editor backUrl={LIST_URL + (theme?.parent_id ? `/${theme.parent_id}` : ``)} onSubmit={execute}>
            <Select
                label="Родительская тема"
                name="parent_id"
                options={(theme?.parents || []).map((value) => ({
                    value: value.id as number,
                    label: value.texts[currentLanguage]?.title as string,
                }))}
                defaultValue={theme?.parent_id}
            />

            <MultiLanguages
                languageElement={(language: Language): React.JSX.Element => (
                    <Input
                        label="Название"
                        defaultValue={theme?.texts[language]?.title || ``}
                        name={`texts[${language}][title]`}
                    />
                )}
            />

            <Input
                label="Порядок отображения"
                name="order"
                type="number"
                defaultValue={theme?.order || theme?.next_order}
            />

            <Switch label="Скрыть / Показать" name="is_public" defaultChecked={theme?.is_public} />
        </Editor>
    );
}
