import Graphemer from "graphemer";
import isString from "lodash/isString";

export type Variable = {
    label: string;
    value: string;
    placeholder: string;
    wrapper: string;
};

export type Chunk = string | Variable;
export type Value = Chunk[];

export const VARIABLES = [
    {
        label: `Название сайта`,
        value: `site_name`,
        placeholder: `%site_name%`,
        wrapper: `<span data-variable="site_name" contenteditable="false" class="btn btn-light-success btn-sm disabled opacity-100 px-2 py-1">Название сайта</span>`,
    },
    {
        label: `Заголовок`,
        value: `title`,
        placeholder: `%title%`,
        wrapper: `<span data-variable="title" contenteditable="false" class="btn btn-light-success btn-sm disabled opacity-100 px-2 py-1">Заголовок</span>`,
    },
    {
        label: `Описание`,
        value: `description`,
        placeholder: `%description%`,
        wrapper: `<span data-variable="description" contenteditable="false" class="btn btn-light-success btn-sm disabled opacity-100 px-2 py-1">Описание</span>`,
    },
    {
        label: `Цена`,
        value: `price`,
        placeholder: `%price%`,
        wrapper: `<span data-variable="price" contenteditable="false" class="btn btn-light-success btn-sm disabled opacity-100 px-2 py-1">Цена</span>`,
    },
] as const;

const nbsp = `\u00A0` as const;

export const getText = ($element: HTMLElement | ChildNode | null): string => {
    if (!$element) {
        return ``;
    }

    let text = ``;

    Array.from($element.childNodes).forEach((child: ChildNode) => {
        if (child.nodeType === Node.TEXT_NODE) {
            text += child.textContent;
        } else if (child.nodeType === Node.ELEMENT_NODE) {
            if ((child as HTMLElement).dataset.variable) {
                text += `%${(child as HTMLElement).dataset.variable}%`;
            } else if ((child as HTMLElement).tagName === `BR`) {
                text += `\n`;
            } else {
                text += getText(child);
            }
        }
    });

    let result = text.replaceAll(nbsp, ` `);

    VARIABLES.forEach(({ placeholder, wrapper }: Variable) => {
        result = result.replaceAll(wrapper, placeholder);
    });

    return result;
};

export const renderHtml = (value: Value): string => {
    return value
        .map((chunk) => {
            return isString(chunk) ? chunk.replaceAll(` `, nbsp).replaceAll(`\n`, `<br />`) : chunk.wrapper;
        })
        .join(``);
};

export const renderPlain = (value: Value): string => {
    return value
        .map((chunk) => {
            return isString(chunk)
                ? chunk
                      .replaceAll(nbsp, ` `)
                      .replaceAll(`<br />`, `\n`)
                      .replaceAll(`<br/>`, `\n`)
                      .replaceAll(`<br>`, `\n`)
                : chunk.placeholder;
        })
        .join(``);
};

export const parse = (text: string): Value => {
    if (!text) {
        return [];
    }

    const chunks: Value = [];

    const vars = [...VARIABLES].sort((a, b) => b.value.length - a.value.length);

    let buffer = ``;

    const chars = new Graphemer().splitGraphemes(text);

    for (let pointer = 0; pointer < chars.length; pointer++) {
        const char = chars[pointer];

        if (char === `%`) {
            const variable = vars.find((variable) => {
                const chunk = chars.slice(pointer, pointer + variable.placeholder.length).join(``);

                return chunk === variable.placeholder;
            });

            if (variable) {
                if (buffer) {
                    chunks.push(buffer);
                    buffer = ``;
                }

                chunks.push(variable);

                pointer += variable.placeholder.length;

                // При выходе из цикла сработает "лишний" инкремент
                pointer--;
            } else {
                buffer += char;
            }
        } else {
            buffer += char;
        }
    }

    if (buffer) {
        chunks.push(buffer);
    }

    return chunks;
};

export const splice = (
    value: Value,
    htmlCaretPosition: number,
    variable: Variable,
): { newValue: Value; newCaretPosition: number } => {
    const chunks = value.map((item) => {
        if (isString(item)) {
            return {
                chunk: item,
                plainLength: item.length,
                htmlLength: item.length,
            };
        } else {
            return {
                chunk: item,
                plainLength: item.placeholder.length,
                htmlLength: item.label.length,
            };
        }
    });

    const totalHtmlLength = chunks.reduce((total, item) => total + item.htmlLength, 0);

    htmlCaretPosition = Math.min(Math.max(htmlCaretPosition, 0), totalHtmlLength);

    if (htmlCaretPosition === 0) {
        return {
            newValue: [variable, ...value],
            newCaretPosition: variable.label.length,
        };
    }

    if (htmlCaretPosition >= totalHtmlLength) {
        const newValue = [...value, variable];

        return {
            newValue: newValue,
            newCaretPosition: totalHtmlLength + variable.label.length,
        };
    }

    if (value.length === 1 && isString(value[0])) {
        const newValue = [value[0].substring(0, htmlCaretPosition), variable, value[0].substring(htmlCaretPosition)];

        return {
            newValue: newValue,
            newCaretPosition: htmlCaretPosition + variable.label.length,
        };
    }

    const newValue = [...value];
    let chunkPointer = 0;
    let realCursorPointer = 0;

    for (; chunkPointer < value.length; chunkPointer++) {
        const chunk = value[chunkPointer];

        const text = isString(chunk) ? chunk : chunk.label;

        if (realCursorPointer + text.length === htmlCaretPosition) {
            chunkPointer++;

            htmlCaretPosition = realCursorPointer + text.length + variable.label.length;

            newValue.splice(chunkPointer, 0, variable);

            break;
        } else if (realCursorPointer + text.length > htmlCaretPosition) {
            if (isString(chunk)) {
                const positionInsideString = htmlCaretPosition - realCursorPointer;

                const before = text.substring(0, positionInsideString);
                const after = text.substring(positionInsideString);

                htmlCaretPosition = realCursorPointer + before.length + variable.label.length;

                newValue.splice(chunkPointer, 1, before, variable, after);

                // TODO: splice
            } else {
                chunkPointer++;

                htmlCaretPosition = realCursorPointer + text.length + variable.label.length;

                newValue.splice(chunkPointer, 0, variable);
            }

            break;
        }

        realCursorPointer += text.length;
    }

    return {
        newValue,
        newCaretPosition: htmlCaretPosition,
    };
};
