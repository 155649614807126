import React from "react";

import type { BlockProps } from "@admin/components/Content/ContentBlocks";
import type { ExpertOpinionBlockValueModel } from "@admin/domain/content/models/ContentBlockValueModel";
import MultiLanguages from "@admin/components/MultiLanguage";
import ImageInput from "@admin/components/ImageInput";

const defaultValue: ExpertOpinionBlockValueModel = {
    title: {},
    text: {},
    file: null,
    full_name: ``,
    achievement: ``,
};

type Props = BlockProps<ExpertOpinionBlockValueModel>;

export default function ExpertOpinionBlock({ index, value = structuredClone(defaultValue) }: Props) {
    return (
        <>
            <MultiLanguages
                languageElement={(language) => (
                    <div className="mb-5">
                        <label className="form-label">Заголовок</label>
                        <input
                            className="form-control"
                            name={`blocks[${index}][value][title][${language}]`}
                            defaultValue={value.title[language] || ``}
                        />
                    </div>
                )}
            />
            <MultiLanguages
                languageElement={(language) => (
                    <div className="mb-5">
                        <label className="form-label">Текст</label>
                        <textarea
                            className="form-control"
                            name={`blocks[${index}][value][text][${language}]`}
                            defaultValue={value.text[language] || ``}
                        />
                    </div>
                )}
            />

            <div className="mb-5">
                <label className="form-label">ФИО эксперта</label>
                <input
                    className="form-control"
                    name={`blocks[${index}][value][full_name]`}
                    defaultValue={value.full_name || ``}
                />
            </div>

            <div className="mb-5">
                <label className="form-label">Должность</label>
                <input
                    className="form-control"
                    name={`blocks[${index}][value][achievement]`}
                    defaultValue={value.achievement || ``}
                />
            </div>

            <div className="mb-5">
                <ImageInput
                    label="Изображение"
                    name={`blocks[${index}][value][file_id]`}
                    defaultValue={value.file}
                    width="400px"
                    height="400px"
                />
            </div>
        </>
    );
}
