import React from "react";
import { useLoaderContext } from "@admin/components/LoaderContext";
import AddProductsModal from "@admin/pages/OrderEditor/Composition/SimpleOrder/AddProductsModal";
import type { EditOrderData } from "@admin/domain/orders/dto/EditOrderData";
import ApplyDiscountsModal from "@admin/pages/OrderEditor/Composition/SimpleOrder/ApplyDiscountsModal";
import ApplyVoucherModal from "@admin/pages/OrderEditor/Composition/SimpleOrder/ApplyVoucherModal";

type Props = {
    order: EditOrderData;
};

export default function EditorButtons({ order }: Props) {
    const { isLoading } = useLoaderContext();

    const [showAddProductsModal, setShowAddProductsModal] = React.useState<boolean>(false);

    const [showChangeDiscountModal, setShowChangeDiscountModal] = React.useState<boolean>(false);

    const [showApplyVoucherModal, setShowApplyVoucherModal] = React.useState<boolean>(false);

    return (
        <div>
            {order.is_editable && !order.is_blocked && (
                <div className="d-flex justify-content-end">
                    <button
                        className="btn btn-info"
                        disabled={isLoading}
                        onClick={(): void => setShowAddProductsModal(true)}
                    >
                        Добавить товар
                    </button>
                    <button
                        className="btn btn-info ms-5"
                        disabled={isLoading}
                        onClick={(): void => setShowChangeDiscountModal(true)}
                    >
                        Изменить скидку
                    </button>
                    <button
                        className="btn btn-info ms-5"
                        disabled={isLoading}
                        onClick={(): void => setShowApplyVoucherModal(true)}
                    >
                        Применить промокод или сертификат
                    </button>
                </div>
            )}
            {order.is_blocked && (
                <div className="d-flex justify-content-end">
                    <span>Состав товаров в заказе был изменен из 1С и дальнейшее редактирование в CMS невозможно</span>
                </div>
            )}
            {!order.is_editable && !order.is_blocked && (
                <div className="d-flex justify-content-end">
                    <span>Этот заказ больше недоступен для редактирования</span>
                </div>
            )}
            {showAddProductsModal && (
                <AddProductsModal order={order} closeModal={(): void => setShowAddProductsModal(false)} />
            )}
            {showChangeDiscountModal && (
                <ApplyDiscountsModal closeModal={(): void => setShowChangeDiscountModal(false)} order={order} />
            )}
            {showApplyVoucherModal && (
                <ApplyVoucherModal closeModal={(): void => setShowApplyVoucherModal(false)} order={order} />
            )}
        </div>
    );
}
