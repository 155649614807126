import React from "react";

import { type ArticleContentBlockModel } from "@admin/domain/articles/models/ArticleContentBlockModel";
import { ArticleBlockType } from "@admin/domain/articles/enums/ArticleBlockType";
import MultiLanguages from "@admin/components/MultiLanguage";

type Props = {
    block: ArticleContentBlockModel & {
        type: ArticleBlockType.ArticleSubscribes;
    };
    index: number;
};

export default function ArticleSubscribesBlock({ block: { value }, index }: Props) {
    return (
        <MultiLanguages
            languageElement={(language) => (
                <>
                    <div className="mb-5">
                        <label className="form-label">Заголовок</label>
                        <textarea
                            className="form-control"
                            name={`blocks[${index}][value][title][${language}]`}
                            defaultValue={value?.title?.[language] || ``}
                        />
                    </div>

                    <div className="mb-5">
                        <label className="form-label">Текст</label>
                        <textarea
                            className="form-control"
                            name={`blocks[${index}][value][text][${language}]`}
                            defaultValue={value?.text?.[language] || ``}
                        />
                    </div>

                    <div className="mb-5">
                        <label className="form-label">Текст кнопки</label>
                        <textarea
                            className="form-control"
                            name={`blocks[${index}][value][button_text][${language}]`}
                            defaultValue={value?.button_text?.[language] || ``}
                        />
                    </div>

                    <div className="mb-5">
                        <label className="form-label">Заголовок после подписки</label>
                        <textarea
                            className="form-control"
                            name={`blocks[${index}][value][result_title][${language}]`}
                            defaultValue={value?.result_title?.[language] || ``}
                        />
                    </div>

                    <div className="mb-5">
                        <label className="form-label">Текст после подписки</label>
                        <textarea
                            className="form-control"
                            name={`blocks[${index}][value][result_text][${language}]`}
                            defaultValue={value?.result_text?.[language] || ``}
                        />
                    </div>
                </>
            )}
        />
    );
}
