import React from "react";

import { PageType } from "@admin/domain/textPages/models/TextPage";
import { saveTextPage } from "@admin/domain/textPages/api";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import Editor from "@admin/components/Editor";
import MultiLanguages from "@admin/components/MultiLanguage";
import QuillEditor from "@admin/components/QuillEditor";
import PublishedAtInput from "@admin/components/PublishedAtInput";
import Switch from "@admin/components/Switch";
import Input from "@admin/components/Input";
import Select from "@admin/components/Select";
import PageSeoParamsEditor from "@admin/components/PageSeoParamsEditor";

const LIST_URL = `/admin/text_pages` as const;

export default function TextPageEditor() {
    const page = window.CMS.textPage || null;
    const textPages = window.CMS.textPages || [];

    if (page) {
        page.textsMap = new Map(page.texts.map((value) => [value.language, value]));
    }

    textPages.forEach((parent) => {
        parent.textsMap = new Map(parent.texts.map((value) => [value.language, value]));
    });

    const currentLanguage = useLanguageContext().currentLanguage;

    const execute = async (body: FormData) => {
        await saveTextPage(page, body);
    };

    return (
        <Editor backUrl={LIST_URL} onSubmit={execute}>
            <Switch label="Скрыть / Показать" name="is_public" defaultChecked={page?.is_public} />

            <Input label="URL страницы" name="path" defaultValue={page?.path} />

            <MultiLanguages
                languageElement={(language) => (
                    <>
                        <input type="hidden" name={`texts[${language}][language]`} value={language} />

                        <Input
                            label="Заголовок страницы"
                            name={`texts[${language}][title]`}
                            defaultValue={page?.textsMap.get(language)?.title}
                        />

                        <QuillEditor
                            label="Текст под заголовком"
                            name={`texts[${language}][additional_text]`}
                            initHtml={page?.textsMap.get(language)?.additional_text}
                        />

                        <QuillEditor
                            label="Текст"
                            name={`texts[${language}][text]`}
                            initHtml={page?.textsMap.get(language)?.text}
                        />
                    </>
                )}
            />

            <Select
                label="Принадлежность"
                name="section"
                defaultValue={page?.section}
                options={[
                    {
                        value: PageType.Public,
                        label: `Интернет-магазин`,
                    },
                    {
                        value: PageType.Private,
                        label: `Личный кабинет`,
                    },
                ]}
                required
            />

            <Select
                label="Автор"
                name="author_id"
                defaultValue={page?.author?.id}
                disabled={!page}
                options={(page?.authors || []).map((author) => ({
                    value: author.id as number,
                    label: `${author.name} ${author.email}`,
                }))}
                required
            />

            <Select
                label="Родительская страница"
                name="parent_id"
                defaultValue={page?.parent_id || undefined}
                options={(textPages || []).map((parent) => ({
                    value: parent.id as number,
                    label: parent.textsMap.get(currentLanguage)?.title as string,
                }))}
            />

            <PublishedAtInput defaultValue={page?.published_at} />

            <PageSeoParamsEditor />
        </Editor>
    );
}
