import React, { useState } from "react";

import type ComplexPromotionLevelModel from "@admin/domain/complexPromotions/models/ComplexPromotionLevelModel";
import Input from "@admin/components/Input";
import RadioButton from "@admin/components/RadioButton";
import ProductsSearch from "@admin/components/ProductsSearch";

type Requirements = ComplexPromotionLevelModel[`content_requirements`];

type Props = {
    levelIndex: number;
    level: ComplexPromotionLevelModel;
};

const ContentRequirements: React.FC<Props> = ({ levelIndex, level }) => {
    const [requirements, setRequirements] = useState<Requirements>(level.content_requirements);

    const add = (): void =>
        setRequirements([
            ...requirements,
            {
                quantity: 1,
            },
        ]);

    const remove = (productIndex: number): void =>
        setRequirements(requirements.filter((_, itemIndex: number): boolean => productIndex !== itemIndex));

    return (
        <div className="rounded border p-10 my-5">
            <h2>Товары в корзине</h2>

            {requirements.length > 1 ? (
                <div className="my-5">
                    <RadioButton
                        name="condition"
                        value="every"
                        label="Все товары"
                        defaultChecked={level?.condition !== `any`}
                    />

                    <RadioButton
                        name="condition"
                        value="any"
                        label="Любой из товаров"
                        defaultChecked={level?.condition === `any`}
                    />
                </div>
            ) : (
                <input type="hidden" name="condition" value="every" />
            )}

            {requirements.map((requirement, index) => (
                <div key={requirement.id || `new-requirement-${index}`} className="rounded border p-6 my-5">
                    {requirement.id && (
                        <input
                            type="hidden"
                            name={`levels[${levelIndex}][content_requirements][${index}][id]`}
                            value={requirement.id}
                        />
                    )}

                    <ProductsSearch
                        label="Товар"
                        inputName={`levels[${levelIndex}][content_requirements][${index}][product_id]`}
                        productId={requirement.product_id}
                        productTitle={requirement.product_title}
                    />

                    <Input
                        label="Количество"
                        name={`levels[${levelIndex}][content_requirements][${index}][quantity]`}
                        defaultValue={requirement.quantity}
                    />

                    {requirements.length > 1 && (
                        <button
                            type="button"
                            className="btn btn-danger btn-sm me-2 mt-5"
                            onClick={(): void => remove(index)}
                        >
                            Удалить
                        </button>
                    )}
                </div>
            ))}

            <button type="button" className="btn btn-primary me-2 mt-5" onClick={add}>
                Добавить товар
            </button>
        </div>
    );
};

export default ContentRequirements;
