import React from "react";

import { updateDeliveryMethod } from "@admin/domain/deliveryMethods/api";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import BreadCrumbs from "@admin/components/BreadCrumbs";
import Editor from "@admin/components/Editor";
import Input from "@admin/components/Input";
import DeliveryMethodIconInput from "./DeliveryMethodIconInput";
import Switch from "@admin/components/Switch";
import type { Language } from "@admin/domain/languages/Languages";
import MultiLanguages from "@admin/components/MultiLanguage";
import Conditions from "@admin/pages/DeliveryMethods/DeliveryMethodEditor/Conditions";
import TextConditions from "@admin/pages/DeliveryMethods/DeliveryMethodEditor/TextConditions";

const LIST_URL = `/admin/delivery_methods` as const;

export default function DeliveryMethodEditor() {
    const deliveryMethod = window.CMS.deliveryMethod;

    if (!deliveryMethod) {
        throw Error(`Delivery method undefined`);
    }

    const currentLanguage = useLanguageContext().currentLanguage;

    const execute = async (body: FormData) => {
        await updateDeliveryMethod(deliveryMethod.id, body);
    };

    return (
        <>
            <BreadCrumbs
                className="mb-5"
                breadCrumbs={[
                    {
                        title: `Способы доставки`,
                        url: `/admin/delivery_methods/menu`,
                    },
                    {
                        title: `Список способов доставки`,
                        url: `/admin/delivery_methods`,
                    },
                    {
                        title: deliveryMethod.texts[currentLanguage]?.title || ``,
                    },
                ]}
            />

            <Editor backUrl={LIST_URL} onSubmit={execute}>
                <Switch label="Скрыть / Показать" name="is_public" defaultChecked={deliveryMethod.is_public} />

                <MultiLanguages
                    languageElement={(language: Language) => (
                        <Input
                            label="Название"
                            name={`texts[${language}][title]`}
                            defaultValue={deliveryMethod?.texts[language]?.title || ``}
                        />
                    )}
                />

                <Input label="Стоимость" name="cost" type="number" defaultValue={deliveryMethod.cost / 100} />

                <div className="d-flex">
                    <Input
                        label="Минимальный срок доставки"
                        name="delivery_time_min_days"
                        defaultValue={deliveryMethod.delivery_time_min_days}
                    />
                    <Input
                        className={{ block: `ms-4` }}
                        label="Максимальный срок доставки"
                        name="delivery_time_max_days"
                        defaultValue={deliveryMethod.delivery_time_max_days}
                    />
                </div>

                <Input label="Порядок отображения" name="order" type="number" defaultValue={deliveryMethod.order} />

                <DeliveryMethodIconInput label="Иконка" name="icon" icon={deliveryMethod.icon} />

                <DeliveryMethodIconInput label="Круглая икона" name="icon_circle" icon={deliveryMethod.icon_circle} />

                <DeliveryMethodIconInput
                    label="Иконка отображения на карте"
                    name="icon_map_point"
                    icon={deliveryMethod.icon_map_point}
                />

                <TextConditions />

                <Conditions />
            </Editor>
        </>
    );
}
