import { patch, post, request } from "../api";
import { type EditOrderData } from "./dto/EditOrderData";
import { type ProductForOrderData } from "./dto/ProductForOrderData";
import { ConsolidatedOrderData } from "@admin/domain/orders/dto/ConsolidatedOrderData";
import { OrderType } from "@admin/domain/orders/enums/OrderType";

type GetOrderData = {
    order: EditOrderData;
};

export const getOrder = (orderId: number): Promise<GetOrderData> => {
    return request({
        url: `/admin/orders/${orderId}`,
    });
};

export const getProductsForOrder = (orderId: number, query = ``): Promise<ProductForOrderData[]> => {
    return request({
        url: `/admin/orders/${orderId}/products`,
        query: { query },
    });
};

export const updateOrder = (order: EditOrderData): Promise<null> => {
    return patch({
        url: `/admin/orders/${order.id}/update`,
        body: order,
    });
};

export const cancelOrder = (orderId: number): Promise<null> => {
    return patch({
        url: `/admin/orders/${orderId}/cancel`,
    });
};

export const updateOrderProducts = (orderId: number, products: { id: string; quantity: number }[]): Promise<null> => {
    return patch({
        url: `/admin/orders/${orderId}/products`,
        body: {
            products,
        },
    });
};

export const applyVoucher = (orderId: number, body: FormData): Promise<null> => {
    return post({
        url: `/admin/orders/${orderId}/apply_voucher`,
        body,
    });
};

export const storeOrderReceipt = (orderId: number): Promise<null> => {
    return post({
        url: `/admin/orders/${orderId}/store_receipt`,
    });
};

export const storeOrderReceiptUrls = (orderId: number): Promise<null> => {
    return post({
        url: `/admin/orders/${orderId}/store_receipt_urls`,
    });
};

export const storeCustomOrderReceipt = (orderId: number, data: FormData): Promise<null> => {
    return post({
        url: `/admin/orders/${orderId}/store_custom_receipt`,
        body: data,
    });
};

export const storeLog = (orderId: number, message: string): Promise<null> => {
    return post({
        url: `/admin/orders/${orderId}/store_log`,
        body: {
            message,
        },
    });
};

export const getConsolidatedOrders = (userId: number): Promise<ConsolidatedOrderData[]> => {
    return request({
        url: `/admin/orders/consolidated/${userId}`,
    });
};

export const storeOrder = (data: {
    creator_id: number;
    type: OrderType;
    consolidated_order_id: number | null;
    currency: string;
}): Promise<{ id: number }> => {
    return post({
        url: `/admin/orders`,
        body: data,
    });
};

export const applyOrderDiscounts = (
    orderId: number,
    creatorDiscount: number,
    targetDiscount: number,
): Promise<null> => {
    return patch({
        url: `/admin/orders/${orderId}/apply_discounts`,
        body: {
            target_discount: targetDiscount,
            creator_discount: creatorDiscount,
        },
    });
};

export const registerInAccounting = (orderId: number): Promise<null> => {
    return post({
        url: `/admin/orders/${orderId}/register_in_accounting`,
    });
};

export const registerInAnalyticalDb = (orderId: number): Promise<null> => {
    return post({
        url: `/admin/orders/${orderId}/register_in_ab`,
    });
};

export const registerPaymentInAccounting = (orderId: number): Promise<null> => {
    return post({
        url: `/admin/orders/${orderId}/register_payment_in_accounting`,
    });
};

export const registerCustomPaymentInAccounting = (orderId: number, data: FormData): Promise<null> => {
    return post({
        url: `/admin/orders/${orderId}/register_custom_payment_in_accounting`,
        body: data,
    });
};
