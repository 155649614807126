import { HowCreateOrderSubBlockModel } from "@admin/domain/uniquePages/models/HowCreateOrderModel";
import React, { useState } from "react";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import { v4 as uuid } from "uuid";
import { initLanguageKeyObject } from "@admin/domain/languages/Languages";
import { makePath } from "@admin/utils/makePath";
import HowCreateOrderSubBlockModal from "./HowCreateOrderSubBlockModal";
import Sortable from "@admin/components/Sortable";
import { ActionBlock } from "./HowCreateOrderActionBlocks";

type Props = {
    initSubBlocks: SubBlock[];
    parent: ActionBlock;
    parentIndex: number;
    onUpdate: (data: ActionBlock) => void;
};

export type SubBlock = { id: string } & HowCreateOrderSubBlockModel;

export default function HowCreateOrderSubBlocks(props: Props) {
    const languageContext = useLanguageContext();

    const [isEdit, setIsEdit] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const [subBlocks, setSubBlocks] = useState<SubBlock[]>(props.initSubBlocks);
    const [subBlockData, setSubBlockData] = useState<SubBlock>({
        id: uuid(),
        description: initLanguageKeyObject(),
        notification: initLanguageKeyObject(),
        image: null,
    });

    const addHandle = (data: SubBlock) => {
        setSubBlocks((prev) => [...prev, { ...data }]);
        props.onUpdate({
            ...props.parent,
            sub_blocks: [...props.parent.sub_blocks, data],
        });
    };

    const updateHandle = (data: SubBlock) => {
        setSubBlocks((prev) =>
            prev.map((value) => {
                if (value.id === data.id) {
                    value = { ...data };
                }
                return value;
            }),
        );
        props.onUpdate({
            ...props.parent,
            sub_blocks: [...props.parent.sub_blocks, data],
        });
    };

    return (
        <div>
            <h4 className="mb-5">Блок с подпунктами</h4>
            <button
                className="btn btn-primary mb-5"
                type="button"
                onClick={() => {
                    setSubBlockData({
                        id: uuid(),
                        notification: initLanguageKeyObject(),
                        description: initLanguageKeyObject(),
                        image: null,
                    });
                    setIsEdit(false);
                    setShowModal(true);
                }}
            >
                Добавить подпункт
            </button>

            <Sortable list={subBlocks} setList={setSubBlocks}>
                {subBlocks.map((subBlock, index) => (
                    <div key={subBlock.id}>
                        <h4 className="mb-5">Подпункт {index + 1}</h4>

                        {subBlock.image && (
                            <img
                                className="img-thumbnail mb-5"
                                src={makePath(subBlock.image?.path)}
                                style={{ width: `650px`, height: `280px` }}
                                alt=""
                            />
                        )}

                        <div className="mb-5">
                            <span className="fw-bolder">Описание действия</span>
                            <p>{subBlock.description[languageContext.currentLanguage]}</p>
                        </div>

                        <div className="mb-5">
                            <span className="fw-bolder">Текст уведомления</span>
                            <p>{subBlock.notification[languageContext.currentLanguage]}</p>
                        </div>

                        {languageContext.availableLanguages.map((language) => (
                            <div key={language}>
                                <input
                                    type="hidden"
                                    name={`content[action_blocks][${props.parentIndex}][sub_blocks][${index}][description][${language}]`}
                                    value={subBlock.description[language]}
                                />
                                <input
                                    type="hidden"
                                    name={`content[action_blocks][${props.parentIndex}][sub_blocks][${index}][notification][${language}]`}
                                    value={subBlock.notification[language] || ``}
                                />
                            </div>
                        ))}
                        <input
                            type="hidden"
                            name={`content[action_blocks][${props.parentIndex}][sub_blocks][${index}][file_id]`}
                            value={subBlock.image?.id}
                        />

                        <div className="mb-5 d-flex justify-content-between">
                            <a
                                className="card-link cursor-pointer text-success"
                                onClick={(event) => {
                                    event.preventDefault();
                                    setSubBlockData(subBlock);
                                    setIsEdit(true);
                                    setShowModal(true);
                                }}
                            >
                                Редактировать
                            </a>
                            <a
                                className="card-link cursor-pointer text-danger"
                                onClick={(event) => {
                                    event.preventDefault();
                                    setSubBlocks((prev) => prev.filter((value) => value.id !== subBlock.id));
                                    props.onUpdate({
                                        ...props.parent,
                                        sub_blocks: props.parent.sub_blocks.filter((value) => subBlock.id !== value.id),
                                    });
                                }}
                            >
                                Удалить
                            </a>
                        </div>
                    </div>
                ))}
            </Sortable>

            <HowCreateOrderSubBlockModal
                showModal={showModal}
                closeModal={() => setShowModal(false)}
                onSubmit={isEdit ? updateHandle : addHandle}
                initSubBlockData={subBlockData}
            />
        </div>
    );
}
