import React, { createRef, useState } from "react";
import { ActiveComponentModel } from "@admin/domain/products/models/edit/ProductSpecificationsModel";
import { getActiveComponentByAccountingId } from "@admin/domain/products/api";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import convertToMapLanguageObject from "@admin/domain/languages/Languages";
import Sortable from "@admin/components/Sortable";

export default function ActiveComponents(props: { activeComponents: ActiveComponentModel[] }) {
    const currentLanguage = useLanguageContext().currentLanguage;
    const [activeComponents, setActiveComponents] = useState(props.activeComponents);
    const inputRef = createRef<HTMLInputElement>();

    async function addActiveComponent() {
        const accountingId = inputRef.current?.value;
        if (!accountingId) return;

        await getActiveComponentByAccountingId(accountingId).then((activeComponent) => {
            if (!activeComponent) return;

            activeComponent.textsMap = convertToMapLanguageObject(activeComponent.texts);
            setActiveComponents((prevState) => [...prevState, activeComponent]);
        });
    }

    return (
        <div className="rounded border p-10 mb-5">
            <h4 className="mb-5">Активные компоненты</h4>

            <div className="input-group mb-5 w-50">
                <input type="text" className="form-control" placeholder="UUID активного компонента" ref={inputRef} />
                <button className="btn btn-primary" type="button" onClick={() => addActiveComponent()}>
                    Добавить
                </button>
            </div>

            <Sortable list={activeComponents} setList={setActiveComponents}>
                {activeComponents.map((activeComponent) => (
                    <div key={activeComponent.id} className="card border mb-4 position-relative">
                        <div className="card-body">
                            <input
                                type="hidden"
                                name="specifications[active_components][]"
                                value={activeComponent.id}
                            />

                            <div>
                                <span className="fw-bolder">UUID:</span>
                                {` `}
                                {activeComponent.accounting_id}
                            </div>
                            <div>
                                <span className="fw-bolder">Название:</span>
                                {` `}
                                {activeComponent.textsMap?.get(currentLanguage)?.title}
                            </div>
                        </div>
                        <i
                            className="bi bi-x-circle position-absolute text-danger end-0 mt-5 me-5 cursor-pointer"
                            onClick={() =>
                                setActiveComponents((prevState) =>
                                    prevState.filter((prevValue) => prevValue.id !== activeComponent.id),
                                )
                            }
                        />
                    </div>
                ))}
            </Sortable>
        </div>
    );
}
