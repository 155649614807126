import React, { ChangeEvent, useState } from "react";
import Input, { InputProps } from "../Input";
import { getFormattedDate, now } from "@admin/utils/dates";
import { Override } from "@admin/utils/overrideType";

type DateTimeInputProps = Override<
    InputProps,
    {
        value?: string | number | undefined;
        defaultValue?: string | number | undefined;
        showNowButton?: boolean;
    }
>;

const DateTimeInput: React.FC<DateTimeInputProps> = ({ value, defaultValue, showNowButton, ...props }) => {
    const [currentValue, setCurrentValue] = useState<string>(
        value ? getFormattedDate(new Date(value)) : defaultValue ? getFormattedDate(new Date(defaultValue)) : ``,
    );

    const onChange = (event: ChangeEvent<HTMLInputElement>) => setCurrentValue(event.target.value);

    return (
        <div className={showNowButton ? `mb-5` : `mb-0`}>
            <Input
                {...props}
                type="datetime-local"
                value={currentValue}
                onChange={onChange}
                className={{
                    ...(props.className || {}),
                    block: (props.className?.block || ``) + (showNowButton ? ` mb-0` : ``),
                }}
            />

            {showNowButton && (
                <small className="link link-primary cursor-pointer" onClick={() => setCurrentValue(now())}>
                    Сейчас
                </small>
            )}
        </div>
    );
};

export default DateTimeInput;
