import { type BusinessExpansionPageModel } from "@admin/domain/uniquePages/models/BusinessExpansionPageModel";
import { type UniquePageProps } from "../index";
import BusinessExpansionPageNewDistributorRegister from "./BusinessExpansionPageNewDistributorRegister";
import BusinessExpansionPageStoreDeliveryPoint from "./BusinessExpansionPageStoreDeliveryPoint";
import BusinessExpansionPageStoreServiceCenter from "./BusinessExpansionPageStoreServiceCenter";

export default function BusinessPage({ page }: UniquePageProps<BusinessExpansionPageModel>) {
    return (
        <div>
            <BusinessExpansionPageNewDistributorRegister page={page} />
            <BusinessExpansionPageStoreDeliveryPoint page={page} />
            <BusinessExpansionPageStoreServiceCenter page={page} />
        </div>
    );
}
