import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Alert } from "react-bootstrap";

import type { ActionBlock } from "./HowCreateOrderActionBlocks";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import MultiLanguages from "@admin/components/MultiLanguage";
import TextArea from "@admin/components/TextArea";
import ImageInput from "@admin/components/ImageInput";

type Props = {
    showModal: boolean;
    closeModal: () => void;
    onSubmit: (data: ActionBlock) => void;
    initActionBlockData: ActionBlock;
};

export default function HowCreateOrderActionBlockModal(props: Props) {
    const languageContext = useLanguageContext();

    const data = props.initActionBlockData;

    const [errors, setErrors] = useState<string[]>([]);

    const submit = () => {
        const imageError = !data.image && data.sub_blocks.length === 0 ? [`Изображение обязательно`] : [];
        const descriptionErrors = languageContext.availableLanguages
            .filter((language) => !data.description[language])
            .map((language) => `Поле "${language} Описание действия" обязательно для заполнения`);

        const newErrors = [...imageError, ...descriptionErrors];
        if (newErrors.length > 0) {
            setErrors(newErrors);
            return;
        }

        props.onSubmit(data);
        props.closeModal();
        setErrors([]);
    };

    return (
        <Modal centered show={props.showModal} size="xl" onHide={props.closeModal}>
            <Modal.Header>
                <Modal.Title>Форма блока действия</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {errors.length > 0 && (
                    <Alert variant="danger">
                        {errors.map((err, index) => (
                            <div key={index}>{err}</div>
                        ))}
                    </Alert>
                )}
                {data.sub_blocks.length === 0 && (
                    <div className="d-flex align-items-center justify-content-center">
                        <ImageInput
                            label="Изображение"
                            name=""
                            defaultValue={data.image}
                            onChange={(image) => (data.image = image)}
                            width="650px"
                            height="280px"
                        />
                    </div>
                )}
                <MultiLanguages
                    languageElement={(language) => (
                        <TextArea
                            label="Описание действия"
                            defaultValue={data.description[language]}
                            onChange={(event) => (data.description[language] = event.target.value)}
                            required
                        />
                    )}
                />
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" type="button" onClick={props.closeModal}>
                    Закрыть
                </button>
                <button className="btn btn-primary" type="button" onClick={submit}>
                    Сохранить
                </button>
            </Modal.Footer>
        </Modal>
    );
}
