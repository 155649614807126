import React from "react";
import classNames from "classnames";

import { Override } from "@admin/utils/overrideType";
import { useIsInvalid } from "../FormErrorsContext";

type className = {
    block?: string;
    input?: string;
};

export type InputProps = {
    label: string;
    className?: className;
} & Override<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    {
        className?: className;
    }
>;

const Input: React.FC<InputProps> = ({ label, className, type = `text`, ...props }) => {
    const isInvalid = useIsInvalid(props.name);

    return (
        <div className={className?.block || `mb-5`}>
            <label className="form-label">{label}</label>
            <input
                type={type}
                {...props}
                className={classNames(className?.input || `form-control`, {
                    "is-invalid": isInvalid,
                })}
            />
        </div>
    );
};

export default Input;
