import React, { useState } from "react";

import type ComplexPromotionLevelModel from "@admin/domain/complexPromotions/models/ComplexPromotionLevelModel";
import Input from "@admin/components/Input";
import ProductsSearch from "@admin/components/ProductsSearch";

type Rewards = ComplexPromotionLevelModel[`rewards`];
type Reward = Rewards[0];

type Props = {
    levelIndex: number;
    level: ComplexPromotionLevelModel;
};

const Rewards: React.FC<Props> = ({ levelIndex, level }) => {
    const initial = level.rewards.map((reward) => ({
        ...reward,
        type: (reward.discount_product_id ? `discount` : `gift`) as Reward[`type`],
    }));

    const [rewards, setRewards] = useState<Rewards>(initial);

    const addReward = (type: Reward[`type`]) =>
        setRewards([
            ...rewards,
            {
                quantity: 1,
                type: type,
            },
        ]);

    const deleteReward = (rewardIndex: number) =>
        setRewards(rewards.filter((_, itemIndex: number): boolean => rewardIndex !== itemIndex));

    const gifts = rewards.filter(({ type }) => type === `gift`);
    const discounts = rewards.filter(({ type }) => type === `discount`);

    let label;

    if (gifts.length === 0 && discounts.length > 0) {
        label = `Скидки`;
    } else if (gifts.length > 0 && discounts.length === 0) {
        label = `Подарки`;
    } else {
        label = `Вознаграждения`;
    }

    return (
        <div className="rounded border p-10 my-5 me-5">
            <h3>{label}</h3>

            {rewards.map((reward, rewardIndex) => (
                <div key={reward.id || `new-reward-${rewardIndex}`} className="rounded border p-6 my-5 me-5">
                    {reward.id && (
                        <input
                            type="hidden"
                            name={`levels[${levelIndex}][rewards][${rewardIndex}][id]`}
                            value={reward.id}
                        />
                    )}

                    <ProductsSearch
                        label={reward.type === `discount` ? `Заменяемый товар` : `Подарок`}
                        inputName={`levels[${levelIndex}][rewards][${rewardIndex}][product_id]`}
                        productId={reward.product_id}
                        productTitle={reward.product_title}
                    />

                    {reward.type === `discount` && (
                        <ProductsSearch
                            label="Товар по скидке"
                            inputName={`levels[${levelIndex}][rewards][${rewardIndex}][discount_product_id]`}
                            productId={reward.discount_product_id}
                            productTitle={reward.discount_product_title}
                        />
                    )}

                    <Input
                        label="Количество"
                        name={`levels[${levelIndex}][rewards][${rewardIndex}][quantity]`}
                        defaultValue={reward.quantity}
                    />

                    {rewards.length > 1 && (
                        <button
                            type="button"
                            className="btn btn-danger btn-sm me-2"
                            onClick={() => deleteReward(rewardIndex)}
                        >
                            Удалить
                        </button>
                    )}
                </div>
            ))}

            <button type="button" className="btn btn-primary me-2" onClick={() => addReward(`gift`)}>
                Добавить подарок
            </button>

            <button type="button" className="btn btn-primary me-2" onClick={() => addReward(`discount`)}>
                Добавить скидку на товар
            </button>
        </div>
    );
};

export default Rewards;
