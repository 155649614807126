import { useState } from "react";
import { Collapse } from "react-bootstrap";

import type { EditOrderData } from "@admin/domain/orders/dto/EditOrderData";
import { registerInAnalyticalDb } from "@admin/domain/orders/api";
import { useLoaderContext } from "@admin/components/LoaderContext";
import { useOrderHooks } from "@admin/pages/OrderEditor/hooks";
import JsonView from "@admin/pages/OrderEditor/JsonView";

type Props = {
    order: EditOrderData;
};

export default function AnalyticalDb({ order }: Props) {
    const [analyticalDbDataIsCollapsed, setAnalyticalDbDataIsCollapsed] = useState<boolean>(false);

    const { isLoading } = useLoaderContext();
    const { orderRequest } = useOrderHooks();

    const send = (): void => {
        orderRequest(async (): Promise<null> => await registerInAnalyticalDb(order.id));
    };

    return (
        <>
            <h4 className="mb-5">Синхронизация с АБ</h4>
            <p>
                Статус заказа:
                {order.is_available_in_ab ? `есть в Аналитической базе` : `отсутствует в Аналитической базе`}
            </p>

            {order.analytical_db_data ? (
                <div className="border rounded p-5 mb-3">
                    <div
                        className="cursor-pointer"
                        onClick={(): void => setAnalyticalDbDataIsCollapsed(!analyticalDbDataIsCollapsed)}
                    >
                        Заказы был выгружен в АБ. Id = {order.id_from_ab}
                    </div>
                    <Collapse in={analyticalDbDataIsCollapsed}>
                        <div className="mb-5">
                            <p>Данные заказа, переданные в Аналитическую базу</p>
                            <JsonView data={order.analytical_db_data} />
                        </div>
                    </Collapse>
                </div>
            ) : (
                <p>Заказ отсутствует в Аналитической базе</p>
            )}

            <button type="button" className="btn btn-info" onClick={send} disabled={isLoading}>
                Отправить в АБ
            </button>
        </>
    );
}
