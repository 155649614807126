import isArray from "lodash-es/isArray";
import isPlainObject from "lodash-es/isPlainObject";

export const setToArray = <T>(set: Set<T>): Array<T> => Array.from(set.values());

export const toArray = <T>(value: Set<T> | Array<T> | object): Array<T> => {
    if (value instanceof Set) {
        return setToArray(value);
    }

    if (isPlainObject(value)) {
        return Object.values(value);
    }

    if (isArray(value)) {
        return value as Array<T>;
    }

    return [];
};

export const toDictionary = <I, K = number>(items: I[], identifier = `id`): Map<K, I> =>
    new Map(items.map((item: I): [K, I] => [item[identifier], item]));
