import { BusinessPageModel } from "@admin/domain/uniquePages/models/BusinessPageModel";
import { type UniquePageProps } from "../index";
import BusinessPageCatalog from "./BusinessPageCatalog";
import BusinessPageCalculator from "./BusinessPageCalculator";
import BusinessPageImagesWithText from "./BusinessPageImagesWithText";
import BusinessPageMain from "./BusinessPageMain";
import BusinessPageVideo from "./BusinessPageVideo";
import BusinessPageLeaders from "./BusinessPageLeaders";
import BusinessPageHowToStart from "./BusinessPageHowToStart";
import BusinessPageHowToEarn from "./BusinessPageHowToEarn";
import BusinessPageFaq from "./BusinessPageFaq";

export default function BusinessPage({ page }: UniquePageProps<BusinessPageModel>) {
    return (
        <div>
            <BusinessPageMain page={page} />
            <BusinessPageCatalog page={page} />
            <BusinessPageCalculator page={page} />
            <BusinessPageImagesWithText page={page} />
            <BusinessPageVideo page={page} />
            <BusinessPageLeaders page={page} />
            <BusinessPageHowToEarn page={page} />
            <BusinessPageHowToStart page={page} />
            <BusinessPageFaq page={page} />
        </div>
    );
}
