import React from "react";
import { post } from "@admin/domain/api";

type Props = {
    perPage: number;
};

export default function PerPage({ perPage }: Props) {
    return (
        <li className="page-item">
            <select
                name="per_page"
                id="store_per_page_setting"
                className="form-control d-inline-block"
                value={perPage}
                onChange={async (e: React.ChangeEvent<HTMLSelectElement>): Promise<void> => {
                    await post({
                        url: `/admin/profile/store_per_page_setting`,
                        body: {
                            per_page: e.target.value,
                        },
                    });

                    window.location.reload();
                }}
            >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
        </li>
    );
}
