import React, { useEffect, useRef, useState } from "react";

import { getCaretPosition, setCaretPosition } from "@admin/utils/caret";
import type { PageSeoParamsModel } from "@admin/domain/seo/models/PageSeoParamsModel";
import { getText, parse, renderHtml, renderPlain, splice, type Value, type Variable, VARIABLES } from "./utils";

type Props = {
    label: string;
    name: string;
    defaultValue: string | null | undefined;
    fallback: PageSeoParamsModel[`fallback`][`title`] | PageSeoParamsModel[`fallback`][`description`] | undefined;
    includePrice?: boolean;
};

export default function SeoTextInput({ label, name, defaultValue, fallback, includePrice = false }: Props) {
    const [value, setValue] = useState<Value>(parse(defaultValue || ``));

    const inputRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.innerHTML = renderHtml(value);
        }
    }, [inputRef.current]);

    const placeVariable = (variable: Variable) => {
        if (!inputRef.current) {
            return;
        }

        const caretPosition = getCaretPosition(inputRef.current);

        const { newValue, newCaretPosition } = splice(value, caretPosition, variable);

        setValue(newValue);

        inputRef.current.innerHTML = renderHtml(newValue);

        inputRef.current.focus();
        setCaretPosition(inputRef.current, newCaretPosition);
    };

    const handleDescriptionChange = () => {
        const text = getText(inputRef.current);

        setValue(parse(text));
    };

    return (
        <div className="mb-5">
            <label className="form-label">{label}</label>
            <div className="border rounded">
                <div className="border-bottom border-bottom-1 py-2 px-4">
                    Переменные:{` `}
                    {VARIABLES.map((variable) => {
                        if (!includePrice && variable.value === `price`) {
                            return null;
                        }

                        return (
                            <button
                                key={variable.value}
                                type="button"
                                className="btn btn-light-success btn-sm me-2 px-2 py-1"
                                onClick={() => placeVariable(variable)}
                            >
                                {variable.label}
                            </button>
                        );
                    })}
                </div>

                <div
                    className="m-4"
                    contentEditable
                    onInput={handleDescriptionChange}
                    ref={inputRef}
                    style={{
                        outline: `none`,
                    }}
                />
                <input type="hidden" name={name} value={renderPlain(value)} />
            </div>

            {fallback && fallback.value && (
                <div className="mt-2">
                    <div className="form-label mb-0">
                        Значение из{` `}
                        <a href={`/admin/seo/templates/${fallback.id}`}>родительского шаблона</a>:
                    </div>
                    <div
                        className="bg-light-info p-2 rounded"
                        dangerouslySetInnerHTML={{
                            __html: renderHtml(parse(fallback.value || ``)),
                        }}
                    />
                </div>
            )}
        </div>
    );
}
