import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import { PhotoWithTextSliderBlockValueModel, SliderModel } from "@admin/domain/content/models/ContentBlockValueModel";
import type { BlockProps } from "@admin/components/Content/ContentBlocks";
import MultiLanguages from "@admin/components/MultiLanguage";
import ImageInput from "@admin/components/ImageInput";

type Props = BlockProps<PhotoWithTextSliderBlockValueModel>;

const defaultValue: PhotoWithTextSliderBlockValueModel = {
    title: {},
    slides: [],
};

export default function PhotoWithTextSliderBlock({ index, value = structuredClone(defaultValue) }: Props) {
    const initialSlides = (value?.slides || []).map((item: SliderModel) => ({
        ...item,
        id: uuid(),
    }));

    const [slides, setSlides] = useState<SliderModel[]>(initialSlides);

    const addSlide = () => {
        setSlides([
            ...slides,
            {
                id: uuid(),
                file: null,
                title: {},
                sub_title: {},
                description: {},
            },
        ]);
    };

    const removeSlide = (slide: SliderModel) => {
        setSlides(slides.filter((item) => item.id !== slide.id));
    };

    return (
        <>
            <MultiLanguages
                languageElement={(language) => (
                    <div className="mb-5">
                        <label className="form-label">Заголовок</label>
                        <input
                            className="form-control"
                            name={`blocks[${index}][value][title][${language}]`}
                            defaultValue={value?.title[language] || ``}
                        />
                    </div>
                )}
            />

            <div className="row row-cols-auto">
                {slides.map((slide, slideIndex) => (
                    <div key={slide.id} className="col mb-5">
                        <MultiLanguages
                            languageElement={(language) => (
                                <>
                                    <div className="mb-5">
                                        <label className="form-label">Заголовок</label>
                                        <input
                                            className="form-control"
                                            name={`blocks[${index}][value][slides][${slideIndex}][title][${language}]`}
                                            defaultValue={slide.title[language] || ``}
                                        />
                                    </div>

                                    <div className="mb-5">
                                        <label className="form-label">Подзаголовок</label>
                                        <input
                                            className="form-control"
                                            name={`blocks[${index}][value][slides][${slideIndex}][sub_title][${language}]`}
                                            defaultValue={slide.sub_title[language] || ``}
                                        />
                                    </div>

                                    <div className="mb-5">
                                        <label className="form-label">Описание</label>
                                        <input
                                            className="form-control"
                                            name={`blocks[${index}][value][slides][${slideIndex}][description][${language}]`}
                                            defaultValue={slide.description[language] || ``}
                                        />
                                    </div>
                                </>
                            )}
                        />

                        <div className="col mb-5">
                            <ImageInput
                                label="Изображение"
                                name={`blocks[${index}][value][slides][${slideIndex}][file_id]`}
                                defaultValue={slide.file}
                                width="300px"
                                height={undefined}
                                thumbnailWidth="300px"
                                thumbnailHeight="300px"
                            />
                        </div>

                        <div>
                            <a className="card-link text-danger" onClick={() => removeSlide(slide)}>
                                Удалить
                            </a>
                        </div>
                    </div>
                ))}
            </div>

            <div className="mb-5 me-5 d-flex">
                <button type="button" className="btn btn-success" onClick={addSlide}>
                    Добавить слайд
                </button>
            </div>
        </>
    );
}
