import React, { useState } from "react";
import Input from "@admin/components/Input";
import Switch from "@admin/components/Switch";
import { v4 as uuid } from "uuid";
import { DeliveryMethodTextConditionModel } from "@admin/domain/deliveryMethods/models/DeliveryMethodModel";

type TextCondition = Partial<DeliveryMethodTextConditionModel>;

export default function TextConditions() {
    const { deliveryMethod } = window.CMS;

    if (!deliveryMethod) {
        throw Error(`Delivery method is undefined`);
    }

    const initTextConditions = (deliveryMethod.text_conditions || []).map((value) => ({...value, id: uuid()}));

    const [deliveryMethodTextConditions, setDeliveryMethodTextConditions] =
        useState<TextCondition[]>(initTextConditions);

    return (
        <div className="rounded border p-10 my-5">
            <h3>Текстовые условия для страницы “Доставка”</h3>
            {deliveryMethodTextConditions?.map((condition, index) => (
                <div key={index} className="rounded border p-10 my-5">
                    <input type="hidden" name={`text_conditions[${index}][id]`} value={condition?.id} />
                    <Input
                        label="Текст"
                        name={`text_conditions[${index}][text]`}
                        defaultValue={condition?.text ? condition.text : ``}
                    />
                    <Switch
                        label="Является ссылкой"
                        name={`text_conditions[${index}][is_link]`}
                        defaultChecked={condition.is_link}
                    />
                    <Input
                        label="Ссылка"
                        name={`text_conditions[${index}][link]`}
                        defaultValue={condition?.link ? condition?.link : ``}
                    />
                    <a
                        className="card-link text-danger"
                        onClick={() => {
                            setDeliveryMethodTextConditions((prev) =>
                                prev.filter((_, prevIndex) => prevIndex !== index),
                            );
                        }}
                    >
                        Удалить
                    </a>
                </div>
            ))}
            <div className="d-flex justify-content-end mb-5">
                <button
                    className="btn btn-primary"
                    type="button"
                    onClick={(event) => {
                        event.preventDefault();
                        setDeliveryMethodTextConditions((prevState) => [
                            ...prevState,
                            {
                                id: uuid(),
                            },
                        ]);
                    }}
                >
                    Добавить текстовое условие
                </button>
            </div>
        </div>
    );
}
