import React from "react";
import DeliveryAndPaymentEditorModal from "./DeliveryAndPaymentEditorModal";
import { useOrderContext } from "../../OrderContext";

export default function GeneralInfoDeliveryAndPaymentDetails() {
    const {
        order: { shipping_info, is_editable, payment_method },
    } = useOrderContext();
    const [showModal, setShowModal] = React.useState(false);

    return (
        <div className="rounded border p-6 mb-5">
            <div className="fw-bolder h4">Детали доставки и оплаты</div>

            <div className="mb-2">
                <div className="fw-bolder">Данные получателя</div>
                <div className="ms-5">
                    <div>
                        <span className="fw-bolder">Имя: </span>
                        {shipping_info?.name}
                    </div>
                    <div>
                        <span className="fw-bolder">Фамилия: </span>
                        {shipping_info?.family_name}
                    </div>
                    <div>
                        <span className="fw-bolder">Отчество: </span>
                        {shipping_info?.patronymic}
                    </div>
                    <div>
                        <span className="fw-bolder">Email: </span>
                        {shipping_info?.email}
                    </div>
                    <div>
                        <span className="fw-bolder">Телефон: </span>
                        {shipping_info?.phone}
                    </div>

                    {shipping_info?.postcode && (
                        <div>
                            <span className="fw-bolder">Индекс: </span>
                            {shipping_info?.postcode}
                        </div>
                    )}

                    {shipping_info?.street && (
                        <div>
                            <span className="fw-bolder">Улица: </span>
                            {shipping_info?.street}
                        </div>
                    )}

                    {shipping_info?.house && (
                        <div>
                            <span className="fw-bolder">Дом: </span>
                            {shipping_info?.house}
                        </div>
                    )}

                    {shipping_info?.flat && (
                        <div>
                            <span className="fw-bolder">Квартира: </span>
                            {shipping_info?.flat}
                        </div>
                    )}
                </div>
            </div>

            <div className="mb-2">
                <span className="fw-bolder">Способ оплаты: </span>
                {payment_method?.title}
            </div>

            <div className="mb-2">
                <div className="fw-bolder">Адрес доставки</div>
                <div className="ms-5">
                    <div>
                        <span className="fw-bolder">Страна: </span>
                        {shipping_info?.country_name}
                    </div>
                    <div>
                        <span className="fw-bolder">Населенный пункт: </span>
                        {shipping_info?.locality_name}
                    </div>
                </div>
            </div>

            <div className="mb-2">
                <span className="fw-bolder">Способ доставки: </span>
                {shipping_info?.delivery_method_title}
            </div>

            {shipping_info?.delivery_point_address && (
                <div className="mb-2">
                    <span className="fw-bolder">Пункт выдачи: </span>
                    {shipping_info.delivery_point_address}
                </div>
            )}

            {is_editable && (
                <a
                    className="d-block link link-primary"
                    href="#"
                    onClick={(event: React.MouseEvent<HTMLAnchorElement>): void => {
                        event.preventDefault();
                        setShowModal(true);
                    }}
                >
                    Редактировать
                </a>
            )}

            {is_editable && showModal && <DeliveryAndPaymentEditorModal closeModal={() => setShowModal(false)} />}
        </div>
    );
}
