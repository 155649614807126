import React from "react";

import { usePhones } from "./hooks/usePhones";
import Input from "@admin/components/Input";

const Phones: React.FC = () => {
    const { phones, addPhone, deletePhone } = usePhones();

    return (
        <div className="d-flex">
            <div className="rounded border p-10 my-5 w-100">
                {phones.map(
                    (phone: string, index: number): React.JSX.Element => (
                        <div className="row" key={`${index}-${phone}`}>
                            <div className="col">
                                <Input label="Телефон" name={`phones[${index}]`} defaultValue={phone} />
                            </div>
                            <div className="cursor-pointer col mt-12">
                                {index > 0 && (
                                    <div onClick={(): void => deletePhone(index)}>
                                        <i className="fas fa-minus me-2" />
                                        Удалить телефон
                                    </div>
                                )}
                            </div>
                        </div>
                    ),
                )}

                <div className="cursor-pointer d-inline-block" onClick={addPhone}>
                    <i className="fas fa-plus me-2" />
                    Добавить телефон
                </div>
            </div>
        </div>
    );
};

export default Phones;
