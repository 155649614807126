import React, { useMemo } from "react";
import { v4 as uuid } from "uuid";

import type { BusinessPageModel } from "@admin/domain/uniquePages/models/BusinessPageModel";
import type { UniquePageProps } from "../index";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import MultiLanguages from "@admin/components/MultiLanguage";
import Select from "@admin/components/Select";
import ImageInput from "@admin/components/ImageInput";

export default function BusinessPageCatalog({ page }: UniquePageProps<BusinessPageModel>) {
    const banners: BusinessPageModel[`catalog__banners`] = useMemo(
        () =>
            (page.contentObject.catalog__banners || []).map((value) => ({
                ...value,
                id: uuid(),
            })),
        [page.contentObject.catalog__banners],
    );
    const currentLanguage = useLanguageContext().currentLanguage;

    const categories = {};
    page.payload.categories?.forEach((category) => {
        const textsMap = new Map(category.texts.map((text) => [text.language, text]));
        categories[category.id] = textsMap.get(currentLanguage)?.title;
    });

    return (
        <div className="mb-10">
            <h4 className="mb-5">Блок с категориями/подкатегориями продуктов</h4>
            <div>
                <MultiLanguages
                    languageElement={(language) => (
                        <div>
                            <div className="mb-5">
                                <label className="form-label">Заголовок</label>
                                <input
                                    className="form-control"
                                    name={`content[catalog__title][${language}]`}
                                    defaultValue={page.contentObject.catalog__title?.[language] || ``}
                                />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">Описание</label>
                                <textarea
                                    className="form-control"
                                    name={`content[catalog__description][${language}]`}
                                    defaultValue={page.contentObject.catalog__description?.[language] || ``}
                                />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">Текст кнопки</label>
                                <textarea
                                    className="form-control"
                                    name={`content[catalog__button_text][${language}]`}
                                    defaultValue={page.contentObject.catalog__button_text?.[language] || ``}
                                />
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="row row-cols-auto">
                {banners.map((banner, index) => (
                    <div key={banner.id} className="col mb-5">
                        <Select
                            label="Кактегория"
                            options={categories}
                            name={`content[catalog__banners][${index}][category_id]`}
                            defaultValue={banner.category?.id}
                            required
                        />
                        <MultiLanguages
                            languageElement={(language) => (
                                <div>
                                    <div className="mb-5">
                                        <label className="form-label">Заголовок</label>
                                        <input
                                            className="form-control"
                                            name={`content[catalog__banners][${index}][title][${language}]`}
                                            defaultValue={banner.title?.[language] || ``}
                                        />
                                    </div>
                                </div>
                            )}
                        />
                        <div key={index} className="col mb-5">
                            <ImageInput
                                label="Изображение"
                                name={`content[catalog__banners][${index}][file_id]`}
                                defaultValue={banner.file}
                                width="300px"
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
