import React, { DetailedHTMLProps, TextareaHTMLAttributes } from "react";
import classNames from "classnames";
import { useIsInvalid } from "../FormErrorsContext";

type TextAreaProps = {
    label: string;
} & DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;

const TextArea: React.FC<TextAreaProps> = ({ label, ...props }) => {
    const isInvalid = useIsInvalid(props.name);

    return (
        <div className="mb-5">
            <label className="form-label">{label}</label>
            <textarea
                {...props}
                className={classNames(`form-control`, {
                    "is-invalid": isInvalid,
                })}
            />
        </div>
    );
};

export default TextArea;
