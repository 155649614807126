import MultiLanguages from "@admin/components/MultiLanguage";
import Input from "@admin/components/Input";
import TextArea from "@admin/components/TextArea";
import React, { useState } from "react";
import AboutPageGroupCompaniesModal from "./AboutPageGroupCompaniesModal";
import { AboutPageGroupCompaniesBannerModel, AboutPageModel } from "@admin/domain/uniquePages/models/AboutPageModel";
import { v4 as uuid } from "uuid";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import { makePath } from "@admin/utils/makePath";
import { type UniquePageProps } from "../../index";

export default function AboutPageGroupCompanies({ page }: UniquePageProps<AboutPageModel>) {
    const bannersInit = (page.contentObject.group_companies__banners || []).map((banner) => {
        banner.id = uuid();
        return banner;
    });

    const languageContext = useLanguageContext();
    const [showModal, setShowModal] = useState(false);

    const [banners, setBanners] = useState(bannersInit);
    const [editableBanner, setEditableBanner] = useState<AboutPageGroupCompaniesBannerModel | undefined>();

    const addHandle = (data: AboutPageGroupCompaniesBannerModel) => {
        setBanners((prev) => [...prev, data]);
    };

    const editHandle = (data: AboutPageGroupCompaniesBannerModel) => {
        setBanners((prev) =>
            prev.map((value) => {
                if (value.id === data.id) value = data;
                return value;
            }),
        );
    };

    return (
        <div className="mb-10">
            <h4 className="mb-5">Тенториум - это группа предприятий</h4>
            <MultiLanguages
                languageElement={(language) => (
                    <div>
                        <Input
                            label="Заголовок"
                            name={`content[group_companies__title][${language}]`}
                            defaultValue={page.contentObject.group_companies__title[language]}
                        />
                        <TextArea
                            label="Описание (Текст под заголовком)"
                            name={`content[group_companies__sub_title][${language}]`}
                            defaultValue={page.contentObject.group_companies__sub_title[language]}
                        />
                    </div>
                )}
            />
            <div className="row row-cols-auto">
                {banners.map((banner, index) => (
                    <div key={banner.id} className="mb-5" style={{ width: `fit-content`, height: `fit-content` }}>
                        <div className="card border">
                            <img
                                style={{ width: `660px`, height: `475px` }}
                                className="card-img-top img_placeholder img-thumbnail"
                                src={makePath(banner.file.path)}
                                alt=""
                            />
                            <input
                                type="hidden"
                                name={`content[group_companies__banners][${index}][file_id]`}
                                value={banner.file.id}
                            />

                            <div className="card-body">
                                <div className="card-text mb-3">
                                    <div>
                                        <span className="fw-bolder card-text">Заголовок:{` `}</span>
                                        {banner.title[languageContext.currentLanguage]}
                                        {Object.entries(languageContext.availableLanguages).map(([, lang]) => (
                                            <input
                                                key={lang}
                                                type="hidden"
                                                name={`content[group_companies__banners][${index}][title][${lang}]`}
                                                value={banner.title[lang]}
                                            />
                                        ))}
                                    </div>
                                    <div>
                                        <span className="fw-bolder card-text">Подзаголовок:{` `}</span>
                                        {banner.sub_title[languageContext.currentLanguage]}
                                        {Object.entries(languageContext.availableLanguages).map(([, lang]) => (
                                            <input
                                                type="hidden"
                                                key={lang}
                                                name={`content[group_companies__banners][${index}][sub_title][${lang}]`}
                                                value={banner.sub_title[lang]}
                                            />
                                        ))}
                                    </div>
                                    <div>
                                        <a href={banner.url} className="link link-primary">
                                            Ссылка
                                        </a>
                                        <input
                                            type="hidden"
                                            name={`content[group_companies__banners][${index}][url]`}
                                            value={banner.url}
                                        />
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <a
                                        className="card-link text-success"
                                        onClick={() => {
                                            setEditableBanner(banner);
                                            setShowModal(true);
                                        }}
                                    >
                                        Редактировать
                                    </a>
                                    <a
                                        className="card-link text-danger"
                                        onClick={() =>
                                            setBanners((prev) => prev.filter((value) => value.id !== banner.id))
                                        }
                                    >
                                        Удалить
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <i
                className="bi bi-plus-square text-primary fs-2hx cursor-pointer ms-3 align-self-center"
                onClick={() => {
                    setEditableBanner(undefined);
                    setShowModal(true);
                }}
            />
            {showModal && (
                <AboutPageGroupCompaniesModal
                    showModal={showModal}
                    closeModal={() => setShowModal(false)}
                    editableBanner={editableBanner}
                    onSubmit={editableBanner ? editHandle : addHandle}
                />
            )}
        </div>
    );
}
