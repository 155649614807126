import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import type { EditProductModel } from "@admin/domain/products/models/edit/EditProductModel";
import Sortable from "@admin/components/Sortable";
import MultiLanguages from "@admin/components/MultiLanguage";
import type { Language } from "@admin/domain/languages/Languages";
import Input from "@admin/components/Input";
import VideoInput from "../../../components/VideoInput";

type Videos = EditProductModel[`product_videos`];
type Video = Videos[0];

type Props = {
    defaultValue: Videos;
};

export default function ProductVideos({ defaultValue }: Props) {
    const [videos, setVideos] = useState<Videos>(defaultValue);

    const add = () => {
        setVideos([
            ...videos,
            {
                id: uuid(),
                file: null,
                url: null,
                texts: [],
            },
        ]);
    };

    const remove = (video: Video) => {
        setVideos(videos.filter((item) => video.id !== item.id));
    };

    return (
        <div className="rounded border p-10 mb-5">
            <h4 className="mb-5">Видео о товаре</h4>

            <div className="mb-5">
                <Sortable list={videos} setList={setVideos}>
                    {videos.map((video, videoIndex) => (
                        <div key={video.id} className="card border mb-4">
                            <div className="card-body">
                                {typeof video.id === `number` && (
                                    <input
                                        type="hidden"
                                        name={`product_videos[${videoIndex}][id]`}
                                        defaultValue={video.id}
                                    />
                                )}

                                <VideoInput
                                    name={`product_videos[${videoIndex}]`}
                                    defaultValue={{
                                        file: video.file,
                                        url: video.url,
                                    }}
                                />

                                <MultiLanguages
                                    languageElement={(language: Language): React.JSX.Element => (
                                        <>
                                            <input
                                                type="hidden"
                                                name={`product_videos[${videoIndex}][texts][${language}][language]`}
                                                defaultValue={language}
                                            />

                                            <Input
                                                label="Название"
                                                name={`product_videos[${videoIndex}][texts][${language}][title]`}
                                                defaultValue={
                                                    video.texts.find((item) => item.language === language)?.title || ``
                                                }
                                            />
                                        </>
                                    )}
                                />

                                <button
                                    type="button"
                                    className="btn btn-sm btn-link text-danger"
                                    onClick={() => remove(video)}
                                >
                                    Удалить
                                </button>
                            </div>
                        </div>
                    ))}
                </Sortable>
            </div>

            <div className="mt-5">
                <button type="button" className="btn btn-primary" onClick={add}>
                    Добавить видео
                </button>
            </div>
        </div>
    );
}
