import React from "react";

import type { BlockProps } from "@admin/components/Content/ContentBlocks";
import type { ImageBlockValueModel } from "@admin/domain/content/models/ContentBlockValueModel";
import MultiLanguages from "@admin/components/MultiLanguage";
import ImageInput from "@admin/components/ImageInput";

const defaultValue: ImageBlockValueModel = {
    title: {},
    file: null,
};

type Props = BlockProps<ImageBlockValueModel>;

export default function ImageBlock({ index, value = structuredClone(defaultValue) }: Props) {
    return (
        <>
            <MultiLanguages
                languageElement={(language) => (
                    <div className="mb-5">
                        <label className="form-label">Заголовок</label>
                        <input
                            className="form-control"
                            name={`blocks[${index}][value][title][${language}]`}
                            defaultValue={value.title[language] || ``}
                        />
                    </div>
                )}
            />

            <div className="mb-5">
                <ImageInput label="Изображение" name={`blocks[${index}][value][file_id]`} defaultValue={value.file} />
            </div>
        </>
    );
}
