import { EditOrderData } from "@admin/domain/orders/dto/EditOrderData";
import React from "react";

export default function OrderTypeInfo({ order }: { order: EditOrderData }) {
    return (
        <>
            <span className="fw-bolder">Тип заказа: </span>
            {order.is_parent && (
                <>
                    Консолидированный заказ
                    <div>
                        <span className="fw-bolder">Подзаказы:{` `}</span>
                        {order.sub_orders.map((subOrder, index) => (
                            <span key={subOrder.id}>
                                <a href={`/admin/orders/${subOrder.id}`} target="_blank">
                                    {subOrder.id}
                                </a>
                                <span>{index === order.sub_orders.length - 1 ? `` : `, `}</span>
                            </span>
                        ))}
                    </div>
                </>
            )}
            {order.is_child && (
                <>
                    Подзаказ в рамках консолидированного заказа
                    <a href={`/admin/orders/${order.consolidated_order_id}`}>
                        {` `}
                        {order.consolidated_order_id}
                    </a>
                </>
            )}
            {!order.is_parent && !order.is_child && `Обычный заказ`}
            {order.template_id ? `, создан на основании автозаказа #${order.template_id}` : ``}
        </>
    );
}
