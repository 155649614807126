import React, { useMemo } from "react";
import { v4 as uuid } from "uuid";

import type { BusinessPageModel } from "@admin/domain/uniquePages/models/BusinessPageModel";
import type { UniquePageProps } from "../index";
import MultiLanguages from "@admin/components/MultiLanguage";
import ImageInput from "@admin/components/ImageInput";

export default function BusinessPageHowToStart({ page }: UniquePageProps<BusinessPageModel>) {
    const banners: BusinessPageModel[`how_to_start__banners`] = useMemo(
        () =>
            (page.contentObject.how_to_start__banners || []).map((value) => ({
                ...value,
                id: uuid(),
            })),
        [page.contentObject.how_to_start__banners],
    );

    return (
        <div className="mb-10">
            <h4 className="mb-5">Как начать</h4>
            <div>
                <MultiLanguages
                    languageElement={(language) => (
                        <div>
                            <div className="mb-5">
                                <label className="form-label">Заголовок</label>
                                <input
                                    className="form-control"
                                    name={`content[how_to_start__title][${language}]`}
                                    defaultValue={page.contentObject.how_to_start__title?.[language] || ``}
                                />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">Описание</label>
                                <textarea
                                    className="form-control"
                                    name={`content[how_to_start__description][${language}]`}
                                    defaultValue={page.contentObject.how_to_start__description?.[language] || ``}
                                />
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="row row-cols-auto">
                {banners.map((banner, index) => (
                    <div key={banner.id} className="col mb-5">
                        <MultiLanguages
                            languageElement={(language) => (
                                <div>
                                    <div className="mb-5">
                                        <label className="form-label">Заголовок</label>
                                        <input
                                            className="form-control"
                                            name={`content[how_to_start__banners][${index}][title][${language}]`}
                                            defaultValue={banner.title?.[language] || ``}
                                        />
                                    </div>
                                </div>
                            )}
                        />
                        <div key={index} className="col mb-5">
                            <ImageInput
                                label="Изображение"
                                name={`content[how_to_start__banners][${index}][file_id]`}
                                defaultValue={banner.file}
                                width="300px"
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
