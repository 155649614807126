import React, { useState } from "react";
import { IngredientsModel } from "@admin/domain/products/models/edit/ProductSpecificationsModel";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import Sortable from "@admin/components/Sortable";

export default function Ingredients(props: { ingredients: IngredientsModel[] }) {
    const currentLanguage = useLanguageContext().currentLanguage;
    const [ingredients, setIngredients] = useState(props.ingredients);

    return (
        <div className="rounded border p-10 mb-5">
            <h4 className="mb-5">Состав</h4>

            <Sortable list={ingredients} setList={setIngredients}>
                {ingredients.map((ingredient) => (
                    <div key={ingredient.id} className="card border mb-4 position-relative">
                        <div className="card-body">
                            <input type="hidden" name="specifications[ingredients][]" value={ingredient.id} />

                            <div>
                                <span className="fw-bolder">UUID:</span>
                                {` `}
                                {ingredient.accounting_id}
                            </div>
                            <div>
                                <span className="fw-bolder">Название: </span>
                                {` `}
                                {ingredient.textsMap?.get(currentLanguage)?.title}
                            </div>
                        </div>
                    </div>
                ))}
            </Sortable>
        </div>
    );
}
