import React from "react";
import { FileModel } from "@admin/domain/files/model/FileModel";
import ImageInput from "@admin/components/ImageInput";

type Props = {
    label: string;
    name: string;
    icon: FileModel | null;
};

export default function DeliveryMethodIconInput(props: Props) {
    return (
        <div className="mb-4">
            <div className="mb-5">
                <ImageInput
                    thumbnailHeight={45}
                    thumbnailWidth={45}
                    label={props.label}
                    name={`${props.name}_file_id`}
                    defaultValue={props.icon}
                />
            </div>
        </div>
    );
}
