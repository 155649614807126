import React from "react";
import MultiLanguages from "@admin/components/MultiLanguage";
import { type UniquePageProps } from "../index";
import { BusinessPageModel } from "@admin/domain/uniquePages/models/BusinessPageModel";
import VideoInput from "@admin/components/VideoInput";

export default function BusinessPageVideo({ page }: UniquePageProps<BusinessPageModel>) {
    return (
        <div className="mb-10">
            <h4 className="mb-5">Блок с видео</h4>
            <div>
                <MultiLanguages
                    languageElement={(language) => (
                        <div>
                            <div className="mb-5">
                                <label className="form-label">Заголовок</label>
                                <input
                                    className="form-control"
                                    name={`content[video_block__title][${language}]`}
                                    defaultValue={page.contentObject.video_block__title?.[language] || ``}
                                />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">Описание</label>
                                <textarea
                                    className="form-control"
                                    name={`content[video_block__description][${language}]`}
                                    defaultValue={page.contentObject.video_block__description?.[language] || ``}
                                />
                            </div>
                        </div>
                    )}
                />
            </div>

            <VideoInput
                name="content[video_block__video]"
                defaultValue={{
                    file: page.contentObject?.video_block__file,
                    url: page.contentObject.video_block__url,
                }}
            />
        </div>
    );
}
