import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import type { BlockProps } from "@admin/components/Content/ContentBlocks";
import type { PhotoSliderBlockValueModel } from "@admin/domain/content/models/ContentBlockValueModel";
import MultiLanguages from "@admin/components/MultiLanguage";
import ImageInput from "@admin/components/ImageInput";

const defaultValue: PhotoSliderBlockValueModel = {
    title: {},
    images_slides: [],
};

type Props = BlockProps<PhotoSliderBlockValueModel>;

export default function PhotoSliderBlock({ index, value = structuredClone(defaultValue) }: Props) {
    const initialValue = (value.images_slides || []).map((value) => ({
        id: uuid(),
        ...value,
    }));

    const [slides, setSlides] = useState<PhotoSliderBlockValueModel[`images_slides`]>(initialValue);

    return (
        <>
            <MultiLanguages
                languageElement={(language) => (
                    <div className="mb-5">
                        <label className="form-label">Заголовок</label>
                        <input
                            className="form-control"
                            name={`blocks[${index}][value][title][${language}]`}
                            defaultValue={value.title[language] || ``}
                        />
                    </div>
                )}
            />

            <div className="row row-cols-auto">
                {slides.map((slide, slideIndex) => (
                    <div key={slide.id} className="col mb-5">
                        <div key={slideIndex} className="col mb-5">
                            <ImageInput
                                label="Изображение"
                                name={`blocks[${index}][value][images_slides][${slideIndex}][file_id]`}
                                defaultValue={slide.file}
                                width="300px"
                                height={undefined}
                                thumbnailWidth="300px"
                                thumbnailHeight="300px"
                            />
                        </div>

                        <div>
                            <a
                                className="card-link text-danger"
                                onClick={() => {
                                    setSlides((slideState) => slideState.filter((value) => value.id !== slide.id));
                                }}
                            >
                                Удалить
                            </a>
                        </div>
                    </div>
                ))}
            </div>

            <div className="mb-5 me-5 d-flex">
                <button
                    type="button"
                    className="btn btn-success"
                    onClick={() =>
                        setSlides([
                            ...slides,
                            {
                                id: uuid(),
                                file: null,
                            },
                        ])
                    }
                >
                    Добавить слайд
                </button>
            </div>
        </>
    );
}
