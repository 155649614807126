import React, { useState } from "react";
import Input from "@admin/components/Input";
import isNumber from "lodash-es/isNumber";
import { v4 as uuid } from "uuid";
import { DeliveryMethodRegionCondition } from "@admin/domain/deliveryMethods/models/DeliveryMethodRegionModel";

type Condition = Partial<DeliveryMethodRegionCondition>;

export default function Conditions() {
    const { deliveryMethodRegionConfig } = window.CMS;

    const initConditions = (deliveryMethodRegionConfig.conditions || []).map((value) => ({ ...value, id: uuid() }));

    const [deliveryMethodRegionConditions, setDeliveryMethodRegionConditions] = useState<Condition[]>(initConditions);

    return (
        <div className="rounded border p-10 my-5">
            <h3>Условия</h3>
            {deliveryMethodRegionConditions.map((condition, index) => (
                <div key={index} className="rounded border p-10 my-5">
                    <input type="hidden" name={`conditions[${index}][id]`} value={condition?.id} />
                    <Input
                        label="Минимальное значение стоимости корзины"
                        name={`conditions[${index}][min_basket_amount]`}
                        defaultValue={isNumber(condition?.min_basket_amount) ? condition?.min_basket_amount / 100 : ``}
                    />
                    <Input
                        label="Максимальный значение стоимости корзины"
                        name={`conditions[${index}][max_basket_amount]`}
                        defaultValue={isNumber(condition?.max_basket_amount) ? condition?.max_basket_amount / 100 : ``}
                    />
                    <Input
                        label="Значение стоимости доставки"
                        name={`conditions[${index}][delivery_cost]`}
                        defaultValue={isNumber(condition?.delivery_cost) ? condition?.delivery_cost / 100 : ``}
                    />
                    <a
                        className="card-link text-danger"
                        onClick={() => {
                            setDeliveryMethodRegionConditions((prev) =>
                                prev.filter((value) => value.id !== condition.id),
                            );
                        }}
                    >
                        Удалить
                    </a>
                </div>
            ))}
            <div className="d-flex justify-content-end mb-5">
                <button
                    className="btn btn-primary"
                    type="button"
                    onClick={(): void => {
                        setDeliveryMethodRegionConditions((prevState) => [
                            ...prevState,
                            {
                                id: uuid(),
                            },
                        ]);
                    }}
                >
                    Добавить условие
                </button>
            </div>
        </div>
    );
}
