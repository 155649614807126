import { type UniquePageProps } from "../index";
import { PaymentModel } from "@admin/domain/uniquePages/models/PaymentModel";
import PaymentPageWarnings from "./PaymentPageWarnings";
import QuillEditor from "@admin/components/QuillEditor";
import MultiLanguages from "@admin/components/MultiLanguage";

export function PaymentPage({ page }: UniquePageProps<PaymentModel>) {
    return (
        <>
            <h2 className="mb-5">Контент страницы "Оплата"</h2>

            <PaymentPageWarnings initWarnings={page.contentObject.warnings} />

            <div className="mb-10">
                <h4 className="mb-5">Текстовый контент</h4>
                <MultiLanguages
                    languageElement={(language) => (
                        <>
                            <QuillEditor
                                label="Описание порядка действий"
                                name={`content[procedure_description][${language}]`}
                                initHtml={page.contentObject.procedure_description[language]}
                            />

                            <QuillEditor
                                label="Описание процесса передачи данных"
                                name={`content[data_transfer_process][${language}]`}
                                initHtml={page.contentObject.data_transfer_process[language]}
                            />
                        </>
                    )}
                />
            </div>
        </>
    );
}
