import React from "react";
import { useOrderContext } from "@admin/pages/OrderEditor/OrderContext";
import Input from "@admin/components/Input";
import { useLoaderContext } from "@admin/components/LoaderContext";
import { storeLog as storeLogRequest } from "@admin/domain/orders/api";
import { useOrderHooks } from "@admin/pages/OrderEditor/hooks";
import { prettyDate } from "@admin/utils/dates";
import { Collapse } from "react-bootstrap";
import JsonView from "@admin/pages/OrderEditor/JsonView";

export default function Logs() {
    const { order } = useOrderContext();
    const [log, setLog] = React.useState<string>(``);
    const [collapseState, setCollapseState] = React.useState<Map<number, boolean>>(new Map());
    const { isLoading } = useLoaderContext();
    const { orderRequest } = useOrderHooks();

    const addLog = async (): Promise<void> => {
        orderRequest(async (): Promise<null> => await storeLogRequest(order.id, log));
    };

    const toggleCollapse = (logId: number): void => {
        setCollapseState(new Map([...collapseState, [logId, !collapseState.get(logId)]]));
    };

    return (
        <div className="rounded border p-10 my-5">
            <h4 className="mb-5">Примечания</h4>

            <div className="py-5">
                <table className="table table-row-bordered gy-7">
                    <thead>
                        <tr className="fw-bolder fs-6 text-gray-800">
                            <th style={{ width: `150px` }}>Дата</th>
                            <th style={{ width: `250px` }}>Инициатор</th>
                            <th>Событие</th>
                        </tr>
                    </thead>
                    <tbody>
                        {order.logs.map(({ id, created_at, message, context, initiator_name }, index: number) => (
                            <tr key={`${index}-${created_at}`}>
                                <td>{prettyDate(created_at)}</td>
                                <td>{initiator_name}</td>
                                <td>
                                    <div className="mb-2">{message}</div>
                                    <div className="border rounded p-5">
                                        <div
                                            className={`cursor-pointer ${collapseState.get(id) ? `mb-5` : ``}`}
                                            onClick={(): void => toggleCollapse(id)}
                                        >
                                            Показать данные
                                        </div>

                                        <Collapse in={collapseState.get(id)}>
                                            <div>
                                                <JsonView data={context} />
                                            </div>
                                        </Collapse>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="d-flex align-items-end flex-column">
                <Input
                    label="Примечание"
                    className={{ block: `col-12 mb-5` }}
                    value={log}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setLog(e.target.value)}
                    disabled={isLoading}
                />
                <div className="col-4 mb-5 d-flex justify-content-end">
                    <button className="btn btn-info" disabled={isLoading} onClick={addLog}>
                        Добавить примечание
                    </button>
                </div>
            </div>
        </div>
    );
}
