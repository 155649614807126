import React from "react";
import Input from "@admin/components/Input";
import { EditOrderData } from "@admin/domain/orders/dto/EditOrderData";
import formatMoney from "@admin/domain/orders/formatMoney";
import { useLoaderContext } from "@admin/components/LoaderContext";
import { useOrderHooks } from "@admin/pages/OrderEditor/hooks";
import { registerInAccounting, registerPaymentInAccounting } from "@admin/domain/orders/api";
import RegisterCustomPaymentInAccountingModal from "./RegisterCustomPaymentInAccountingModal";

type Props = {
    order: EditOrderData;
};

export default function Accounting({ order }: Props) {
    const { isLoading } = useLoaderContext();
    const { orderRequest } = useOrderHooks();
    const [modalIsOpened, setModalIsOpened] = React.useState<boolean>(false);

    const register = (): void => {
        orderRequest(async (): Promise<null> => await registerInAccounting(order.id));
    };

    const registerPayment = (): void => {
        orderRequest(async (): Promise<null> => await registerPaymentInAccounting(order.id));
    };

    return (
        <>
            <h4 className="mb-5">Синхронизация с 1С</h4>
            <Input value={order.id_from_1c} label="GUID заказа" className={{ block: `col-4 mb-5` }} disabled />
            <Input value="" label="GUID платежа по заказу" className={{ block: `col-4 mb-5` }} disabled />
            <Input
                value={
                    order.logs.find((log) => log.action === `order_payment_was_registered`)?.context?.[`1c_data`]
                        ?.pay_id
                }
                label="ID платежа"
                className={{ block: `col-4 mb-5` }}
                disabled
            />
            <Input
                value={order.is_parent || !order.id_from_1c ? `` : formatMoney(order, `total_amount`)}
                label="Сумма платежа"
                className={{ block: `col-4 mb-5` }}
                disabled
            />
            <Input value={order.paid_at} label="Дата платежа" className={{ block: `col-4 mb-5` }} disabled />

            <button type="button" className="btn btn-info mb-5 me-2" onClick={register} disabled={isLoading}>
                Отправить заказ
            </button>

            <button type="button" className="btn btn-info mb-5 me-2" onClick={registerPayment} disabled={isLoading}>
                Отправить платеж
            </button>

            {!order.payment_external_id && (
                <button
                    type="button"
                    className="btn btn-info mb-5 me-2"
                    onClick={(): void => setModalIsOpened(true)}
                    disabled={isLoading}
                >
                    Ручное формирование платежа
                </button>
            )}

            {modalIsOpened && (
                <RegisterCustomPaymentInAccountingModal
                    orderId={order.id}
                    closeModal={(): void => setModalIsOpened(false)}
                />
            )}
        </>
    );
}
