import React from "react";
import BaseModal from "@admin/components/Modal";
import Input from "@admin/components/Input";
import { EditOrderData } from "@admin/domain/orders/dto/EditOrderData";
import { applyOrderDiscounts } from "@admin/domain/orders/api";
import { useLoaderContext } from "@admin/components/LoaderContext";
import { useOrderHooks } from "@admin/pages/OrderEditor/hooks";

type Props = {
    closeModal: () => void;
    order: EditOrderData;
};

export default function ApplyDiscountsModal({ closeModal, order }: Props) {
    const { actualizeOrder } = useOrderHooks();
    const { request, isLoading } = useLoaderContext();
    const [creatorDiscount, setCreatorDiscount] = React.useState<number>(order.creator_discount);
    const [targetDiscount, setTargetDiscount] = React.useState<number>(order.target_discount);

    const apply = (): void => {
        request(async (): Promise<void> => {
            await applyOrderDiscounts(order.id, creatorDiscount, targetDiscount);
            await actualizeOrder();
            closeModal();
        });
    };

    return (
        <BaseModal
            size="sm"
            closeModal={closeModal}
            title="Изменить скидку"
            body={
                <>
                    <Input
                        label={`Скидка ${order.is_child ? `консолидатора` : `оформителя`} (в р.)`}
                        type="number"
                        min={0}
                        defaultValue={order.creator_discount / 100}
                        onChange={(e): void => setCreatorDiscount(+e.target.value * 100)}
                        disabled={isLoading}
                    />
                    {order.target.id !== order.creator.id && (
                        <Input
                            label="Скидка получателя (в р.)"
                            type="number"
                            min={0}
                            defaultValue={order.target_discount / 100}
                            onChange={(e): void => setTargetDiscount(+e.target.value * 100)}
                            disabled={isLoading}
                        />
                    )}
                </>
            }
            footer={
                <>
                    <button className="btn btn-secondary" onClick={closeModal} disabled={isLoading}>
                        Закрыть
                    </button>
                    <button className="btn btn-primary" onClick={apply} disabled={isLoading}>
                        Применить
                    </button>
                </>
            }
        />
    );
}
