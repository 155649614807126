import { CountryModel } from "@admin/domain/countries/models/CountryModel";

export const getDefaultCountry = (countries: Map<number, CountryModel>): CountryModel => {
    let defaultCountry = null;

    countries.forEach((country: CountryModel): void => {
        if (country.is_default) {
            defaultCountry = country;
        }
    });

    return defaultCountry || countries.values().next().value;
};
