import React, { useReducer, useRef } from "react";
import Select, { SelectInstance } from "react-select";

import type { ProductCategoryModel } from "@admin/domain/productCategories/models/ProductCategoryModel";
import type { ActiveComponentModel as Base } from "@admin/domain/products/models/edit/ProductSpecificationsModel";
import convertToMapLanguageObject from "@admin/domain/languages/Languages";
import { useLanguageContext } from "@admin/components/LanguagesContext";

type ActiveComponentModel = Base & { title: string };

type ActiveComponentsReducerProps = {
    action: `delete` | `add`;
    id: ActiveComponentModel[`id`];
};

type Option = {
    value: ActiveComponentModel[`id`];
    label: ActiveComponentModel[`title`];
};

type Props = {
    productCategory: ProductCategoryModel | null;
};

function reducer(
    selected: Array<ActiveComponentModel[`id`]>,
    { action, id }: ActiveComponentsReducerProps,
): Array<ActiveComponentModel[`id`]> {
    switch (action) {
        case `add`:
            return [...selected, id];

        case `delete`:
            return selected.filter((item) => item !== id);

        default:
            throw Error(`Unknown action: ` + action);
    }
}

export default function ActiveComponents({ productCategory }: Props) {
    const currentLanguage = useLanguageContext().currentLanguage;

    const activeComponents = (window.CMS.activeComponents || []).map((value): ActiveComponentModel => {
        const textsMap = convertToMapLanguageObject(value.texts);

        return {
            ...value,
            textsMap,
            title: textsMap.get(currentLanguage)?.title || `${value.id}`,
        };
    });

    const [selected, dispatch] = useReducer(reducer, productCategory?.active_components || []);

    const selectRef = useRef<SelectInstance<Option> | null>(null);

    return (
        <div className="mb-5">
            <label className="form-label" htmlFor="input_active_component">
                Активные компоненты
            </label>

            <div className="mb-5">
                <Select
                    ref={selectRef}
                    placeholder="Выберите"
                    options={activeComponents
                        .filter((item) => !selected.includes(item.id))
                        .map(
                            (item): Option => ({
                                value: item.id,
                                label: `${item.title || item.id}`,
                            }),
                        )
                        .sort((a, b) => {
                            return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
                        })}
                    onChange={(item, { action }) => {
                        if (action === `select-option` && item?.value) {
                            dispatch({ action: `add`, id: item.value });
                        }
                    }}
                />
            </div>

            {selected
                .map((id): ActiveComponentModel | undefined => activeComponents.find((item) => item.id === id))
                .map(
                    (activeComponent: ActiveComponentModel | undefined) =>
                        activeComponent && (
                            <div key={activeComponent.id} className="card border mb-5 position-relative">
                                <input type="hidden" name="active_components[]" value={activeComponent.id} />

                                <div className="card-body">
                                    <span className="fw-bolder">{activeComponent.title}</span>
                                </div>

                                <div className="position-absolute top-0 end-0">
                                    <button
                                        type="button"
                                        title="Удалить"
                                        className="btn btn-icon btn-icon-danger"
                                        onClick={() => {
                                            dispatch({
                                                action: `delete`,
                                                id: activeComponent.id,
                                            });
                                        }}
                                    >
                                        <i className="fa fa-trash" />
                                    </button>
                                </div>
                            </div>
                        ),
                )}
        </div>
    );
}
