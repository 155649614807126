import React from "react";
import { AccessObjectModel } from "@admin/domain/access/models/AccessObjectModel";
import { AccessAction, AccessPermissionModel } from "@admin/domain/access/models/AccessPermissionModel";
import { useAccessPermissionsContext } from "@admin/pages/AccessPermissionsEditor/AccessPermissionsContext";
import { useLoaderContext } from "@admin/components/LoaderContext";
import { storeAccessPermission, updateAccessPermission } from "@admin/domain/access/api";
import { InputChangeEvent } from "@admin/utils/types";

type Props = {
    accessObject: AccessObjectModel;
    action: AccessAction;
};

export default function AccessCheckBox({ accessObject, action }: Props) {
    const { selectedAccessGroup } = useAccessPermissionsContext();
    const { accessPermissions, setAccessPermissions } = useAccessPermissionsContext();
    const { request, isLoading } = useLoaderContext();

    if (!selectedAccessGroup) {
        return null;
    }

    const currentAccessPermission = accessPermissions.find(
        (permission: AccessPermissionModel): boolean =>
            permission.access_group_id === selectedAccessGroup?.id &&
            permission.access_object_id === accessObject.id &&
            permission.action === action,
    );

    const changePermission = (e: InputChangeEvent): void => {
        const isEnabled = e.target.checked;

        request(async (): Promise<void> => {
            if (currentAccessPermission) {
                await updateAccessPermission(currentAccessPermission.id, isEnabled);

                setAccessPermissions(
                    accessPermissions.map((permission: AccessPermissionModel): AccessPermissionModel => {
                        if (permission.id === currentAccessPermission.id) {
                            return {
                                ...currentAccessPermission,
                                is_enabled: isEnabled,
                            };
                        }

                        return permission;
                    }),
                );
            } else {
                const newAccessPermission = await storeAccessPermission({
                    access_group_id: selectedAccessGroup.id,
                    access_object_id: accessObject.id,
                    action: action,
                    is_enabled: isEnabled,
                });

                setAccessPermissions([...accessPermissions, newAccessPermission]);
            }
        });
    };

    return (
        <div className="mb-5">
            <div className="form-check form-check-custom form-check-solid">
                <input
                    className="form-check-input"
                    type="checkbox"
                    defaultChecked={currentAccessPermission?.is_enabled}
                    disabled={isLoading}
                    onChange={changePermission}
                />
            </div>
        </div>
    );
}
