import React, { FormEvent } from "react";
import BaseModal from "@admin/components/Modal";
import Input from "@admin/components/Input";
import { useOrderHooks } from "@admin/pages/OrderEditor/hooks";
import { storeCustomOrderReceipt } from "@admin/domain/orders/api";
import { useOrderContext } from "@admin/pages/OrderEditor/OrderContext";

type Props = {
    closeModal: () => void;
};

export default function StoreCustomReceiptModal({ closeModal }: Props) {
    const { order } = useOrderContext();
    const { orderRequest } = useOrderHooks();

    const store = async (event: FormEvent): Promise<void> => {
        event.preventDefault();

        orderRequest(async (): Promise<void> => {
            await storeCustomOrderReceipt(order.id, new FormData(event.target as HTMLFormElement));
            closeModal();
        });
    };

    return (
        <BaseModal
            closeModal={closeModal}
            title="Ручное формирование платежа"
            body={
                <form onSubmit={store} id="custom_receipt_form">
                    <Input label="Сумма заказа" name="amount" />
                </form>
            }
            footer={
                <>
                    <button type="button" className="btn btn-secondary" onClick={closeModal}>
                        Закрыть
                    </button>
                    <button type="submit" className="btn btn-primary" form="custom_receipt_form">
                        Подтвердить
                    </button>
                </>
            }
        />
    );
}
