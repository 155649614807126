import MultiLanguages from "@admin/components/MultiLanguage";
import Input from "@admin/components/Input";
import TextArea from "@admin/components/TextArea";
import React, { useState } from "react";
import { makePath } from "@admin/utils/makePath";
import AboutPageAwardsModal from "./AboutPageAwardsModal";
import { AboutPageAwardBannerModel, AboutPageModel } from "@admin/domain/uniquePages/models/AboutPageModel";
import { v4 as uuid } from "uuid";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import { type UniquePageProps } from "../../index";
import Sortable from "@admin/components/Sortable";

export default function AboutPageAwards({ page }: UniquePageProps<AboutPageModel>) {
    const awardsInit = (page.contentObject.awards__banners || []).map((value) => {
        return { ...value, id: uuid() };
    });

    const languageContext = useLanguageContext();

    const [showModal, setShowModal] = useState(false);
    const [awards, setAwards] = useState(awardsInit);
    const [editableAward, setEditableAward] = useState<AboutPageAwardBannerModel | undefined>();

    const addHandle = (data: AboutPageAwardBannerModel) => {
        setAwards((prev) => [...prev, data]);
    };

    const editHandle = (data: AboutPageAwardBannerModel) => {
        setAwards((prev) => {
            const index = prev.findIndex((value) => value.id === data.id);
            prev[index] = data;
            return prev;
        });
    };

    return (
        <div className="mb-10">
            <h4 className="mb-5">Блок с информацией о наградах</h4>
            <MultiLanguages
                languageElement={(language) => (
                    <div>
                        <Input
                            label="Заголовок"
                            name={`content[awards__title][${language}]`}
                            defaultValue={page.contentObject.awards__title[language]}
                        />
                        <TextArea
                            label="Подзаголовок"
                            name={`content[awards__sub_title][${language}]`}
                            defaultValue={page.contentObject.awards__sub_title[language]}
                        />
                    </div>
                )}
            />
            <Sortable list={awards} setList={setAwards} className="row row-cols-auto">
                {awards.map((award, index) => (
                    <div key={award.id} className="mb-5" style={{ width: `fit-content`, height: `fit-content` }}>
                        <div className="card border">
                            <img
                                style={{ width: `660px`, height: `375px` }}
                                className="card-img-top img_placeholder img-thumbnail"
                                src={makePath(award.file.path)}
                            />
                            <input
                                type="hidden"
                                name={`content[awards__banners][${index}][file_id]`}
                                value={award.file.id}
                            />
                            <div className="card-body">
                                <div className="card-text mb-3">
                                    <div>
                                        <span className="fw-bolder">Заголовок:{` `}</span>
                                        {` `}
                                        {award.title[languageContext.currentLanguage]}
                                        {Object.entries(languageContext.availableLanguages).map(([, lang]) => (
                                            <input
                                                type="hidden"
                                                key={lang}
                                                name={`content[awards__banners][${index}][title][${lang}]`}
                                                value={award.title[lang]}
                                            />
                                        ))}
                                    </div>
                                    <div>
                                        <span className="fw-bolder">Подзаголовок:{` `}</span>
                                        {` `}
                                        {award.sub_title[languageContext.currentLanguage]}
                                        {Object.entries(languageContext.availableLanguages).map(([, lang]) => (
                                            <input
                                                type="hidden"
                                                key={lang}
                                                name={`content[awards__banners][${index}][sub_title][${lang}]`}
                                                value={award.sub_title[lang]}
                                            />
                                        ))}
                                    </div>
                                </div>
                                <div>
                                    <a
                                        className="card-link text-success"
                                        onClick={() => {
                                            setEditableAward(award);
                                            setShowModal(true);
                                        }}
                                    >
                                        Редактировать
                                    </a>
                                    <a
                                        className="card-link text-danger"
                                        onClick={() => {
                                            setAwards((prev) => prev.filter((value) => value.id !== award.id));
                                        }}
                                    >
                                        Удалить
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Sortable>
            {awards.length < 10 && (
                <i
                    className="bi bi-plus-square text-primary fs-2hx cursor-pointer ms-3 align-self-center"
                    onClick={() => {
                        setEditableAward(undefined);
                        setShowModal(true);
                    }}
                />
            )}
            {showModal && (
                <AboutPageAwardsModal
                    showModal={showModal}
                    closeModal={() => setShowModal(false)}
                    onSubmit={editableAward ? editHandle : addHandle}
                    editableAward={editableAward}
                />
            )}
        </div>
    );
}
