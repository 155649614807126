import React from "react";
import { Collapse } from "react-bootstrap";
import JsonView from "@admin/pages/OrderEditor/JsonView";

type Props = {
    data: Record<string, any>;
};

export default function ReceiptData({ data }: Props) {
    const [receiptDataIsCollapsed, setReceiptDataIsCollapsed] = React.useState<boolean>(false);

    return (
        <div className="col-9 border rounded p-5 mb-3">
            <div className="cursor-pointer" onClick={(): void => setReceiptDataIsCollapsed(!receiptDataIsCollapsed)}>
                {receiptDataIsCollapsed ? `Скрыть чек` : `Показать чек`}
            </div>
            <Collapse in={receiptDataIsCollapsed}>
                <div className="mb-5">
                    <JsonView data={data} />
                </div>
            </Collapse>
        </div>
    );
}
