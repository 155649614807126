import { type UniquePageProps } from "../index";
import { HowCreateOrderModel } from "@admin/domain/uniquePages/models/HowCreateOrderModel";
import HowCreateOrderActionBlocks from "./HowCreateOrderActionBlocks";
import React from "react";

export default function HowCreateOrderPage({ page }: UniquePageProps<HowCreateOrderModel>) {
    return (
        <div>
            <h2 className="mb-5">Контент страницы "Как оформить заказ в интернет-магазине Tentorium.ru"</h2>
            <HowCreateOrderActionBlocks initActionBLocks={page.contentObject.action_blocks} />
        </div>
    );
}
