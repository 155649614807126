import React from "react";
import Phones from "./Phones";
import Input from "@admin/components/Input";
import { DeliveryPointModel } from "@admin/domain/deliveryPoints/models/DeliveryPointModel";
import CheckBox from "@admin/components/CheckBox";

type Props = {
    deliveryPoint: DeliveryPointModel | null;
};

const Contacts: React.FC<Props> = ({ deliveryPoint }) => {
    return (
        <>
            <h2>Контакты</h2>

            <Input label="Email" name="email" defaultValue={deliveryPoint?.email || ``} />

            <Phones />

            <CheckBox
                label="Скрыть номера телефонов"
                defaultChecked={deliveryPoint?.phones_is_hidden}
                name="phones_is_hidden"
            />
        </>
    );
};

export default Contacts;
