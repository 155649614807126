import React from "react";
import MultiLanguages from "@admin/components/MultiLanguage";
import { type UniquePageProps } from "../index";
import { BusinessPageModel } from "@admin/domain/uniquePages/models/BusinessPageModel";

export default function BusinessPageMain({ page }: UniquePageProps<BusinessPageModel>) {
    return (
        <div className="mb-10">
            <h4 className="mb-5">Основной блок с баннером</h4>
            <div>
                <MultiLanguages
                    languageElement={(language) => (
                        <div>
                            <div className="mb-5">
                                <label className="form-label">Заголовок</label>
                                <input
                                    className="form-control"
                                    name={`content[main_block__title][${language}]`}
                                    defaultValue={page.contentObject.main_block__title?.[language] || ``}
                                />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">Описание</label>
                                <textarea
                                    className="form-control"
                                    name={`content[main_block__description][${language}]`}
                                    defaultValue={page.contentObject.main_block__description?.[language] || ``}
                                />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">Текст кнопки</label>
                                <textarea
                                    className="form-control"
                                    name={`content[main_block__button_text][${language}]`}
                                    defaultValue={page.contentObject.main_block__button_text?.[language] || ``}
                                />
                            </div>
                        </div>
                    )}
                />
            </div>
        </div>
    );
}
