import { post } from "../api";
import { NotificationModel } from "@admin/domain/notifications/models/NotificationModel";

const createNotification = async (body: FormData): Promise<NotificationModel> => {
    return await post({
        url: `/admin/notifications`,
        body,
    });
};

const updateNotification = async (id: NotificationModel[`id`], body: FormData): Promise<NotificationModel> => {
    return await post({
        url: `/admin/notifications/${id}`,
        body,
    });
};

export const saveNotification = async (
    notification: NotificationModel | null,
    body: FormData,
): Promise<NotificationModel> => {
    return notification ? updateNotification(notification.id, body) : createNotification(body);
};
