import React, { useState } from "react";

import type ComplexPromotionModel from "@admin/domain/complexPromotions/models/ComplexPromotionModel";
import { AmountCondition } from "@admin/domain/complexPromotions/models/Conditions";
import Select from "@admin/components/Select";
import Input from "@admin/components/Input";
import Rewards from "./Rewards";

type Levels = ComplexPromotionModel[`levels`];
type Level = Levels[0];

type Props = {
    promotion: ComplexPromotionModel | null;
};

const ContentPromotion: React.FC<Props> = ({ promotion }) => {
    const [levels, setLevels] = useState<Levels>(promotion?.levels || []);

    const addLevel = (): void =>
        setLevels([
            ...levels,
            {
                amount_requirement: 0,
                content_requirements: [],
                rewards: [],
            },
        ]);

    const deleteLevel = (levelIndex: number): void => {
        setLevels(levels.filter((_level: Level, index: number): boolean => levelIndex !== index));
    };

    return (
        <>
            <Select
                label="Тип суммы"
                name="condition"
                defaultValue={promotion?.condition || AmountCondition.Points}
                options={[
                    {
                        value: AmountCondition.Money,
                        label: `Цена товара (в р.)`,
                    },
                    { value: AmountCondition.Points, label: `Баллы` },
                ]}
                prependEmptyOption={false}
                required
            />

            <h2>Уровни акции</h2>

            {levels.map((level, levelIndex: number) => (
                <div key={level.id || `new-level-${levelIndex}`} className="rounded border p-10 my-5">
                    <input type="hidden" name={`levels[${levelIndex}][id]`} value={level.id} />

                    <Input
                        label="Сумма"
                        defaultValue={level.amount_requirement ? level.amount_requirement / 100 : undefined}
                        name={`levels[${levelIndex}][amount_requirement]`}
                    />

                    <Rewards levelIndex={levelIndex} level={level} />

                    {levels.length > 1 && (
                        <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => {
                                if (confirm(`Удалить уровень?`)) {
                                    deleteLevel(levelIndex);
                                }
                            }}
                        >
                            Удалить уровень
                        </button>
                    )}
                </div>
            ))}

            <div className="rounded border p-10 my-5 cursor-pointer" onClick={addLevel}>
                <i className="fas fa-plus me-2" />
                Добавить уровень
            </div>
        </>
    );
};

export default ContentPromotion;
