import React from "react";
import { v4 as uuid } from "uuid";

import { useIsInvalid } from "../FormErrorsContext";

export type Props = {
    label: string;
} & Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, `defaultValue`>;

const CheckBox: React.FC<Props> = ({ label, name, value = 1, ...props }) => {
    const isInvalid = useIsInvalid(name);

    const id = `${name}_${value}_${uuid()}`;

    return (
        <div className="mb-5">
            <div className={`form-check form-check-custom form-check-solid ${isInvalid ? `text-danger` : ``}`}>
                <input type="hidden" name={name} value={value === 1 || value === `1` ? `0` : ``} />
                <input className="form-check-input" {...props} type="checkbox" name={name} value={value} id={id} />
                <label className="form-check-label" htmlFor={id}>
                    {label}
                </label>
            </div>
        </div>
    );
};

export default CheckBox;
