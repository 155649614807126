import React, { useState } from "react";

import type { ArticleContentBlockModel } from "@admin/domain/articles/models/ArticleContentBlockModel";
import Sortable from "@admin/components/Sortable";
import AddBlockModal from "./AddBlockModal";
import Block from "./Block";

export type ArticleBlocksProps = {
    blocks: ArticleContentBlockModel[];
};

export default function ArticleBlocks(props: ArticleBlocksProps) {
    const [blocks, setBlocks] = useState<ArticleContentBlockModel[]>(props.blocks);

    const [showModal, setShowModal] = useState(false);

    const addBlock = (newBlock: ArticleContentBlockModel): void => {
        setBlocks([...blocks, newBlock]);
        setShowModal(false);
    };

    const deleteBlock = (id: number) => {
        setBlocks(blocks.filter((item) => item.id !== id));
    };

    return (
        <>
            <Sortable list={blocks} setList={setBlocks}>
                {blocks.map((block, index) => (
                    <Block key={block.id} block={block} index={index} onDelete={deleteBlock} />
                ))}
            </Sortable>

            <button className="btn btn-success" type="button" onClick={(): void => setShowModal(true)}>
                Добавить блок
            </button>

            <AddBlockModal show={showModal} close={(): void => setShowModal(false)} onSubmit={addBlock} />
        </>
    );
}
