import React from "react";
import { AccessGroupModel } from "@admin/domain/access/models/AccessGroupModel";
import { useAccessPermissionsContext } from "@admin/pages/AccessPermissionsEditor/AccessPermissionsContext";
import { AccessScope } from "@admin/domain/access/enums";

type AccessGroupsByType = Map<string, AccessGroupModel[]>;

const accessGroupTypes = new Map([
    [AccessScope.Public, `Дистрибьюторские`],
    [AccessScope.Cms, `Администраторские`],
]);

export default function AccessGroupsSelect() {
    const { accessGroups } = useAccessPermissionsContext();
    const { setSelectedAccessGroup, setSelectedAccessObject } = useAccessPermissionsContext();

    const accessGroupsByType = accessGroups.reduce(
        (result: AccessGroupsByType, accessGroup: AccessGroupModel): AccessGroupsByType => {
            result.set(accessGroup.scope, [...(result.get(accessGroup.scope) || []), accessGroup]);

            return result;
        },
        new Map(),
    );

    const setAccessGroup = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const id = +e.target.value || null;

        setSelectedAccessGroup(
            accessGroups.find(
                (accessGroup: AccessGroupModel): boolean => accessGroup.id === id,
            ) as AccessGroupModel | null,
        );

        setSelectedAccessObject(null);
    };

    return (
        <div className="col-6">
            <label className="form-label">Субъекты доступа</label>
            <select className="form-select" style={{ height: `45px` }} onChange={setAccessGroup}>
                <option></option>
                {Array.from(accessGroupsByType, ([scope, accessGroups]) => {
                    return (
                        <optgroup label={accessGroupTypes.get(scope as AccessScope)} key={scope}>
                            {accessGroups.map((accessGroup: AccessGroupModel) => (
                                <option key={accessGroup.id} value={accessGroup.id}>
                                    {accessGroup.title}
                                </option>
                            ))}
                        </optgroup>
                    );
                })}
            </select>
        </div>
    );
}
