import React from "react";
import { MainBlockBannerModel } from "@admin/domain/uniquePages/models/MainPageModel";

type Props = {
    banner: MainBlockBannerModel;
    index: number;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    setEditableBanner: React.Dispatch<React.SetStateAction<MainBlockBannerModel | undefined>>;
    setBanners: React.Dispatch<React.SetStateAction<MainBlockBannerModel[]>>;
};

export default function BannerButtons({ setEditableBanner, setShowModal, setBanners, banner }: Props) {
    return (
        <>
            <div>
                <a
                    className="card-link text-success"
                    onClick={() => {
                        setEditableBanner(banner);
                        setShowModal(true);
                    }}
                >
                    Редактировать
                </a>
                <a
                    className="card-link text-danger"
                    onClick={() => setBanners((prev) => prev.filter((value) => value.id !== banner.id))}
                >
                    Удалить
                </a>
            </div>
        </>
    );
}
