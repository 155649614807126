import React, { useState } from "react";
import MultiLanguages from "@admin/components/MultiLanguage";
import { BusinessPageModel, FaqBlocksModel } from "@admin/domain/uniquePages/models/BusinessPageModel";
import { type UniquePageProps } from "../index";
import { v4 as uuid } from "uuid";

type Banner = Partial<FaqBlocksModel>;

export default function BusinessPageFaq({ page }: UniquePageProps<BusinessPageModel>) {
    const initBanners = (page.contentObject.faq__blocks || []).map((value) => {
        return { ...value, id: uuid() };
    });

    const [banners, setBanners] = useState<Banner[]>(initBanners);

    return (
        <div className="mb-10">
            <h4 className="mb-5">Часто задаваемые вопросы</h4>
            <div>
                <MultiLanguages
                    languageElement={(language) => (
                        <div>
                            <div className="mb-5">
                                <label className="form-label">Заголовок</label>
                                <input
                                    className="form-control"
                                    name={`content[faq__title][${language}]`}
                                    defaultValue={page.contentObject.faq__title?.[language] || ``}
                                />
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="row row-cols-auto">
                {banners.map((banner, index) => (
                    <div key={banner.id} className="col mb-7">
                        <MultiLanguages
                            languageElement={(language) => (
                                <div>
                                    <div className="mb-7">
                                        <label className="form-label">Вопрос</label>
                                        <textarea
                                            className="form-control"
                                            rows={5}
                                            cols={50}
                                            name={`content[faq__blocks][${index}][question][${language}]`}
                                            defaultValue={banner.question?.[language] || ``}
                                        />
                                    </div>
                                    <div className="mb-7">
                                        <label className="form-label">Ответ</label>
                                        <textarea
                                            className="form-control"
                                            rows={5}
                                            cols={50}
                                            name={`content[faq__blocks][${index}][answer][${language}]`}
                                            defaultValue={banner.answer?.[language] || ``}
                                        />
                                    </div>
                                </div>
                            )}
                        />
                        <div>
                            <a
                                className="card-link text-danger"
                                onClick={() => {
                                    setBanners((prev) => prev.filter((value) => value.id !== banner.id));
                                }}
                            >
                                Удалить
                            </a>
                        </div>
                    </div>
                ))}

                <div className="mb-5 d-flex">
                    <div className="me-5">
                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={() =>
                                setBanners((prevState) => [
                                    ...prevState,
                                    {
                                        question: undefined,
                                        answer: undefined,
                                    },
                                ])
                            }
                        >
                            Добавить
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
