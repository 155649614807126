import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import type { LeadersBlockValueModel } from "@admin/domain/content/models/ContentBlockValueModel";
import type { BlockProps } from "@admin/components/Content/ContentBlocks";
import MultiLanguages from "@admin/components/MultiLanguage";
import VideoInput from "@admin/components/VideoInput";

const defaultValue: LeadersBlockValueModel = {
    title: {},
    leader_banners: [],
};

type Props = BlockProps<LeadersBlockValueModel>;

export default function LeadersBlock({ index, value = structuredClone(defaultValue) }: Props) {
    const initialValue = (value.leader_banners || []).map((item) => ({
        id: uuid(),
        ...item,
    }));

    const [banners, setBanners] = useState<LeadersBlockValueModel[`leader_banners`]>(initialValue);

    return (
        <>
            <MultiLanguages
                languageElement={(language) => (
                    <div className="mb-5">
                        <label className="form-label">Заголовок</label>
                        <input
                            className="form-control"
                            name={`blocks[${index}][value][title][${language}]`}
                            defaultValue={value.title[language] || ``}
                        />
                    </div>
                )}
            />

            {banners.map((banner, bannerIndex) => (
                <div key={banner.id} className="rounded border p-4 my-8 mb-5">
                    <MultiLanguages
                        languageElement={(language) => (
                            <div className="mb-5">
                                <label className="form-label">Информация</label>
                                <input
                                    className="form-control"
                                    name={`blocks[${index}][value][leader_banners][${bannerIndex}][info][${language}]`}
                                    defaultValue={banner.info?.[language] || ``}
                                />
                            </div>
                        )}
                    />

                    <div className="mb-5">
                        <label className="form-label">Имя</label>
                        <input
                            className="form-control"
                            name={`blocks[${index}][value][leader_banners][${bannerIndex}][full_name]`}
                            defaultValue={banner.full_name}
                        />
                    </div>

                    <VideoInput
                        name={`blocks[${index}][value][leader_banners][${bannerIndex}]`}
                        defaultValue={{ file: banner.file }}
                        allowLink={false}
                    />
                </div>
            ))}

            <div className="mb-5 d-flex">
                <div className="me-5">
                    <button
                        type="button"
                        className="btn btn-success"
                        onClick={() =>
                            setBanners([
                                ...banners,
                                {
                                    id: uuid(),
                                    file: null,
                                    video_file: null,
                                    preview_file: null,
                                    add_preview_file: null,
                                    info: {},
                                    full_name: ``,
                                },
                            ])
                        }
                    >
                        Добавить лидера
                    </button>
                </div>
            </div>
        </>
    );
}
