import React, { useEffect } from "react";
import { useLoaderContext } from "@admin/components/LoaderContext";
import { toDictionary } from "@admin/utils/converters";
import { searchDeliveryPoints } from "@admin/domain/deliveryPoints/api";
import { DeliveryPointModel } from "@admin/domain/deliveryPoints/models/DeliveryPointModel";
import Select from "@admin/components/Select";

type Props = {
    localityId: number | null;
    deliveryMethodId: number;
    deliveryPointId: number | null;
    setDeliveryPoint: (point: DeliveryPointModel) => void;
};

export default function DeliveryPoints({ localityId, deliveryMethodId, deliveryPointId, setDeliveryPoint }: Props) {
    const { request } = useLoaderContext();

    const [deliveryPoints, setDeliveryPoints] = React.useState<Map<number, DeliveryPointModel>>(new Map());

    useEffect((): void => {
        if (!localityId) {
            return;
        }
        // noinspection JSIgnoredPromiseFromCall
        search();

        return;
    }, [localityId, deliveryMethodId]);

    const search = async (): Promise<void> => {
        request(async (): Promise<void> => {
            if (!localityId) {
                return;
            }

            const deliveryPoints = await searchDeliveryPoints(localityId, deliveryMethodId);

            setDeliveryPoints(toDictionary(deliveryPoints));
        });
    };

    if (!localityId) {
        return null;
    }

    return (
        <Select
            label="Пункт выдачи"
            options={[...deliveryPoints].map(([, point]) => ({
                label: point.address,
                value: point.id,
            }))}
            value={deliveryPointId || undefined}
            onChange={(e): void => {
                setDeliveryPoint(deliveryPoints.get(+e.target.value) as DeliveryPointModel);
            }}
        />
    );
}
