import { post } from "../api";
import { PromoCodeModel } from "./models/PromoCodeModel";

export type StorePromoCodeData = {
    promoCode: PromoCodeModel;
};

const createPromoCode = async (body: FormData): Promise<StorePromoCodeData> => {
    return await post({
        url: `/admin/promo_codes`,
        body,
    });
};

const updatePromoCode = async (id: PromoCodeModel[`id`], body: FormData): Promise<StorePromoCodeData> => {
    return await post({
        url: `/admin/promo_codes/${id}`,
        body,
    });
};

export const savePromoCode = async (promoCode: PromoCodeModel | null, body: FormData): Promise<StorePromoCodeData> => {
    return promoCode ? updatePromoCode(promoCode.id, body) : createPromoCode(body);
};
