import { initLanguageKeyObject, Language, LanguageKeyObject } from "@admin/domain/languages/Languages";
import React, { useState } from "react";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import { v4 as uuid } from "uuid";
import Sortable from "@admin/components/Sortable";
import Input from "@admin/components/Input";
import MultiLanguages from "@admin/components/MultiLanguage";

export default function PaymentPageWarnings({ initWarnings }: { initWarnings: LanguageKeyObject[] }) {
    const languageContext = useLanguageContext();
    const [showAddForm, setShowAddForm] = useState(false);
    const [warnings, setWarnings] = useState<{ id: string; text: LanguageKeyObject }[]>(
        initWarnings.map((value) => ({ id: uuid(), text: value })),
    );

    const addForm = () => {
        if (!showAddForm) {
            return (
                <button className="btn btn-primary mb-5" type="button" onClick={() => setShowAddForm(true)}>
                    Добавить предупреждение
                </button>
            );
        }

        const warning = { id: uuid(), text: initLanguageKeyObject() };
        const submitWarning = () => {
            for (const language of languageContext.availableLanguages) {
                if (!warning.text[language]) return false;
            }
            setWarnings((prev) => [...prev, warning]);
            setShowAddForm(false);
        };

        return (
            <div className="row mb-5">
                <MultiLanguages
                    languageElement={(language: Language) => (
                        <>
                            <Input
                                label="Предупреждение"
                                className={{ block: `col-6` }}
                                onChange={(event) => (warning.text[language] = event.target.value)}
                            />
                        </>
                    )}
                />

                <div className="col d-flex align-items-end">
                    <button className="btn btn-primary " type="button" onClick={submitWarning}>
                        Добавить
                    </button>
                </div>
            </div>
        );
    };

    return (
        <div className="mb-10">
            <h4 className="mb-5">Блок с предупреждениями</h4>

            {addForm()}

            <Sortable list={warnings} setList={setWarnings}>
                {warnings.map((warning, index) => (
                    <div key={warning.id} className="card border mb-5 cursor-pointer">
                        <div className="card-body">
                            <div className="mb-3">{warning.text[languageContext.currentLanguage]}</div>

                            <button
                                type="button"
                                className="d-block card-link text-danger"
                                onClick={(event) => {
                                    event.preventDefault();
                                    setWarnings((prev) => prev.filter((prev) => prev.id !== warning.id));
                                }}
                            >
                                Удалить
                            </button>
                        </div>

                        {Object.entries(languageContext.availableLanguages).map(([, lang]) => (
                            <input
                                key={lang}
                                type="hidden"
                                name={`content[warnings][${index}][${lang}]`}
                                value={warning.text[lang]}
                            />
                        ))}
                    </div>
                ))}
            </Sortable>
        </div>
    );
}
