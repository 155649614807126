import React, { useState } from "react";

import type { BlockProps } from "@admin/components/Content/ContentBlocks";
import type { ProductsSliderModel } from "@admin/domain/content/models/ContentBlockValueModel";
import MultiLanguages from "@admin/components/MultiLanguage";
import ProductsSearch from "../../ProductsSearch";

const defaultValue: ProductsSliderModel = {
    products: [],
    title: {},
    button_text: {},
    url: ``,
};

type Props = BlockProps<ProductsSliderModel>;

export default function ProductsSliderBlock({ index, value = structuredClone(defaultValue) }: Props) {
    const [products, setProducts] = useState(value.products || []);

    return (
        <>
            <MultiLanguages
                languageElement={(language) => (
                    <>
                        <div className="mb-5">
                            <label className="form-label">Заголовок</label>
                            <input
                                name={`blocks[${index}][value][title][${language}]`}
                                defaultValue={value.title[language] || ``}
                                className="form-control"
                            />
                        </div>

                        <div className="mb-5">
                            <label className="form-label">Текст кнопки</label>
                            <input
                                name={`blocks[${index}][value][button_text][${language}]`}
                                defaultValue={value.button_text[language] || ``}
                                className="form-control"
                            />
                        </div>
                    </>
                )}
            />

            <div className="mb-5">
                <label className="form-label">Ссылка для кнопки</label>
                <input name={`blocks[${index}][value][url]`} defaultValue={value.url || ``} className="form-control" />
            </div>

            <div className="rounded border p-10 my-5">
                <h3>Товары</h3>

                {products.map((product, productIndex) => (
                    <div key={product.id || `product-${productIndex}`} className="col mb-5">
                        <ProductsSearch
                            inputName={`blocks[${index}][value][product_ids][${productIndex}]`}
                            productId={product.id}
                            productTitle={product.title}
                            onChange={(value, label): void =>
                                setProducts(
                                    products.map((item, itemIndex) => {
                                        if (itemIndex === productIndex) {
                                            return {
                                                id: value || ``,
                                                title: label || ``,
                                            };
                                        }

                                        return item;
                                    }),
                                )
                            }
                        />

                        <a
                            className="card-link text-danger"
                            onClick={(): void =>
                                setProducts(products.filter((_, itemIndex): boolean => productIndex !== itemIndex))
                            }
                        >
                            Удалить
                        </a>
                    </div>
                ))}

                <div className="mb-5 d-flex">
                    <div className="me-5">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(): void =>
                                setProducts([
                                    ...products,
                                    {
                                        id: ``,
                                        title: ``,
                                    },
                                ])
                            }
                        >
                            Добавить товар
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
