import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Alert } from "react-bootstrap";
import { v4 as uuid } from "uuid";

import type { AdvantageModel } from "@admin/domain/uniquePages/models/MainPageModel";
import type { FileModel } from "@admin/domain/files/model/FileModel";
import { initLanguageKeyObject, type Language } from "@admin/domain/languages/Languages";
import Input from "@admin/components/Input";
import MultiLanguages from "@admin/components/MultiLanguage";
import ImageInput from "@admin/components/ImageInput";

type Props = {
    showModal: boolean;
    closeModal: () => void;
    onSubmit: (data: AdvantageModel) => void;
    editableBanner?: AdvantageModel;
};

export default function MainPageAdvantageModal(props: Props) {
    const [banner, setBanner] = useState<AdvantageModel>(
        props.editableBanner || {
            id: uuid(),
            title: initLanguageKeyObject(),
            description: initLanguageKeyObject(),
            file: null,
        },
    );

    const [errors, setErrors] = useState<string[]>([]);
    const submit = () => {
        const newErrors: string[] = [];
        if (!banner.file) newErrors.push(`Изображение обязательно`);
        if (!banner.title.RU || !banner.title.EN || !banner.title.TR)
            newErrors.push(`Поле "Заголовок" обязательно для заполнения`);
        if (newErrors.length > 0) {
            setErrors(newErrors);
            return;
        }

        props.onSubmit({ ...banner, file: banner.file as FileModel });
        props.closeModal();
    };

    return (
        <Modal centered show={props.showModal} onHide={props.closeModal}>
            <Modal.Header>
                <Modal.Title>{props.editableBanner ? `Обновление` : `Добавление`} банера</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {errors.length > 0 && (
                    <Alert variant="danger">
                        {errors.map((err, index) => (
                            <div key={index}>{err}</div>
                        ))}
                    </Alert>
                )}

                <div className="d-flex align-items-center justify-content-center">
                    <ImageInput
                        label="Изображение"
                        name=""
                        defaultValue={banner.file}
                        onChange={(file) => setBanner({ ...banner, file })}
                        width="660px"
                        height="475px"
                    />
                </div>

                <MultiLanguages
                    languageElement={(language: Language) => (
                        <>
                            <Input
                                label="Заголовок"
                                onChange={(event) =>
                                    setBanner((prev) => {
                                        const title = prev.title;
                                        title[language] = event.target.value;
                                        return { ...prev, title: title };
                                    })
                                }
                                value={banner.title?.[language] || ``}
                            />

                            <Input
                                label="Текст под заголовком"
                                onChange={(event) =>
                                    setBanner((prev) => {
                                        const description = prev.description;
                                        description[language] = event.target.value;
                                        return {
                                            ...prev,
                                            description: description,
                                        };
                                    })
                                }
                                value={banner.description?.[language]}
                            />
                        </>
                    )}
                />
            </Modal.Body>

            <Modal.Footer>
                <button className="btn btn-secondary" onClick={props.closeModal}>
                    Закрыть
                </button>
                <button className="btn btn-primary" onClick={submit}>
                    {props.editableBanner ? `Обновить` : `Добавить`}
                </button>
            </Modal.Footer>
        </Modal>
    );
}
