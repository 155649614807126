import React from "react";

import { type InputProps } from "../Input";
import { useIsInvalid } from "@admin/components/FormErrorsContext";

type Props = Omit<InputProps, `defaultValue`> & { value?: string | number };

const Switch = ({ label, name, value = 1, className, ...props }: Props) => {
    const isInvalid = useIsInvalid(name);

    return (
        <div className={`d-flex ` + (className?.block ? className.block : `mb-5`)}>
            <label className="form-check-label form-label me-3" htmlFor={`${name}_label`}>
                {label}
            </label>
            <div className="form-check form-switch">
                <input type="hidden" name={name} value={value === 1 || value === `1` ? `0` : ``} />
                <input
                    {...props}
                    type="checkbox"
                    name={name}
                    value={value}
                    id={`${name}_label`}
                    className={(className?.input || `form-check-input`) + (isInvalid ? ` is-invalid` : ``)}
                />
            </div>
        </div>
    );
};

export default Switch;
