import Input from "@admin/components/Input";
import { type UniquePageProps } from "../index";
import { AboutPageModel } from "@admin/domain/uniquePages/models/AboutPageModel";

export default function AboutPageStatistic({ page }: UniquePageProps<AboutPageModel>) {
    return (
        <div className="mb-10">
            <h4>Блок со статистикой</h4>
            <div className="row mb-5">
                <Input
                    className={{ block: `col` }}
                    label="Годы работы"
                    name="content[statistic__years_work]"
                    type="number"
                    defaultValue={page.contentObject.statistic__years_work || ``}
                />
                <Input
                    className={{ block: `col` }}
                    label="Кол-во дистрибьюторов"
                    name="content[statistic__number_distributors]"
                    defaultValue={page.contentObject.statistic__number_distributors || ``}
                />
            </div>
            <div className="row mb-5">
                <Input
                    className={{ block: `col` }}
                    label="Страны"
                    name="content[statistic__countries]"
                    type="number"
                    defaultValue={page.contentObject.statistic__countries || ``}
                />
                <Input
                    className={{ block: `col` }}
                    label="Кол-во клиентов"
                    name="content[statistic__number_clients]"
                    defaultValue={page.contentObject.statistic__number_clients || ``}
                />
            </div>
            <div className="row mb-5">
                <Input
                    className={{ block: `col-6` }}
                    label="Кол-во представительств"
                    name="content[statistic__representative_offices]"
                    defaultValue={page.contentObject.statistic__representative_offices || ``}
                />
            </div>
        </div>
    );
}
