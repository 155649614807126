import isString from "lodash/isString";

const makeURL = (url: string, query: object | null | string | undefined): string => {
    if (!query) {
        return url;
    }

    if (isString(query)) {
        return url + query;
    }

    const GETParameters = new URLSearchParams();

    Object.entries(query).forEach(([key, value]): void => {
        if (value) {
            GETParameters.append(key, value);
        }
    });

    if (!GETParameters.toString()) {
        return url;
    }

    return url + `?` + GETParameters.toString();
};

export default makeURL;
