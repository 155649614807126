import React, { useState } from "react";
import { makePath } from "@admin/utils/makePath";
import MainPageBuyerChoiceModal from "./MainPageBuyerChoiceModal";
import { BuyerChoiceBannerModel, MainPageModel } from "@admin/domain/uniquePages/models/MainPageModel";
import { v4 as uuid } from "uuid";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import Input from "@admin/components/Input";
import MultiLanguages from "@admin/components/MultiLanguage";
import Select from "@admin/components/Select";
import { type UniquePageProps } from "../../index";

export default function MainPageBuyerChoice({ page }: UniquePageProps<MainPageModel>) {
    const bannersInit = (page.contentObject.buyers_choice__banners || []).map((value) => {
        return { ...value, id: uuid() };
    });

    const currentLanguage = useLanguageContext().currentLanguage;

    const [showModal, setShowModal] = useState(false);
    const [banners, setBanners] = useState(bannersInit);
    const [editableBanner, setEditableBanner] = useState<BuyerChoiceBannerModel | undefined>();

    const products = {};
    page.payload.products?.forEach((product) => {
        const textsMap = new Map(product.texts.map((text) => [text.language, text]));
        products[product.id] = textsMap.get(currentLanguage)?.title;
    });

    const addHandle = (data: BuyerChoiceBannerModel) => {
        setBanners((prev) => [...prev, data]);
    };

    const editHandle = (data: BuyerChoiceBannerModel) => {
        setBanners((prev) => {
            const index = prev.findIndex((value) => value.id === data.id);
            prev[index] = data;
            return prev;
        });
    };

    return (
        <div className="mb-10">
            <h4 className="mb-5">Блок Выбор покупателей</h4>
            <div>
                <MultiLanguages
                    languageElement={(language) => (
                        <div>
                            <Input
                                label="Заголовок"
                                name={`content[buyers_choice__title][${language}]`}
                                defaultValue={page.contentObject.buyers_choice__title[language]}
                            />
                        </div>
                    )}
                />
            </div>
            <div>
                {banners.map((banner, index) => (
                    <div key={banner.id} className="mb-5" style={{ width: `fit-content`, height: `fit-content` }}>
                        <div className="card border">
                            <img
                                style={{ width: `660px`, height: `375px` }}
                                className="card-img-top img_placeholder img-thumbnail"
                                src={makePath(banner.file.path)}
                            />
                            <input
                                type="hidden"
                                name={`content[buyers_choice__banners][${index}][file_id]`}
                                value={banner.file.id}
                            />
                            <div className="card-body">
                                <div className="card-text mb-3">
                                    <div>
                                        <span className="fw-bolder">Текст отзыва:{` `}</span>
                                        {` `}
                                        {banner.text}
                                        <input
                                            type="hidden"
                                            name={`content[buyers_choice__banners][${index}][text]`}
                                            value={banner.text}
                                        />
                                    </div>
                                    <div className="card-text mb-3">
                                        <div>
                                            <span className="fw-bolder">Имя создателя отзыва:{` `}</span>
                                            {` `}
                                            {banner.reviewer_name}
                                            <input
                                                type="hidden"
                                                name={`content[buyers_choice__banners][${index}][reviewer_name]`}
                                                value={banner.reviewer_name}
                                            />
                                        </div>
                                    </div>
                                    <div className="card-text mb-3">
                                        <div>
                                            <img
                                                style={{ width: `100px` }}
                                                className="card-img-top img_placeholder img-thumbnail"
                                                src={makePath(banner.reviewer_file.path)}
                                            />
                                            <input
                                                type="hidden"
                                                name={`content[buyers_choice__banners][${index}][reviewer_file_id]`}
                                                value={banner.reviewer_file.id}
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <Select
                                            label="Товар"
                                            options={products}
                                            name={`content[buyers_choice__banners][${index}][product_id]`}
                                            defaultValue={banner.product?.id}
                                            required
                                        />
                                    </div>
                                </div>
                                <div>
                                    <a
                                        className="card-link text-success"
                                        onClick={() => {
                                            setEditableBanner(banner);
                                            setShowModal(true);
                                        }}
                                    >
                                        Редактировать
                                    </a>
                                    <a
                                        className="card-link text-danger"
                                        onClick={() => {
                                            setBanners((prev) => prev.filter((value) => value.id !== banner.id));
                                        }}
                                    >
                                        Удалить
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {banners.length < 10 && (
                <i
                    className="bi bi-plus-square text-primary fs-2hx cursor-pointer ms-3 align-self-center"
                    onClick={() => {
                        setEditableBanner(undefined);
                        setShowModal(true);
                    }}
                />
            )}
            {showModal && (
                <MainPageBuyerChoiceModal
                    showModal={showModal}
                    closeModal={() => setShowModal(false)}
                    onSubmit={editableBanner ? editHandle : addHandle}
                    editableBanner={editableBanner}
                />
            )}
        </div>
    );
}
