import React, { createRef, useEffect, useState } from "react";
import MultiLanguages from "@admin/components/MultiLanguage";
import { makePath } from "@admin/utils/makePath";
import { upload } from "@admin/domain/products/api";
import { FileModel } from "@admin/domain/files/model/FileModel";
import { type UniquePageProps } from "../index";
import { MainPageModel } from "@admin/domain/uniquePages/models/MainPageModel";

export default function MainPageECatalog({ page }: UniquePageProps<MainPageModel>) {
    const [document, setDocument] = useState<FileModel | null>(null);
    const fileUpload = createRef<HTMLInputElement>();
    useEffect(() => {
        if (page?.contentObject?.e_catalog__document) {
            setDocument(page?.contentObject?.e_catalog__document);
        }
    }, [page?.contentObject?.e_catalog__document]);

    async function addFile(event: React.ChangeEvent<HTMLInputElement>) {
        await upload(event.target.files?.item(0) as File).then((document) => setDocument(document));
    }

    return (
        <div className="mb-10">
            <h4 className="mb-5">Электронный каталог</h4>
            <MultiLanguages
                languageElement={(language) => (
                    <div>
                        <div className="mb-5">
                            <label className="form-label">Заголовок</label>
                            <input
                                className="form-control"
                                name={`content[e_catalog__title][${language}]`}
                                defaultValue={page.contentObject.e_catalog__title?.[language] || ``}
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Описание (Текст под заголовком)</label>
                            <textarea
                                className="form-control"
                                name={`content[e_catalog__description][${language}]`}
                                defaultValue={page.contentObject.e_catalog__description?.[language] || ``}
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Текст кнопки 1</label>
                            <input
                                className="form-control"
                                name={`content[e_catalog__show_button__text][${language}]`}
                                defaultValue={page.contentObject.e_catalog__show_button__text?.[language] || ``}
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Текст кнопки 2</label>
                            <input
                                className="form-control"
                                name={`content[e_catalog__download_button__text][${language}]`}
                                defaultValue={page.contentObject.e_catalog__download_button__text?.[language] || ``}
                            />
                        </div>
                    </div>
                )}
            />
            <div className="row">
                <h4 className="mb-5">Файл электронного каталога</h4>
                <br />
                {!document && (
                    <div className="mb-5">
                        <input className="d-none" type="file" accept=".pdf" ref={fileUpload} onChange={addFile} />
                        <button type="button" className="btn btn-primary" onClick={() => fileUpload.current?.click()}>
                            Загрузить файл
                        </button>
                    </div>
                )}
                {document && (
                    <div className="card border mb-4 position-relative">
                        <div className="card-body">
                            <div className="d-flex flex-column justify-content-center">
                                <div>
                                    <span className="fw-bolder">Название файла:</span>
                                    {document?.name}
                                </div>
                                <div>
                                    <span className="fw-bolder">Формат файла:</span>
                                    {document?.mime}
                                </div>
                                <div>
                                    <span className="fw-bolder">Дата загрузки:</span>
                                    {document?.created_at}
                                </div>
                                <a
                                    href={page.contentObject.e_catalog__document ? makePath(document.path) : ``}
                                    target="_blank"
                                    className="fw-bolder"
                                >
                                    Скачать
                                </a>
                                <input
                                    type="hidden"
                                    name={`content[e_catalog__document][file_id]`}
                                    defaultValue={document.id}
                                />
                            </div>
                        </div>
                        <i
                            className="bi bi-x-circle position-absolute text-danger end-0 mt-5 me-5 cursor-pointer"
                            onClick={() => {
                                setDocument(null);
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
