import React from "react";

import type { ArticleContentBlockModel } from "@admin/domain/articles/models/ArticleContentBlockModel";
import { ArticleBlockType } from "@admin/domain/articles/enums/ArticleBlockType";
import MultiLanguages from "@admin/components/MultiLanguage";
import ImageInput from "@admin/components/ImageInput";

type Props = {
    block: ArticleContentBlockModel & {
        type: ArticleBlockType.TitleWithTextImage;
    };
    index: number;
};

export default function TitleWithTextImageBlock({ block: { value }, index }: Props) {
    return (
        <>
            <MultiLanguages
                languageElement={(language) => (
                    <>
                        <div className="mb-5">
                            <label className="form-label">Заголовок</label>
                            <input
                                className="form-control"
                                name={`blocks[${index}][value][title][${language}]`}
                                defaultValue={value?.title?.[language] || ``}
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Текст</label>
                            <input
                                className="form-control"
                                name={`blocks[${index}][value][description][${language}]`}
                                defaultValue={value?.description?.[language] || ``}
                            />
                        </div>
                    </>
                )}
            />

            <div className="mb-5">
                <ImageInput label="Изображение" name={`blocks[${index}][value][file_id]`} defaultValue={value?.file} />
            </div>
        </>
    );
}
