import React, { createRef, useState } from "react";

import { type FileModel } from "@admin/domain/files/model/FileModel";
import { upload } from "@admin/domain/products/api";
import { makePath } from "@admin/utils/makePath";
import { useIsInvalid } from "@admin/components/FormErrorsContext";
import Sortable from "@admin/components/Sortable";

type DocumentsProps = {
    label: string;
    name: string;
    accept?: string;
    defaultValue: FileModel[];
};

export default function DocumentsInput({ label, name, accept, defaultValue }: DocumentsProps) {
    const fileUpload = createRef<HTMLInputElement>();

    let validationName = name;
    if (validationName.slice(-2) === `[]`) {
        validationName = validationName.slice(0, -2);
    }

    const isInvalid = useIsInvalid(validationName);

    const [documents, setDocuments] = useState(defaultValue);

    function addDocument(event: React.ChangeEvent<HTMLInputElement>) {
        upload(event.target.files?.item(0) as File).then((document) => setDocuments([...documents, document]));
    }

    return (
        <div className={`rounded border p-10 my-5` + (isInvalid ? ` border-danger` : ``)}>
            <h4 className="mb-5">{label}</h4>

            <div className="mb-5">
                <input className="d-none" type="file" accept={accept} ref={fileUpload} onChange={addDocument} />
                <button type="button" className="btn btn-primary" onClick={() => fileUpload.current?.click()}>
                    Добавить файл
                </button>
            </div>

            <Sortable list={documents} setList={setDocuments}>
                {documents.map((document, index) => (
                    <div
                        key={document.id}
                        className={
                            `card border mb-4 position-relative` +
                            (useIsInvalid(`${validationName}[${index}]`) ? ` border-danger` : ``)
                        }
                    >
                        <div className="card-body d-flex">
                            <div className="d-flex flex-column justify-content-center">
                                <div>
                                    <span className="fw-bolder">Название файла:</span>
                                    {document.name}
                                </div>
                                <div>
                                    <span className="fw-bolder">Формат файла:</span>
                                    {document.mime}
                                </div>
                                <div>
                                    <span className="fw-bolder">Размер файла:</span>
                                    {document.metadata.size}
                                </div>
                                <div>
                                    <span className="fw-bolder">Дата загрузки:</span>
                                    {document.created_at}
                                </div>
                                <a href={makePath(document.path)} target="_blank" className="fw-bolder">
                                    Скачать
                                </a>
                                <input type="hidden" name={name} defaultValue={document.id} />
                            </div>
                        </div>
                        <i
                            className="bi bi-x-circle position-absolute text-danger end-0 mt-5 me-5 cursor-pointer"
                            onClick={() =>
                                setDocuments((prevState) =>
                                    prevState.filter((prevDocument) => prevDocument.id !== document.id),
                                )
                            }
                        />
                    </div>
                ))}
            </Sortable>
        </div>
    );
}
