import BreadCrumbs from "@admin/components/BreadCrumbs";
import React, { useState } from "react";
import Input from "@admin/components/Input";
import MultiLanguages from "@admin/components/MultiLanguage";
import TextArea from "@admin/components/TextArea";
import Switch from "@admin/components/Switch";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Sortable from "@admin/components/Sortable";
import { GroupDeliveryMethodModel } from "@admin/domain/deliveryMethods/models/DeliveryMethodGroupModel";
import { createDeliveryMethodGroup, updateDeliveryMethodGroup } from "@admin/domain/deliveryMethods/api";
import Editor from "@admin/components/Editor";

export default function DeliveryMethodGroupEditor() {
    const deliveryMethodGroup = window.CMS.deliveryMethodGroup || null;
    const currentLanguage = useLanguageContext().currentLanguage;
    const [deliveryMethods, setDeliveryMethods] = useState(
        deliveryMethodGroup?.delivery_methods as GroupDeliveryMethodModel[],
    );

    const execute = async (body: FormData): Promise<void> => {
        if (deliveryMethodGroup?.id) {
            await updateDeliveryMethodGroup(deliveryMethodGroup.id, body);
        } else {
            const { id } = await createDeliveryMethodGroup(body);

            location.replace(`/admin/delivery_method_groups/${id}/edit`);
        }
    };

    return (
        <>
            <BreadCrumbs
                className="mb-5"
                breadCrumbs={[
                    {
                        title: `Способы доставки`,
                        url: `/admin/delivery_methods/menu`,
                    },
                    {
                        title: `Общие настройки доставки`,
                        url: `/admin/delivery_methods/settings`,
                    },
                    {
                        title: `Обобщенные способы доставки`,
                        url: `/admin/delivery_method_groups`,
                    },
                    {
                        title: deliveryMethodGroup?.id
                            ? `Редактирование ` + deliveryMethodGroup?.texts[currentLanguage]?.title
                            : `Добавление обобщенного способа доставки`,
                    },
                ]}
            />

            <Editor onSubmit={execute}>
                <MultiLanguages
                    languageElement={(language) => (
                        <>
                            <Input
                                label="Название"
                                name={`texts[${language}][title]`}
                                defaultValue={
                                    deliveryMethodGroup?.id ? deliveryMethodGroup?.texts[language]?.title : ``
                                }
                            />

                            <TextArea
                                label="Краткое описание"
                                name={`texts[${language}][short_text]`}
                                defaultValue={
                                    deliveryMethodGroup?.id ? deliveryMethodGroup?.texts[language]?.short_text : ``
                                }
                            />
                        </>
                    )}
                />

                <Input label="Порядок" type="number" name="order" defaultValue={deliveryMethodGroup?.order} />

                <Switch label="Скрыть / Показать" name="is_public" defaultChecked={deliveryMethodGroup?.is_public} />

                <div className="mb-5">
                    <label className="form-label fs-4">
                        Способы доставки
                        <OverlayTrigger overlay={<Tooltip>Зажмите один из методов доставки для сортировки</Tooltip>}>
                            <i className="ms-3 bi bi-question-circle text-black-50" />
                        </OverlayTrigger>
                    </label>

                    <Sortable list={deliveryMethods} setList={setDeliveryMethods}>
                        {deliveryMethods.map((deliveryMethod, index) => (
                            <div key={deliveryMethod.id}>
                                <Switch
                                    className={{ block: `mb-0` }}
                                    label={deliveryMethod.texts[currentLanguage]?.title || ``}
                                    name={`delivery_method_item[${index}]`}
                                    checked={deliveryMethod.checked}
                                    value={deliveryMethod.id}
                                    onChange={() =>
                                        setDeliveryMethods((prev) =>
                                            prev.map((value) => {
                                                if (value.id === deliveryMethod.id) value.checked = !value.checked;
                                                return value;
                                            }),
                                        )
                                    }
                                />
                            </div>
                        ))}
                    </Sortable>
                </div>
            </Editor>
        </>
    );
}
