import React from "react";
import { EditOrderData } from "@admin/domain/orders/dto/EditOrderData";
import formatMoney from "@admin/domain/orders/formatMoney";

type Props = {
    order: EditOrderData;
};

export default function ConsolidatedOrderReceiptMessage({ order }: Props) {
    if (!order.is_child || !order.consolidated_order_receipt_id) {
        return null;
    }

    return (
        <p>
            Внимание! Существует чек по оплате для группового заказа
            {` `}
            <a href={`/admin/orders/${order.consolidated_order_id}`} target="_blank">
                {order.consolidated_order_id}
            </a>
            {` `}
            на сумму{` `}
            {formatMoney(order, `consolidated_order_total_amount`)}
        </p>
    );
}
