import React, { useMemo, useState } from "react";
import { v4 as uuid } from "uuid";

import { BusinessPageModel } from "@admin/domain/uniquePages/models/BusinessPageModel";
import { type UniquePageProps } from "../index";
import MultiLanguages from "@admin/components/MultiLanguage";
import VideoInput from "../../../components/VideoInput";

export default function BusinessPageLeaders({ page }: UniquePageProps<BusinessPageModel>) {
    const initialBanners: BusinessPageModel[`leaders__banners`] = useMemo(
        () =>
            (page.contentObject.leaders__banners || []).map((value) => ({
                ...value,
                id: uuid(),
            })),
        [page.contentObject.leaders__banners],
    );

    const [banners, setBanners] = useState(initialBanners);

    return (
        <div className="mb-10">
            <h4 className="mb-5">Лидеры месяца</h4>
            <div>
                <MultiLanguages
                    languageElement={(language) => (
                        <div>
                            <div className="mb-5">
                                <label className="form-label">Заголовок</label>
                                <input
                                    className="form-control"
                                    name={`content[leaders__title][${language}]`}
                                    defaultValue={page.contentObject.leaders__title?.[language] || ``}
                                />
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="row row-cols-auto">
                {banners.map((banner, index) => (
                    <div key={banner.id} className="col mb-5">
                        <div>
                            <MultiLanguages
                                languageElement={(language) => (
                                    <div>
                                        <div className="mb-5">
                                            <label className="form-label">Информация</label>
                                            <input
                                                className="form-control"
                                                name={`content[leaders__banners][${index}][info][${language}]`}
                                                defaultValue={banner.info?.[language] || ``}
                                            />
                                        </div>
                                    </div>
                                )}
                            />

                            <div className="mb-5">
                                <label className="form-label">Имя</label>
                                <input
                                    className="form-control"
                                    name={`content[leaders__banners][${index}][full_name]`}
                                    defaultValue={banner.full_name}
                                />
                            </div>

                            <VideoInput
                                name={{
                                    file: `content[leaders__banners][${index}][video_file_id]`,
                                }}
                                defaultValue={{
                                    file: banner.video_file,
                                }}
                                allowLink={false}
                            />

                            <div className="row">
                                <a
                                    className="card-link text-danger"
                                    onClick={() => {
                                        setBanners(banners.filter((value) => value.id !== banner.id));
                                    }}
                                >
                                    Удалить
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="mb-5 d-flex">
                <div className="me-5">
                    <button
                        type="button"
                        className="btn btn-success"
                        onClick={() =>
                            setBanners([
                                ...banners,
                                {
                                    id: uuid(),
                                    video_file: null,
                                    full_name: ``,
                                    info: {},
                                },
                            ])
                        }
                    >
                        Добавить лидера
                    </button>
                </div>
            </div>
        </div>
    );
}
