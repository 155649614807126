import React, { useState } from "react";
import isFunction from "lodash-es/isFunction";
import { useLanguageContext } from "../LanguagesContext";
import { Language } from "@admin/domain/languages/Languages";

type MultiLanguagesProps = {
    languageElement: (language: Language) => React.JSX.Element;
    hasErrors?: (language: Language) => boolean;
};

export default function MultiLanguages({ languageElement, hasErrors }: MultiLanguagesProps) {
    const languageContext = useLanguageContext();
    const availableLanguages = languageContext.availableLanguages;
    const [activeLang, setActiveLang] = useState(languageContext.currentLanguage);

    if (availableLanguages.length === 1) {
        return languageElement(availableLanguages[0]);
    }

    return (
        <div className="mb-5">
            <div>
                <ul className="nav nav-tabs">
                    {availableLanguages.map((lang) => (
                        <li key={lang} className="nav-item cursor-pointer" onClick={() => setActiveLang(lang)}>
                            <span
                                className={`nav-link ${isFunction(hasErrors) && hasErrors(lang) ? `bg-danger text-white fw-boldest` : `${lang === activeLang ? `active text-primary` : `text-dark`}`}`}
                            >
                                {lang}
                            </span>
                        </li>
                    ))}
                </ul>
                <div className="border-end border-bottom border-start p-10">
                    {availableLanguages.map((lang) => (
                        <div key={lang} className={activeLang === lang ? `mb-5` : `d-none`}>
                            {languageElement(lang)}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
