import React, { useState } from "react";

import { saveDeliveryPoint } from "@admin/domain/deliveryPoints/api";
import Editor from "@admin/components/Editor";
import CommonParameters from "./CommonParameters";
import Address from "./Address";
import Contacts from "./Contacts";
import Identifiers from "./Identifiers";

const LIST_URL = `/admin/delivery_points` as const;

const DeliveryPointEditor: React.FC = () => {
    const deliveryPoint = window.CMS.deliveryPoint || null;

    const [provider, setProvider] = useState(deliveryPoint?.provider || null);

    const execute = async (body: FormData): Promise<void> => {
        await saveDeliveryPoint(deliveryPoint, body);
    };

    return (
        <Editor
            backUrl={deliveryPoint ? `` : LIST_URL}
            onSubmit={execute}
            additionalButtons={({ isLoading }) =>
                deliveryPoint && (
                    <button
                        type="button"
                        className="btn btn-danger me-4"
                        disabled={isLoading}
                        onClick={(): boolean => confirm(`Вы уверены удалить пункт выдачи?`)}
                    >
                        Удалить
                    </button>
                )
            }
        >
            <CommonParameters deliveryPoint={deliveryPoint} setProvider={setProvider} />
            <Address deliveryPoint={deliveryPoint} />
            <Contacts deliveryPoint={deliveryPoint} />
            <Identifiers deliveryPoint={deliveryPoint} provider={provider} />
        </Editor>
    );
};

export default DeliveryPointEditor;
