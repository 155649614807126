import React, { useState } from "react";
import Input from "@admin/components/Input";
import TextArea from "@admin/components/TextArea";
import MultiLanguages from "@admin/components/MultiLanguage";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import { AboutPageModel, AboutPageResearchBannerModel } from "@admin/domain/uniquePages/models/AboutPageModel";
import { makePath } from "@admin/utils/makePath";
import AboutPageResearchModal from "./AboutPageResearchModal";
import { type UniquePageProps } from "../../index";
import { v4 as uuid } from "uuid";

export default function AboutPageResearch({ page }: UniquePageProps<AboutPageModel>) {
    const bannersInit = (page.contentObject.research_banners || []).map((banner) => {
        banner.id = uuid();
        return banner;
    });
    const languageContext = useLanguageContext();
    const [showModal, setShowModal] = useState(false);
    const [researchBanners, setResearchBanners] = useState(bannersInit);

    const [editableBanner, setEditableBanner] = useState<AboutPageResearchBannerModel | undefined>();
    const addHandle = (data: AboutPageResearchBannerModel) => {
        setResearchBanners((prev) => [...prev, data]);
    };

    const editHandle = (data: AboutPageResearchBannerModel) => {
        setResearchBanners((prev) =>
            prev.map((value) => {
                if (value.id === data.id) value = data;
                return value;
            }),
        );
    };

    return (
        <div className="mb-10">
            <h4 className="mb-5">Блок с плашками исследований</h4>
            <MultiLanguages
                languageElement={(language) => (
                    <div>
                        <Input
                            label="Заголовок"
                            name={`content[research_text__title][${language}]`}
                            defaultValue={page.contentObject.research_text__title[language]}
                        />
                        <TextArea
                            label="Описание (текст под заголовком)"
                            name={`content[research_text__description][${language}]`}
                            defaultValue={page.contentObject.research_text__description[language]}
                        />
                    </div>
                )}
            />

            <div className="mb-5">
                <div className="row row-cols-auto">
                    {researchBanners.map((research, index) => (
                        <div
                            key={index}
                            className="mb-5"
                            style={{
                                width: `fit-content`,
                                height: `fit-content`,
                            }}
                        >
                            <div className="card border">
                                <img
                                    src={makePath(research.file.path)}
                                    style={{ width: `320px`, height: `400px` }}
                                    className="card-img-top img_placeholder img-thumbnail"
                                    alt=""
                                />
                                <input
                                    type="hidden"
                                    defaultValue={research.file.id}
                                    name={`content[research_banners][${index}][file_id]`}
                                />

                                <div className="card-body">
                                    <div className="card-text mb-3">
                                        <div>
                                            <span className="fw-bolder">Название:{` `}</span>
                                            {research.title[languageContext.currentLanguage]}
                                            {Object.entries(languageContext.availableLanguages).map(([, lang]) => (
                                                <input
                                                    key={lang}
                                                    type="hidden"
                                                    name={`content[research_banners][${index}][title][${lang}]`}
                                                    value={research.title[lang]}
                                                />
                                            ))}
                                        </div>
                                        <div>
                                            <a className="link" href={research.url} target="_blank">
                                                Ссылка
                                            </a>
                                            <input
                                                type="hidden"
                                                name={`content[research_banners][${index}][url]`}
                                                value={research.url}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <a
                                            className="card-link text-success"
                                            onClick={() => {
                                                setEditableBanner(research);
                                                setShowModal(true);
                                            }}
                                        >
                                            Редактировать
                                        </a>
                                        <a
                                            href="#"
                                            className="card-link text-danger"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                setResearchBanners((prev) =>
                                                    prev.filter((_, prevIndex) => prevIndex !== index),
                                                );
                                            }}
                                        >
                                            Удалить
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {researchBanners.length <= 10 && (
                    <i
                        className="bi bi-plus-square text-primary fs-2hx cursor-pointer ms-3 align-self-center"
                        onClick={() => {
                            setEditableBanner(undefined);
                            setShowModal(true);
                        }}
                    />
                )}
            </div>
            {showModal && (
                <AboutPageResearchModal
                    showModal={showModal}
                    closeModal={() => setShowModal(false)}
                    editableBanner={editableBanner}
                    onSubmit={editableBanner ? editHandle : addHandle}
                />
            )}
        </div>
    );
}
