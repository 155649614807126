import { Modal } from "react-bootstrap";
import React from "react";
import { deleteAllDeliveryMethodNotifications } from "@admin/domain/deliveryMethods/api";

type Props = {
    showModal: boolean;
    closeModal: () => void;
};

export default function DeliveryMethodDeleteAllNotificationModal(props: Props) {
    const submit = async () => {
        await deleteAllDeliveryMethodNotifications();
        location.reload();
    };

    return (
        <Modal show={props.showModal} onHide={props.closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Удаление уведомлений</Modal.Title>
            </Modal.Header>
            <Modal.Body>Вы действительно хотите удалить все уведомления ?</Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={props.closeModal}>
                    Нет
                </button>
                <button className="btn btn-primary" onClick={submit}>
                    Да
                </button>
            </Modal.Footer>
        </Modal>
    );
}
