import React, { ChangeEvent, useEffect, useState } from "react";
import { getRubrics } from "@admin/domain/articles/api";
import Select from "@admin/components/Select";

type ArticleRubricsProps = {
    article?: any;
};

export default function ArticleRubrics({ article }: ArticleRubricsProps) {
    const [options, setOptions] = useState<Record<string, string>>({});
    const [value, setValue] = useState<string>(article?.rubric?.id || ``);

    const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setValue(event.target.value);
    };

    useEffect(() => {
        getRubrics().then((rubrics) => {
            const options = {
                "": ``,
            };
            (rubrics || []).forEach(({ id, title }) => {
                options[id] = title;
            });
            setOptions(options);
        });
    }, []);

    return <Select label="Рубрики" options={options} name={`rubric_id`} value={value} onChange={onChange} required />;
}
