import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import { ArticleContentBlockModel } from "@admin/domain/articles/models/ArticleContentBlockModel";
import { Icon as IconsModel } from "@admin/domain/articles/models/ArticleBlockValueModel";
import { ArticleBlockType } from "@admin/domain/articles/enums/ArticleBlockType";
import MultiLanguages from "@admin/components/MultiLanguage";
import ImageInput from "@admin/components/ImageInput";

type Slider = Partial<IconsModel>;

type Props = {
    block: ArticleContentBlockModel & {
        type: ArticleBlockType.Icons;
    };
    index: number;
};

export default function IconsBlock({ block: { value }, index }: Props) {
    const initSlider = (value?.icons || []).map((value) => ({
        ...value,
        id: uuid(),
    }));
    const [slider, setSlider] = useState<Slider[]>(initSlider);

    return (
        <div className="mb-10">
            <MultiLanguages
                languageElement={(language) => (
                    <div className="mb-5">
                        <label className="form-label">Заголовок</label>
                        <input
                            className="form-control"
                            name={`blocks[${index}][value][title][${language}]`}
                            defaultValue={value?.title?.[language] || ``}
                        />
                    </div>
                )}
            />

            <div className="row row-cols-auto">
                {slider.map((slide, itemIndex) => (
                    <div key={slide.id} className="col mb-5">
                        <MultiLanguages
                            languageElement={(language) => (
                                <div className="mb-5">
                                    <label className="form-label">Описание</label>
                                    <input
                                        className="form-control"
                                        name={`blocks[${index}][value][icons][${itemIndex}][title][${language}]`}
                                        defaultValue={slide?.title?.[language] || ``}
                                    />
                                </div>
                            )}
                        />

                        <div className="col mb-5">
                            <ImageInput
                                label="Изображение"
                                name={`blocks[${index}][value][icons][${itemIndex}][file_id]`}
                                defaultValue={slide.file}
                                width="300px"
                                height="300px"
                            />
                        </div>

                        <div>
                            <a
                                className="card-link text-danger"
                                onClick={() => {
                                    setSlider((slideState) => slideState.filter((value) => value.id !== slide.id));
                                }}
                            >
                                Удалить
                            </a>
                        </div>
                    </div>
                ))}
            </div>

            <div className="mb-5 d-flex">
                <div className="me-5">
                    <button
                        type="button"
                        className="btn btn-success"
                        onClick={() =>
                            setSlider((slideState) => [
                                ...slideState,
                                {
                                    id: uuid(),
                                    file: undefined,
                                    title: undefined,
                                },
                            ])
                        }
                    >
                        Добавить слайд
                    </button>
                </div>
            </div>
        </div>
    );
}
