import React from "react";

import { Override } from "@admin/utils/overrideType";
import Select from "@admin/components/Select";

type Props = {
    label?: string;
    name?: string;
    className?: { block?: string };
} & Override<
    React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>,
    {
        className?: { block?: string };
    }
>;

const CountrySelect: React.FC<Props> = ({ label = `Страна`, name = `country_id`, ...props }) => {
    // @ts-ignore
    const { countries }: Record<number, string> = window.CMS;

    const options = Object.entries(countries)
        .map(([id, name]) => ({
            value: id as unknown as number,
            label: name as string,
        }))
        .sort((a, b) => (a.label > b.label ? 1 : -1));

    return <Select label={label} options={options} name={name} {...props} />;
};

export default CountrySelect;
