import get from "lodash/get";
import { EditOrderData } from "@admin/domain/orders/dto/EditOrderData";

export default function compareOrders(oldOrder: EditOrderData, newOrder: EditOrderData): Map<string, [string, string]> {
    const orderChanges = new Map<string, [string, string]>([
        [`target.id`, [`Личный номер получателя заказа`, `target.personal_number`]],
        [`shipping_info.name`, [`Имя получателя`, `shipping_info.name`]],
        [`shipping_info.family_name`, [`Фамилия получателя`, `shipping_info.family_name`]],
        [`shipping_info.patronymic`, [`Отчество получателя`, `shipping_info.patronymic`]],
        [`shipping_info.email`, [`email получателя`, `shipping_info.email`]],
        [`shipping_info.phone`, [`Телефон получателя`, `shipping_info.phone`]],
        [`shipping_info.delivery_method.id`, [`Способ доставки`, `shipping_info.delivery_method.texts.RU.title`]],
        [`shipping_info.locality.id`, [`Город доставки`, `shipping_info.locality.full_name_with_path`]],
        [`payment_method.id`, [`Способ оплаты`, `payment_method.name`]],
    ]);

    orderChanges.forEach((_, field: string): void => {
        if (get(newOrder, field) === get(oldOrder, field)) {
            orderChanges.delete(field);
        }
    });

    return orderChanges;
}
