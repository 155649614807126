import { useState } from "react";

export type UsePhonesHook = {
    phones: ReadonlyArray<string>;
    addPhone: () => void;
    deletePhone: (phoneIndex: number) => void;
};

export const usePhones = (): UsePhonesHook => {
    const { deliveryPoint } = window.CMS;
    const [phones, setPhones] = useState<ReadonlyArray<string>>(deliveryPoint?.phones || [``]);

    const addPhone = (): void => setPhones([...phones, ``]);

    const deletePhone = (phoneIndex: number): void => {
        if (!confirm(`Вы уверены удалить телефон?`)) {
            return;
        }

        setPhones(phones.filter((_, index: number): boolean => phoneIndex !== index));
    };

    return {
        phones,
        addPhone,
        deletePhone,
    };
};
