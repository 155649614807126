import React from "react";

import type ComplexPromotionModel from "@admin/domain/complexPromotions/models/ComplexPromotionModel";
import ContentRequirements from "./ContentRequirements";
import Rewards from "./Rewards";

type Props = {
    promotion: ComplexPromotionModel | null;
};

const ContentPromotion: React.FC<Props> = ({ promotion }) => {
    const levels = [...(promotion?.levels || [])];

    if (levels.length <= 0) {
        levels.push({
            amount_requirement: 0,
            content_requirements: [],
            rewards: [],
        });
    }

    return (
        <>
            {levels.map((level, levelIndex: number) => (
                <React.Fragment key={level.id || `new-level-${levelIndex}`}>
                    <input type="hidden" name={`levels[${levelIndex}][id]`} value={level.id} />

                    <ContentRequirements levelIndex={levelIndex} level={level} />

                    <Rewards levelIndex={levelIndex} level={level} />
                </React.Fragment>
            ))}
        </>
    );
};

export default ContentPromotion;
