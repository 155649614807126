import React from "react";

import { ArticleContentBlockModel } from "@admin/domain/articles/models/ArticleContentBlockModel";
import { ArticleBlockType } from "@admin/domain/articles/enums/ArticleBlockType";
import CheckBox from "@admin/components/CheckBox";

type Props = {
    block: ArticleContentBlockModel & {
        type: ArticleBlockType.ScoreWithLinks;
    };
    index: number;
};

export default function ScoreWithLinksBlock({ block: { value }, index }: Props) {
    const social_networks = value?.social_networks;

    const checkBoxes = {
        vkontakte: social_networks ? social_networks.vkontakte : true,
        odnoklassniki: social_networks ? social_networks.odnoklassniki : true,
        telegram: social_networks ? social_networks.telegram : true,
        whats_app: social_networks ? social_networks.whats_app : true,
        viber: social_networks ? social_networks.viber : true,
    };

    return Object.entries(checkBoxes).map(([socialNetwork, isChecked]: [string, boolean]) => (
        <CheckBox
            key={`checkbox-${socialNetwork}`}
            label={socialNetwork}
            name={`blocks[${index}][value][social_networks][${socialNetwork}]`}
            defaultChecked={isChecked}
        />
    ));
}
