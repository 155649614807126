import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import type { ImageWithTextCheckerboardBlockValueModel } from "@admin/domain/content/models/ContentBlockValueModel";
import type { BlockProps } from "@admin/components/Content/ContentBlocks";
import MultiLanguages from "@admin/components/MultiLanguage";
import ImageInput from "@admin/components/ImageInput";
import ProductsSearch from "@admin/components/ProductsSearch";

const defaultValue: ImageWithTextCheckerboardBlockValueModel = {
    title: {},
    checkerboard_banners: [],
};

type Props = BlockProps<ImageWithTextCheckerboardBlockValueModel>;

export default function ImageWithTextCheckerboardBlock({ index, value = structuredClone(defaultValue) }: Props) {
    const initialValue = (value.checkerboard_banners || []).map((value) => ({
        id: uuid(),
        ...value,
    }));

    const [list, setList] = useState<ImageWithTextCheckerboardBlockValueModel[`checkerboard_banners`]>(initialValue);

    return (
        <>
            <MultiLanguages
                languageElement={(language) => (
                    <div className="mb-5">
                        <label className="form-label">Заголовок раздела</label>
                        <textarea
                            className="form-control"
                            name={`blocks[${index}][value][title][${language}]`}
                            defaultValue={value.title[language] || ``}
                        />
                    </div>
                )}
            />

            {list.map((item, itemIndex) => (
                <div key={item.id} className="rounded border p-4 my-8 mb-5">
                    <MultiLanguages
                        languageElement={(language) => (
                            <>
                                <div className="mb-5">
                                    <label className="form-label">Заголовок</label>
                                    <input
                                        className="form-control"
                                        name={`blocks[${index}][value][checkerboard_banners][${itemIndex}][title][${language}]`}
                                        defaultValue={item.title?.[language] || ``}
                                    />
                                </div>
                                <div className="mb-5">
                                    <label className="form-label">Описание</label>
                                    <textarea
                                        className="form-control"
                                        name={`blocks[${index}][value][checkerboard_banners][${itemIndex}][description][${language}]`}
                                        defaultValue={item.description?.[language] || ``}
                                    />
                                </div>

                                <div className="mb-5">
                                    <label className="form-label">Текст ссылки</label>
                                    <input
                                        className="form-control"
                                        name={`blocks[${index}][value][checkerboard_banners][${itemIndex}][button_text][${language}]`}
                                        defaultValue={item.button_text?.[language] || ``}
                                    />
                                </div>
                            </>
                        )}
                    />

                    <div className="mb-5">
                        <label className="form-label">URL ссылки</label>
                        <input
                            className="form-control"
                            name={`blocks[${index}][value][checkerboard_banners][${itemIndex}][url]`}
                            defaultValue={item.url || ``}
                        />
                    </div>

                    <div className="col mb-5">
                        <ImageInput
                            label="Изображение"
                            name={`blocks[${index}][value][checkerboard_banners][${itemIndex}][file_id]`}
                            defaultValue={item.file}
                            width="300px"
                            height="300px"
                        />
                    </div>

                    <div className="col mb-5">
                        <ProductsSearch
                            inputName={`blocks[${index}][value][checkerboard_banners][${itemIndex}][product_id]`}
                            label="Товар для добавления в корзину"
                            productId={item.product_id}
                            productTitle={item.product_title}
                            required={false}
                             />
                    </div>
                </div>
            ))}

            <div className="mb-5 me-5 d-flex">
                <button
                    type="button"
                    className="btn btn-success"
                    onClick={() =>
                        setList([
                            ...list,
                            {
                                id: uuid(),
                                title: {},
                                description: {},
                                button_text: {},
                                url: ``,
                                file: null,
                                product_id: null,
                                product_title: null,
                            },
                        ])
                    }
                >
                    Добавить изображение
                </button>
            </div>
        </>
    );
}
