import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import type { BlockProps } from "@admin/components/Content/ContentBlocks";
import type { DocumentDirectoriesBlockValueModel } from "@admin/domain/content/models/ContentBlockValueModel";
import type { CustomPageModel } from "@admin/domain/customPages/models/CustomPageModel";
import MultiLanguages from "@admin/components/MultiLanguage";
import Select from "@admin/components/Select";

const defaultValue: DocumentDirectoriesBlockValueModel = {
    directory_list: [],
};

type Props = BlockProps<DocumentDirectoriesBlockValueModel>;

type DirectoryModel = DocumentDirectoriesBlockValueModel[`directory_list`][0];

type DirectoryProps = {
    blockIndex: number;
    directoryIndex: number;
    defaultValue: DirectoryModel;
    list: Array<CustomPageModel>;
};

const Directory = ({ blockIndex, directoryIndex, defaultValue, list }: DirectoryProps) => {
    const [directory, setDirectory] = useState<DirectoryModel>(defaultValue);

    return (
        <MultiLanguages
            languageElement={(language) => (
                <>
                    <Select
                        label="Кастомная страница с файлами"
                        name={`blocks[${blockIndex}][value][directory_list][${directoryIndex}][custom_page_id]`}
                        value={directory.custom_page_id || ``}
                        options={[
                            { value: ``, label: `Создать новую` },
                            ...list.map((page) => ({
                                value: page.id,
                                label: page.textsMap.get(language)?.title || page.id,
                            })),
                        ]}
                        onChange={(event) => {
                            setDirectory({
                                ...directory,
                                custom_page_id: +event.target.value ? +event.target.value : null,
                            });
                        }}
                        className={{ block: `mb-3` }}
                        prependEmptyOption={false}
                    />

                    {directory.custom_page_id ? (
                        <input
                            type="hidden"
                            name={`blocks[${blockIndex}][value][directory_list][${directoryIndex}][title][${language}]`}
                            value=""
                        />
                    ) : (
                        <div className="mb-3">
                            <label className="form-label">Название</label>
                            <input
                                type="text"
                                name={`blocks[${blockIndex}][value][directory_list][${directoryIndex}][title][${language}]`}
                                value={directory.title?.[language] || ``}
                                className="form-control"
                                onChange={(event) => {
                                    setDirectory({
                                        ...directory,
                                        title: {
                                            ...directory.title,
                                            [language]: event.target.value,
                                        },
                                    });
                                }}
                            />
                        </div>
                    )}
                </>
            )}
        />
    );
};

export default function DocumentDirectoriesBlock({ index, value = structuredClone(defaultValue) }: Props) {
    const initialValue = (value.directory_list || []).map((item) => ({
        id: uuid(),
        ...item,
    }));

    const [directories, setDirectories] = useState(initialValue);

    const children = (window.CMS.customPages || []).filter(
        (page) => window.CMS.customPage?.id && page.parent_id === window.CMS.customPage?.id,
    );

    return (
        <>
            <div className="row row-cols-2">
                {directories.map((directory, directoryIndex) => (
                    <div key={directory.id} className="col mb-5">
                        <div className="rounded border p-4 m-0">
                            <Directory
                                blockIndex={index}
                                directoryIndex={directoryIndex}
                                defaultValue={directory}
                                list={children}
                            />

                            <button
                                type="button"
                                className="btn btn-link btn-text-danger p-0"
                                onClick={() =>
                                    setDirectories((prev) => prev.filter((value) => value.id !== directory.id))
                                }
                            >
                                Удалить
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            <div className="mb-5 me-5 d-flex">
                <button
                    type="button"
                    className="btn btn-success"
                    onClick={() =>
                        setDirectories([
                            ...directories,
                            {
                                id: uuid(),
                                title: {},
                                custom_page_id: null,
                            },
                        ])
                    }
                >
                    Добавить папку
                </button>
            </div>
        </>
    );
}
