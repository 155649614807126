import { type UniquePageProps } from "../index";
import { DeliveryAndPaymentModel } from "@admin/domain/uniquePages/models/DeliveryAndPaymentModel";
import DeliveryAndPaymentBlockItem from "./DeliveryAndPaymentBlockItem";
import { FileModel } from "@admin/domain/files/model/FileModel";
import { LanguageKeyObject } from "@admin/domain/languages/Languages";

export default function DeliveryAndPaymentPage({ page }: UniquePageProps<DeliveryAndPaymentModel>) {
    const getInputs = (
        name: string,
    ): {
        title: LanguageKeyObject;
        description: LanguageKeyObject;
        icon: FileModel;
        url: string;
    } => {
        return {
            title: page.contentObject[`${name}__title`],
            description: page.contentObject[`${name}__description`],
            icon: page.contentObject[`${name}__icon`],
            url: page.contentObject[`${name}__url`],
        };
    };

    return (
        <div>
            <h2 className="mb-5">Контент страницы "Доставка и оплата"</h2>
            <div className="row">
                <DeliveryAndPaymentBlockItem title="Доставка" name="delivery" inputs={getInputs(`delivery`)} />
                <DeliveryAndPaymentBlockItem title="Оплата" name="payment" inputs={getInputs(`payment`)} />
            </div>
            <div className="row">
                <DeliveryAndPaymentBlockItem title="Возврат" name="refund" inputs={getInputs(`refund`)} />
                <DeliveryAndPaymentBlockItem
                    title="Оформить заказ"
                    name="how_place_order"
                    inputs={getInputs(`how_place_order`)}
                />
            </div>
        </div>
    );
}
