import React from "react";

import { DeliveryPointModel } from "@admin/domain/deliveryPoints/models/DeliveryPointModel";
import Input from "@admin/components/Input";
import CountrySelect from "@admin/components/CountrySelect";

type Props = {
    deliveryPoint: DeliveryPointModel | null;
};

const Address: React.FC<Props> = ({ deliveryPoint }) => {
    return (
        <>
            <h2>Адрес</h2>

            <CountrySelect defaultValue={deliveryPoint?.country_id} required />

            <Input label="Почтовый индекс" name="postcode" defaultValue={deliveryPoint?.postcode || ``} />

            <Input label="Регион" name="region" defaultValue={deliveryPoint?.region_name || ``} />

            <Input label="Населённый пункт" name="locality" defaultValue={deliveryPoint?.locality_name || ``} />

            <Input label="Адрес" name="address" defaultValue={deliveryPoint?.address || ``} />
        </>
    );
};

export default Address;
