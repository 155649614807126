import React from "react";
import AsyncSelect from "react-select/async";

import { type FoundProduct, type FoundProductsPayload, searchProducts } from "@admin/domain/products/api";
import { useIsInvalid } from "../FormErrorsContext";
import debounce from "lodash-es/debounce";
import { SelectOption } from "@admin/utils/types";

type Props = {
    inputName: string;
    label?: string;
    productId?: string | null;
    productTitle?: string | null;
    required?: boolean | false;
    onChange?: (value: string | null | undefined, label: string | undefined | null) => void;
};

const ProductsSearch: React.FC<Props> = ({ productId, productTitle, inputName, label, required, onChange = () => null }) => {
    const isInvalid = useIsInvalid(inputName);

    const debouncedSearch = debounce((query: string, setOptionsCallback: (options: SelectOption[]) => void) => {
        if (query.length < 2) {
            return;
        }

        searchProducts(query).then(({ products }: FoundProductsPayload): void =>
            setOptionsCallback(
                products.map(
                    (product: FoundProduct): SelectOption => ({
                        // @ts-ignore
                        value: product.id,
                        label: product.title,
                    }),
                ),
            ),
        );
    }, 500);

    return (
        <div className="mb-5">
            {label && <label className="form-label">{label}</label>}

            <AsyncSelect
                classNames={{
                    control: () => `form-control p-0` + (isInvalid ? ` is-invalid` : ``),
                }}
                placeholder="Поиск по названию или артикулу товара"
                // @ts-ignore
                loadOptions={debouncedSearch}
                defaultValue={
                    productId
                        ? {
                              label: productTitle,
                              value: productId,
                          }
                        : undefined
                }
                name={inputName}
                isClearable
                required = {required}
                onChange={(value): void => onChange(value?.value, value?.label)}
            />
        </div>
    );
};

export default ProductsSearch;
