import React, { createRef, useState } from "react";
import { ProductExpertOpinionModel } from "@admin/domain/products/models/edit/ProductExpertOpinionModel";
import { makePath } from "@admin/utils/makePath";
import { upload } from "@admin/domain/products/api";
import MultiLanguages from "@admin/components/MultiLanguage";
import Text from "./Text";

export default function ProductExpertOpinion({
    productExpertOpinion,
}: {
    productExpertOpinion?: ProductExpertOpinionModel;
}) {
    const [isPublic, setIsPublic] = useState(productExpertOpinion?.is_public || false);

    const [expertOpinionImage, setExpertOpinionImage] = useState(productExpertOpinion?.file);
    const inputRef = createRef<HTMLInputElement>();

    function loadImage(event: React.ChangeEvent<HTMLInputElement>) {
        upload(event.target.files?.item(0) as File).then((image) => setExpertOpinionImage(image));
    }

    return (
        <div className="rounded border p-10 mb-5">
            <h4 className="mb-5">Мнение эксперта</h4>
            <div className="mb-5">
                <div className="form-check form-check-custom form-check-solid">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        defaultChecked={isPublic}
                        id="expert_is_public"
                        name="expert[is_public]"
                        value={isPublic ? 1 : 0}
                        onChange={(event) => setIsPublic(event.target.checked)}
                    />
                    <label className="form-check-label form-label" htmlFor="expert_is_public">
                        Показывать мнение эксперта
                    </label>
                </div>
            </div>
            {isPublic && (
                <div>
                    <div className="mb-5">
                        {expertOpinionImage ? (
                            <div className="card border mb-4 position-relative">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <img
                                            className="me-3 img-thumbnail product_list_img"
                                            src={makePath(expertOpinionImage.path)}
                                            alt="image"
                                        />
                                        <div className="d-flex flex-column justify-content-center">
                                            <div>
                                                <span className="fw-bolder">Название файла:</span>
                                                {` `}
                                                {expertOpinionImage.name}
                                            </div>
                                            <div>
                                                <span className="fw-bolder">Формат файла:</span>
                                                {` `}
                                                {expertOpinionImage.mime}
                                            </div>
                                            <div>
                                                <span className="fw-bolder">Размер файла:</span>
                                                {` `}
                                                {expertOpinionImage.metadata.size}
                                            </div>
                                            <a
                                                className="link link-success"
                                                href={makePath(expertOpinionImage.path)}
                                                target="_blank"
                                            >
                                                Посмотреть
                                            </a>
                                            <input
                                                type="hidden"
                                                name="expert[image]"
                                                defaultValue={expertOpinionImage.id}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <i
                                    className="bi bi-x-circle position-absolute text-danger end-0 mt-5 me-5 cursor-pointer"
                                    onClick={() => setExpertOpinionImage(undefined)}
                                />
                            </div>
                        ) : (
                            <div>
                                <input
                                    className="d-none"
                                    type="file"
                                    accept="image/*"
                                    ref={inputRef}
                                    onChange={loadImage}
                                />
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => inputRef.current?.click()}
                                >
                                    Добавить изображение
                                </button>
                            </div>
                        )}
                    </div>

                    <MultiLanguages
                        languageElement={(language) => (
                            <Text language={language} texts={productExpertOpinion?.textsMap} />
                        )}
                    />
                </div>
            )}
        </div>
    );
}
