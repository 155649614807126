import React from "react";

import { saveComplexPromotion } from "@admin/domain/complexPromotions/api";
import Editor from "@admin/components/Editor";
import Common from "./Common";
import AmountPromotion from "./AmountPromotion";
import ContentPromotion from "./ContentPromotion";

const LIST_URL = `/admin/complex_promotions` as const;

type Props = {
    type?: `amount` | `content`;
};

const ComplexPromotionEditor: React.FC<Props> = (props) => {
    const promotion = window.CMS.complexPromotion || null;

    const execute = async (body: FormData) => {
        await saveComplexPromotion(promotion, body);
    };

    const reset = () => {
        // Чтобы сбросить добавленные уровни, проще всего перезагрузить страницу.
        window.location.reload();
    };

    const type = promotion?.type || props.type || `amount`;

    return (
        <Editor
            backUrl={promotion ? `/admin/complex_promotions/${promotion.id}` : LIST_URL}
            onSubmit={execute}
            onReset={reset}
        >
            <Common type={type} promotion={promotion} />

            {type === `content` ? (
                <ContentPromotion promotion={promotion} />
            ) : (
                <AmountPromotion promotion={promotion} />
            )}
        </Editor>
    );
};

export default ComplexPromotionEditor;
