import React from "react";

import SeoTextInput from "./SeoTextInput";
import ImageInput from "./ImageInput";
import TextArea from "@admin/components/TextArea";

type Props = {
    includePrice?: boolean;
};

export default function PageSeoParamsEditor({ includePrice = false }: Props) {
    const seo = window.CMS.seo || null;

    return (
        <div className="border rounded p-4 my-8" id="seo">
            <h2>Настройки SEO</h2>

            <SeoTextInput
                label="Title"
                name="seo[title]"
                defaultValue={seo?.title}
                fallback={seo?.fallback.title}
                includePrice={includePrice}
            />

            <div className="separator separator-dashed mb-4"></div>

            <SeoTextInput
                label="Description"
                name="seo[description]"
                defaultValue={seo?.description}
                fallback={seo?.fallback.description}
                includePrice={includePrice}
            />

            <div className="separator separator-dashed mb-4"></div>

            <ImageInput
                label="Изображение для соц. сетей"
                name="seo[file_id]"
                defaultValue={seo?.image}
                fallback={seo?.fallback.image}
            />

            <div className="separator separator-dashed mb-4"></div>

            <TextArea label="Head" name="seo[head]" defaultValue={seo?.head || ``} />

            <div className="separator separator-dashed mb-4"></div>

            <TextArea label="Body" name="seo[body]" defaultValue={seo?.body || ``} />
        </div>
    );
}
