import React from "react";

import OrderTypeInfo from "./OrderTypeInfo";
import TargetGeneralInfo from "./TargetUserInfo";
import PaymentUrl from "./PaymentUrl";
import GeneralInfoDeliveryAndPaymentDetails from "./DeliveryAndPayment";
import {
    cancelOrder as cancelOrderRequest,
    getOrder as getOrderRequest,
    updateOrder as updateOrderRequest,
} from "@admin/domain/orders/api";
import { useOrderContext } from "../OrderContext";
import { readableOrderStatus } from "@admin/domain/orders/enums/OrderStatus";
import { ResponseErrorInterface } from "@admin/domain/api/ResponseErrorInterface";
import CreatorInfo from "@admin/pages/OrderEditor/GeneralInfo/CreatorInfo";
import OrderChangesFromDistributorModal from "@admin/pages/OrderEditor/GeneralInfo/DistributorOrderChangesModal";
import { EditOrderData } from "@admin/domain/orders/dto/EditOrderData";
import { DateTime } from "luxon";
import OrderChangesFrom1cModal from "@admin/pages/OrderEditor/GeneralInfo/OrderChangesFrom1cModal";
import { useLoaderContext } from "@admin/components/LoaderContext";
import compareOrders from "@admin/domain/orders/compareOrders";
import { useOrderHooks } from "@admin/pages/OrderEditor/hooks";

export default function GeneralInfo() {
    const { order, setOrder } = useOrderContext();
    const { orderRequest } = useOrderHooks();
    const { isLoading, startLoading, stopLoading, setErrorsFromResponse, request } = useLoaderContext();
    const [orderChanges, setOrderChanges] = React.useState<Map<string, [string, string]>>(new Map());
    const [actualOrder, setActualOrder] = React.useState<EditOrderData | null>(null);
    const [orderEditedBy1c, setOrderEditedBy1c] = React.useState<boolean>(false);

    const checkOrderChangesAndUpdate = async (): Promise<void> => {
        startLoading();

        try {
            const { order: actualOrder } = await getOrderRequest(order.id);

            if (
                (actualOrder.edited_by_distributor_at &&
                    DateTime.fromSQL(order.updated_at) < DateTime.fromSQL(actualOrder.edited_by_distributor_at)) ||
                (actualOrder.shipping_info?.edited_by_distributor_at &&
                    order.shipping_info?.updated_at &&
                    DateTime.fromSQL(order.shipping_info.updated_at) <
                        DateTime.fromSQL(actualOrder.shipping_info.edited_by_distributor_at))
            ) {
                const newOrderChanges = compareOrders(order, actualOrder);

                if (newOrderChanges.size > 0) {
                    setOrderChanges(newOrderChanges);
                    setActualOrder(actualOrder);
                } else {
                    await updateOrder();

                    return;
                }

                stopLoading();
                return;
            }

            if (
                (actualOrder.edited_by_accounting_at &&
                    DateTime.fromSQL(order.updated_at) < DateTime.fromSQL(actualOrder.edited_by_accounting_at)) ||
                (actualOrder.shipping_info?.edited_by_accounting_at &&
                    order.shipping_info?.updated_at &&
                    DateTime.fromSQL(order.shipping_info.updated_at) <
                        DateTime.fromSQL(actualOrder.shipping_info.edited_by_accounting_at))
            ) {
                setOrderEditedBy1c(true);

                return;
            }

            await updateOrder();
            stopLoading();
        } catch (e) {
            setErrorsFromResponse(e as ResponseErrorInterface);
        }
    };

    const updateOrder = async (): Promise<void> => {
        orderRequest(async (): Promise<void> => {
            await updateOrderRequest(order);
        });
    };

    const cancelOrder = async (): Promise<void> => {
        request(async (): Promise<void> => {
            await cancelOrderRequest(order.id);
            location.reload();
        });
    };

    const actualizeOrder = (): void => {
        setOrderChanges(new Map());
        setOrder(actualOrder as EditOrderData);
    };

    const overwriteOrder = async (): Promise<void> => {
        setOrderChanges(new Map());
        await updateOrder();
    };

    return (
        <div className="rounded border p-10 my-5">
            {orderChanges.size > 0 && actualOrder && (
                <OrderChangesFromDistributorModal
                    orderChanges={orderChanges}
                    closeModal={(): void => setOrderChanges(new Map())}
                    actualizeOrder={actualizeOrder}
                    overwriteOrder={overwriteOrder}
                    actualOrder={actualOrder}
                />
            )}

            {orderEditedBy1c && <OrderChangesFrom1cModal closeModal={(): void => location.replace(`/admin/orders`)} />}

            <h4 className="mb-5">Общие сведения</h4>
            <div className="rounded border p-6 mb-5">
                <div>
                    <span className="fw-bolder">Номер заказа: </span>
                    {` `}
                    {order.id || ``}
                </div>
                <div>
                    <span className="fw-bolder">Дата оформления: </span>
                    {` `}
                    {order.created_at}
                </div>
                <div>
                    <span className="fw-bolder">Статус заказа: </span>
                    {` `}
                    {readableOrderStatus(order.status)}
                </div>
                <div>
                    <OrderTypeInfo order={order} />
                </div>
            </div>

            <CreatorInfo />

            {!order.is_parent && <TargetGeneralInfo />}

            <div className="rounded border p-6 mb-5">
                <div className="mb-2">
                    <PaymentUrl order={order} />
                </div>
                <a className="d-block" href={`/admin/orders/${order.id}/invoice`} target="_blank">
                    Сгенерировать накладную
                </a>
            </div>

            <GeneralInfoDeliveryAndPaymentDetails />

            {order.is_editable && (
                <div className="d-flex justify-content-end">
                    <button className="btn btn-danger" onClick={cancelOrder} disabled={isLoading}>
                        Отменить заказ
                    </button>
                    <button className="btn btn-primary ms-5" onClick={checkOrderChangesAndUpdate} disabled={isLoading}>
                        Сохранить
                    </button>
                </div>
            )}
        </div>
    );
}
