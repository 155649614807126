import React from "react";
import debounce from "lodash-es/debounce";
import { SelectOption } from "@admin/utils/types";
import AsyncSelect from "react-select/async";
import { ProductCategoryModel } from "@admin/domain/productCategories/models/ProductCategoryModel";
import { title } from "@admin/utils/misc";
import { searchProductCategories as searchProductCategoriesRequest } from "@admin/domain/productCategories/api";

type Props = {
    label: string;
    categoryId: number | null;
    categoryTitle: string | null;
    categoryName: string;
    onChange?: (value: number | undefined, label: string | undefined) => void;
};

export default function CategoriesSearch({
    label,
    categoryTitle,
    categoryId,
    categoryName,
    onChange = () => null,
}: Props) {
    const searchCategory = debounce((query: string, setOptionsCallback: (options: SelectOption[]) => void): void => {
        searchProductCategoriesRequest(query)
            .then((categories: ProductCategoryModel[]): void => {
                setOptionsCallback(
                    categories.map(
                        (category: ProductCategoryModel): SelectOption => ({
                            value: category.id,
                            label: title(category.texts),
                        }),
                    ),
                );
            })
            .catch((): void => setOptionsCallback([]));

        return;
    }, 500);

    return (
        <div className="col">
            <label className="form-label">{label}</label>
            <AsyncSelect
                placeholder="Поиск категории"
                isClearable
                required
                name={categoryName}
                loadOptions={searchCategory}
                defaultValue={
                    categoryId
                        ? {
                              label: categoryTitle,
                              value: categoryId,
                          }
                        : undefined
                }
                onChange={(option): void => onChange(option?.value || 0, option?.label || ``)}
            />
        </div>
    );
}
