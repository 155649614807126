import React from "react";
import { EditOrderData } from "@admin/domain/orders/dto/EditOrderData";

export default function PaymentUrl({ order }: { order: EditOrderData }) {
    if (!order.is_editable) {
        return null;
    }

    return (
        <div>
            <span className="fw-bolder">Ссылка для оплаты: </span>
            <a className="link link-primary" href={order.payment_url} target="_blank">
                Ссылка
            </a>
        </div>
    );
}
