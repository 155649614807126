import React from "react";

import { type UserModel } from "@admin/domain/users/models/UserModel";
import { useOrderContext } from "@admin/pages/OrderEditor/OrderContext";
import { EditOrderData } from "@admin/domain/orders/dto/EditOrderData";
import BaseModal from "@admin/components/Modal";
import UsersSearch from "@admin/components/UsersSearch";

type Props = {
    closeModal: () => void;
};

export default function TargetUserModal({ closeModal }: Props) {
    const { order, setOrder } = useOrderContext();
    const [selectedUser, setSelectedUser] = React.useState<UserModel>(order.target);

    const submit = (): void => {
        if (!selectedUser) {
            alert(`Получатель заказа не выбран`);
            return;
        }

        setOrder(
            (prev: EditOrderData): EditOrderData => ({
                ...prev,
                target: selectedUser,
            }),
        );
        closeModal();
    };

    return (
        <BaseModal
            closeModal={closeModal}
            title="Выбор другого Дистрибьютора"
            subTitle={
                <span>
                    Сейчас выбран: {order.target.personal_number}
                    {`, `}
                    {order.target.full_name}
                </span>
            }
            body={<UsersSearch onSelectUser={setSelectedUser} />}
            footer={
                <>
                    <button className="btn btn-secondary" onClick={closeModal}>
                        Закрыть
                    </button>
                    <button className="btn btn-primary" onClick={submit}>
                        Подтвердить
                    </button>
                </>
            }
        />
    );
}
