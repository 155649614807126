import React, { useCallback, useEffect, useState } from "react";
import isFunction from "lodash/isFunction";
import Spinner from "react-bootstrap/Spinner";

import type { FileModel } from "@admin/domain/files/model/FileModel";
import { makePath } from "@admin/utils/makePath";
import { upload } from "@admin/domain/products/api";

type Props = {
    name: string | (() => string);
    value: FileModel | null | undefined;
    onChange: (file: FileModel | null) => void;
    disabled: boolean;
};

export default function File({ name, value, onChange, disabled }: Props) {
    const [pendingPreview, setPendingPreview] = useState<File | null>(null);
    const [pendingVideo, setPendingVideo] = useState<File | null>(null);
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const setValue = useCallback((value: FileModel | null) => {
        setPendingVideo(null);
        setPendingPreview(null);

        onChange(value);
    }, []);

    const uploadFile = useCallback(async (): Promise<void> => {
        if (!pendingPreview) {
            alert(`Загрузите превью`);

            return;
        }

        if (!pendingVideo) {
            alert(`Загрузите видео`);

            return;
        }

        try {
            setIsUploading(true);

            const result = await upload(pendingVideo, pendingPreview);

            setPendingVideo(null);
            setPendingPreview(null);

            onChange(result);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        } finally {
            setIsUploading(false);
        }
    }, [pendingPreview, pendingVideo]);

    const remove = useCallback(() => {
        if (confirm(`Вы действительно хотите удалить файл?`)) {
            setValue(null);
        }
    }, []);

    useEffect(() => {
        if (pendingPreview && pendingVideo) {
            // noinspection JSIgnoredPromiseFromCall
            uploadFile();
        }
    }, [pendingPreview, pendingVideo]);

    if (disabled) {
        return null;
    }

    if (value) {
        return (
            <div className="position-relative">
                <input type="hidden" name={isFunction(name) ? name() : `${name}[file_id]`} value={value.id} />

                {value.preview_file && (
                    <div className="mb-5">
                        <h4>Превью файл</h4>

                        <div>
                            <span className="fw-bolder">Название файла:</span>
                            {` `}
                            {value.preview_file.name}
                        </div>

                        <div>
                            <span className="fw-bolder">Формат файла:</span>
                            {` `}
                            {value.preview_file.mime}
                        </div>

                        <a href={makePath(value.preview_file.path)} target="_blank" className="fw-bolder">
                            Скачать превью
                        </a>
                    </div>
                )}

                <h4>Видео файл</h4>

                <div>
                    <span className="fw-bolder">Название файла:</span>
                    {` `}
                    {value.name}
                </div>

                <div>
                    <span className="fw-bolder">Формат файла:</span>
                    {` `}
                    {value.mime}
                </div>

                <a href={makePath(value.path)} target="_blank" className="fw-bolder">
                    Скачать видео
                </a>

                <div className="position-absolute top-0 end-0">
                    <i title="Удалить" className="fa fa-lg fa-trash text-danger cursor-pointer" onClick={remove} />
                </div>
            </div>
        );
    }

    return (
        <div className="position-relative">
            <div className="mb-5">
                <label className="form-label">Превью для видео файла</label>
                <input
                    type="file"
                    className="form-control"
                    name="file"
                    accept="image/*"
                    disabled={isUploading}
                    onChange={(event) => {
                        setPendingPreview(event.target.files?.item(0) as File);
                    }}
                />
            </div>

            <div className="mb-5">
                <label className="form-label">Видео файл</label>
                <input
                    type="file"
                    className="form-control"
                    name="file"
                    accept="video/*"
                    disabled={isUploading}
                    onChange={(event) => {
                        setPendingVideo(event.target.files?.item(0) as File);
                    }}
                />
            </div>

            {isUploading && (
                <div className="align-items-center bg-info bg-opacity-10 d-flex h-100 justify-content-center position-absolute start-0 top-0 w-100">
                    <Spinner variant="info" />
                </div>
            )}
        </div>
    );
}
