import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import type { ArticleContentBlockModel } from "@admin/domain/articles/models/ArticleContentBlockModel";
import type { ArticleBlockType } from "@admin/domain/articles/enums/ArticleBlockType";
import type { CheckerboardBannerModel } from "@admin/domain/content/models/ContentBlockValueModel";
import MultiLanguages from "@admin/components/MultiLanguage";
import CheckBox from "@admin/components/CheckBox";
import ImageInput from "@admin/components/ImageInput";
import QuillEditor from "@admin/components/QuillEditor";

type Props = {
    block: ArticleContentBlockModel & {
        type: ArticleBlockType.HorizontalImageWithTextCheckerboard | ArticleBlockType.VerticalImageWithTextCheckerboard;
    };
    index: number;
};

export default function ImageWithTextCheckerboardBlock({ block: { value }, index }: Props) {
    const initList = (value?.checkerboard_banners || []).map((value) => ({
        ...value,
        id: uuid(),
    }));

    const [list, setList] = useState<Array<CheckerboardBannerModel>>(initList);

    return (
        <>
            <CheckBox
                label="Левое расположение изображения"
                name={`blocks[${index}][value][is_right_image]`}
                defaultChecked={value?.is_right_image}
            />

            <MultiLanguages
                languageElement={(language) => (
                    <>
                        <div className="mb-5">
                            <label className="form-label">Заголовок</label>
                            <textarea
                                className="form-control"
                                name={`blocks[${index}][value][title][${language}]`}
                                defaultValue={value?.title?.[language] || ``}
                            />
                        </div>

                        <div className="mb-5">
                            <label className="form-label">Текст под заголовком</label>
                            <textarea
                                className="form-control"
                                name={`blocks[${index}][value][sub_title][${language}]`}
                                defaultValue={value?.sub_title?.[language] || ``}
                            />
                        </div>
                    </>
                )}
            />

            {list.map((item, itemIndex) => (
                <div key={item.id} className="rounded border p-4 my-8 mb-5">
                    <MultiLanguages
                        languageElement={(language) => (
                            <>
                                <div className="mb-5">
                                    <label className="form-label">Заголовок</label>
                                    <input
                                        className="form-control"
                                        name={`blocks[${index}][value][checkerboard_banners][${itemIndex}][title][${language}]`}
                                        defaultValue={item.title?.[language] || ``}
                                    />
                                </div>

                                <div className="mb-5">
                                    <QuillEditor
                                        label="Описание"
                                        name={`blocks[${index}][value][checkerboard_banners][${itemIndex}][description][${language}]`}
                                        initHtml={item.description?.[language] || ``}
                                    />
                                </div>

                                <div className="mb-5">
                                    <label className="form-label">Текст кнопки ссылки</label>
                                    <input
                                        className="form-control"
                                        name={`blocks[${index}][value][checkerboard_banners][${itemIndex}][button_text][${language}]`}
                                        defaultValue={item.button_text?.[language] || ``}
                                    />
                                </div>
                            </>
                        )}
                    />

                    <div className="mb-5">
                        <label className="form-label">Ссылка</label>
                        <input
                            className="form-control"
                            name={`blocks[${index}][value][checkerboard_banners][${itemIndex}][url]`}
                            defaultValue={item.url || ``}
                        />
                    </div>

                    <div className="col mb-5">
                        <ImageInput
                            label="Изображение"
                            name={`blocks[${index}][value][checkerboard_banners][${itemIndex}][file_id]`}
                            defaultValue={item.file}
                            width="300px"
                            height="300px"
                        />
                    </div>
                </div>
            ))}

            <div className="me-5 mb-5 d-flex">
                <button
                    type="button"
                    className="btn btn-success"
                    onClick={() =>
                        setList([
                            ...list,
                            {
                                id: uuid(),
                                title: {},
                                description: {},
                                button_text: {},
                                url: ``,
                                file: null,
                                product_id: null,
                                product_title: null,
                            },
                        ])
                    }
                >
                    Добавить изображение
                </button>
            </div>
        </>
    );
}
