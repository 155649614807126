import React, { type FormEvent } from "react";
import { EditOrderData } from "@admin/domain/orders/dto/EditOrderData";
import BaseModal from "@admin/components/Modal";
import { useLoaderContext } from "@admin/components/LoaderContext";
import Input from "@admin/components/Input";
import { applyVoucher as applyVoucherRequest } from "@admin/domain/orders/api";
import { useOrderHooks } from "@admin/pages/OrderEditor/hooks";

type Props = {
    closeModal: () => void;
    order: EditOrderData;
};

export default function ApplyVoucherModal({ closeModal, order }: Props) {
    const { request, isLoading } = useLoaderContext();
    const { actualizeOrder } = useOrderHooks();

    const applyVoucher = (event: FormEvent): void => {
        event.preventDefault();
        const body = new FormData(event.target as HTMLFormElement);

        request(async (): Promise<void> => {
            await applyVoucherRequest(order.id, body);
            await actualizeOrder();

            closeModal();
        });
    };

    return (
        <BaseModal
            closeModal={closeModal}
            title="Применить промокод или сертификат"
            body={
                <>
                    <form onSubmit={applyVoucher} id="apply_voucher_form">
                        <Input label={`Промокод или электронный сертификат`} disabled={isLoading} name="code" />
                    </form>
                </>
            }
            footer={
                <>
                    <button className="btn btn-secondary" onClick={closeModal} disabled={isLoading}>
                        Закрыть
                    </button>
                    <button className="btn btn-primary" disabled={isLoading} form="apply_voucher_form">
                        Применить
                    </button>
                </>
            }
        />
    );
}
