import React, { createRef, useState } from "react";
import isNumber from "lodash-es/isNumber";
import classNames, { type Argument } from "classnames";

import { type FileModel } from "@admin/domain/files/model/FileModel";
import { makePath } from "@admin/utils/makePath";
import { upload } from "@admin/domain/products/api";
import { useIsInvalid } from "@admin/components/FormErrorsContext";

type Props = {
    label: string;
    name: string;
    defaultValue: FileModel | null | undefined;
    width?: `${number}px` | `${number}%` | number;
    height?: `${number}px` | `${number}%` | number;
    thumbnailHeight?: `${number}px` | `${number}%` | number;
    thumbnailWidth?: `${number}px` | `${number}%` | number;
    onChange?: (image: FileModel | null) => void;
    className?: {
        container?: Argument;
        image?: Argument;
    };
} & Omit<
    React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
    `ref` | `width` | `height` | `onChange` | `defaultValue` | `className`
>;

export default function ImageInput({
    label,
    name,
    defaultValue,
    width,
    height,
    thumbnailHeight = 300,
    thumbnailWidth = 300,
    className,
    onChange,
    ...props
}: Props) {
    const [image, setImage] = useState<FileModel | null>(defaultValue || null);

    let imageClassName = className?.image;
    if (typeof imageClassName === `undefined`) {
        imageClassName = `mb-5 img-thumbnail `;
    }

    let containerClassName = className?.container;
    if (typeof containerClassName === `undefined`) {
        containerClassName = `p-4 my-8 mb-5 rounded border`;
    }

    const refFileInput = createRef<HTMLInputElement>();

    async function uploadImage(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
        event.preventDefault();

        const file = event.target.files?.item(0);

        if (!file) {
            return;
        }

        const image = await upload(file);

        setImage(image);
        onChange?.(image);
    }

    const remove = () => {
        if (confirm(`Вы действительно хотите удалить файл?`)) {
            setImage(null);
            onChange?.(null);
        }
    };

    const isInvalid = useIsInvalid(name);

    if (isNumber(width)) {
        width = `${width}px`;
    }

    if (isNumber(height)) {
        height = `${height}px`;
    }

    if (isNumber(thumbnailWidth)) {
        thumbnailWidth = `${thumbnailWidth}px`;
    }

    if (isNumber(thumbnailHeight)) {
        thumbnailHeight = `${thumbnailHeight}px`;
    }

    return (
        <>
            <input type="hidden" name={name} value={image?.id || ``} />

            <div
                className={classNames(
                    `position-relative`,
                    {
                        border: isInvalid,
                        "border-danger": isInvalid,
                    },
                    containerClassName,
                )}
            >
                {label && <h4 className="mb-5">{label}</h4>}

                {image ? (
                    <div className="position-relative">
                        <img
                            {...props}
                            src={makePath(image.path)}
                            style={{
                                ...props.style,
                                width,
                                height,
                                cursor: `pointer`,
                            }}
                            className={classNames(imageClassName)}
                            onClick={() => refFileInput.current?.click()}
                            alt=""
                        />

                        <div className="position-absolute top-0 end-0">
                            <i
                                title="Удалить"
                                className="fa fa-lg fa-trash text-danger cursor-pointer"
                                onClick={remove}
                            />
                        </div>
                    </div>
                ) : (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            width: thumbnailWidth,
                            height: thumbnailHeight,
                            cursor: `pointer`,
                        }}
                        className={classNames(`img_placeholder`, imageClassName)}
                        onClick={(): void => refFileInput.current?.click()}
                    />
                )}

                <input className="d-none" type="file" accept="image/*" onChange={uploadImage} ref={refFileInput} />
            </div>
        </>
    );
}
