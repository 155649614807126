import React from "react";

import { EditProductModel } from "@admin/domain/products/models/edit/EditProductModel";
import { updateProduct } from "@admin/domain/products/api";
import convertToMapLanguageObject from "@admin/domain/languages/Languages";
import Editor from "@admin/components/Editor";
import DocumentsInput from "@admin/components/DocumentsInput";
import ExpertOpinion from "./ProductExpertOpinion";
import ProductVideos from "./ProductVideos";
import CrossSale from "./CrossSale";
import ProductAttributes from "./ProductAttributes";
import ProductAdditionalAttributes from "./ProductAdditionalAttributes";
import ProductPrices from "./ProductPrices";
import ProductSpecifications from "./ProductSpecifications";
import PageSeoParamsEditor from "@admin/components/PageSeoParamsEditor";

function prepareProduct(product: EditProductModel): EditProductModel {
    product.product_additional_attributes.variations = product.product_additional_attributes.variations.map((value) => {
        value.textsMap = convertToMapLanguageObject(value.texts);
        return value;
    });

    product.specifications.ingredients = product.specifications.ingredients.map((value) => {
        value.textsMap = convertToMapLanguageObject(value.texts);
        return value;
    });

    product.specifications.active_components = product.specifications.active_components.map((value) => {
        value.textsMap = convertToMapLanguageObject(value.texts);
        return value;
    });

    if (product.expert_opinion) {
        product.expert_opinion.textsMap = convertToMapLanguageObject(product.expert_opinion.texts);
    }

    return product;
}

export default function ProductEditor() {
    const product = prepareProduct(window.CMS.product as EditProductModel);

    const execute = async (body: FormData) => {
        await updateProduct(product.product_additional_attributes.id, body);
    };

    return (
        <Editor backUrl={`/admin/products/${product.product_additional_attributes.id}`} onSubmit={execute}>
            <ProductAttributes productAttributes={product.product_attributes} />

            <ProductAdditionalAttributes productAdditionalAttributes={product.product_additional_attributes} />

            <ProductPrices productPrices={product.prices} />

            <ProductSpecifications specifications={product.specifications} />

            <ProductVideos defaultValue={product.product_videos} />

            <DocumentsInput
                label="Документы"
                name="documents[]"
                accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf, .odt"
                defaultValue={product.documents}
            />

            <DocumentsInput
                label="Промо материалы"
                name="promo_materials[]"
                accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf, .odt"
                defaultValue={product.promo_materials}
            />

            <ExpertOpinion productExpertOpinion={product.expert_opinion} />

            <CrossSale crossSales={product.cross_sale_products} />

            <PageSeoParamsEditor includePrice />
        </Editor>
    );
}
