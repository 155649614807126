import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import MultiLanguages from "@admin/components/MultiLanguage";
import type { HeadingWithListBlock } from "@admin/domain/content/models/ContentBlockValueModel";
import type { BlockProps } from "@admin/components/Content/ContentBlocks";
import HeadingInput from "@admin/components/Content/Blocks/HeadingInput";

const defaultValue: HeadingWithListBlock = {
    value: `h2`,
    title: {},
    list: [],
};

type Props = BlockProps<HeadingWithListBlock>;

export default function HeadingWithListBlock({ index, value = structuredClone(defaultValue) }: Props) {
    const initialValue = (value.list || []).map((value) => ({
        ...value,
        id: uuid(),
    }));

    const [list, setList] = useState<HeadingWithListBlock[`list`]>(initialValue);

    return (
        <>
            <HeadingInput name={`blocks[${index}][value]`} value={value.value} title={value.title} />

            <label className="form-label">Элементы списка</label>
            {list.map((item, itemIndex) => (
                <MultiLanguages
                    key={item.id}
                    languageElement={(language) => (
                        <div className="input-group mb-3">
                            <input
                                className="form-control"
                                name={`blocks[${index}][value][list][${itemIndex}][title][${language}]`}
                                defaultValue={item.title[language] || ``}
                            />

                            <button
                                type="button"
                                className="btn btn-outline btn-outline-danger"
                                onClick={() => {
                                    setList(list.filter((value) => value.id !== item.id));
                                }}
                            >
                                Удалить
                            </button>
                        </div>
                    )}
                />
            ))}

            <div className="mb-5 me-5 d-flex">
                <button
                    type="button"
                    className="btn btn-success"
                    onClick={() =>
                        setList([
                            ...list,
                            {
                                id: uuid(),
                                title: {},
                            },
                        ])
                    }
                >
                    Добавить элемент списка
                </button>
            </div>
        </>
    );
}
