import { post } from "../api";
import { ComplexPromotionModel } from "./models/ComplexPromotionModel";

const createComplexPromotion = async (body: FormData): Promise<ComplexPromotionModel> => {
    return await post({
        url: `/admin/complex_promotions`,
        body,
    });
};

const updateComplexPromotion = async (
    id: ComplexPromotionModel[`id`],
    body: FormData,
): Promise<ComplexPromotionModel> => {
    return await post({
        url: `/admin/complex_promotions/${id}`,
        body,
    });
};

export const saveComplexPromotion = async (
    promotion: ComplexPromotionModel | null,
    body: FormData,
): Promise<ComplexPromotionModel> => {
    return promotion ? updateComplexPromotion(promotion.id, body) : createComplexPromotion(body);
};
