import React from "react";
import { FooterBlockModel } from "@admin/domain/footer/Models/FooterBlockModel";

type Props = {
    block: FooterBlockModel;
    index: number;
    editBlock: (block: FooterBlockModel, index: number) => void;
    deleteBlock: (index: number) => void;
};

export default function FooterBlockItem({ block, index, editBlock, deleteBlock }: Props) {
    return (
        <div key={block.id} className="card rounded border mb-4">
            <div className="card-body">
                {block.id > 0 && <input type="hidden" name={`footer_blocks[${index}][id]`} value={block.id} />}

                <input type="hidden" name={`footer_blocks[${index}][title]`} value={block.title} />

                <h3 className="card-text">{block.title}</h3>
                {block.value.map((page, pageIndex) => (
                    <div key={page.id} className="card rounded border mb-4 p-3">
                        <p className="mb-0">{page.title}</p>

                        <input
                            type="hidden"
                            name={`footer_blocks[${index}][pages][${pageIndex}][id]`}
                            value={page.id}
                        />

                        <input
                            type="hidden"
                            name={`footer_blocks[${index}][pages][${pageIndex}][title]`}
                            value={page.title}
                        />

                        <input
                            type="hidden"
                            name={`footer_blocks[${index}][pages][${pageIndex}][url]`}
                            value={page.url}
                        />
                    </div>
                ))}

                <a className="card-link text-primary cursor-pointer" onClick={(): void => editBlock(block, index)}>
                    Редактировать
                </a>
                <a className="card-link text-danger cursor-pointer" onClick={(): void => deleteBlock(index)}>
                    Удалить
                </a>
            </div>
        </div>
    );
}
