import React from "react";
import { Alert } from "react-bootstrap";

import { saveTemplate } from "@admin/domain/seo/api";
import Editor from "@admin/components/Editor";
import SeoTextInput from "@admin/components/PageSeoParamsEditor/SeoTextInput";
import ImageInput from "@admin/components/PageSeoParamsEditor/ImageInput";
import TextArea from "@admin/components/TextArea";
import Input from "@admin/components/Input";

const LIST_URL = `/admin/seo/templates` as const;

export default function SeoParamsEditor() {
    const seo = window.CMS.seo || null;

    const execute = async (body: FormData) => {
        await saveTemplate(seo, body);
    };

    return (
        <Editor backUrl={LIST_URL} onSubmit={execute}>
            {seo && <Input label="ID" value={seo.id} disabled />}

            {!seo && (
                <>
                    <Alert variant="warning" dismissible className="mb-8">
                        <p className="mb-0">
                            SEO-параметры конкретных сущностей (продуктов, категорий, текстовых страниц и т.п.)
                            заполняются в их редакторе
                        </p>
                    </Alert>
                    <Input label="URL страницы" name="url" defaultValue="" />
                </>
            )}

            <SeoTextInput
                label="Title"
                name="title"
                defaultValue={seo?.title}
                fallback={seo?.fallback.title}
                includePrice={seo?.id === `products`}
            />

            <SeoTextInput
                label="Description"
                name="description"
                defaultValue={seo?.description}
                fallback={seo?.fallback.description}
                includePrice={seo?.id === `products`}
            />

            <ImageInput
                label="Изображение для соц. сетей"
                name="file_id"
                defaultValue={seo?.image}
                fallback={seo?.fallback.image}
            />

            <TextArea label="Head" name="head" defaultValue={seo?.head || ``} />

            <TextArea label="Body" name="body" defaultValue={seo?.body || ``} />
        </Editor>
    );
}
