import React from "react";

import type { BlockProps } from "@admin/components/Content/ContentBlocks";
import type { VideoBlockValueModel } from "@admin/domain/content/models/ContentBlockValueModel";
import MultiLanguages from "@admin/components/MultiLanguage";
import VideoInput from "@admin/components/VideoInput";

type Props = BlockProps<VideoBlockValueModel>;

const defaultValue: VideoBlockValueModel = {
    title: {},
    description: {},
    url: null,
    file: null,
};

export default function VideoBlock({ index, value = structuredClone(defaultValue) }: Props) {
    return (
        <>
            <MultiLanguages
                languageElement={(language) => (
                    <>
                        <div className="mb-5">
                            <label className="form-label">Заголовок</label>
                            <input
                                className="form-control"
                                name={`blocks[${index}][value][title][${language}]`}
                                defaultValue={value.title[language] || ``}
                            />
                        </div>

                        <div className="mb-5">
                            <label className="form-label">Описание</label>
                            <textarea
                                className="form-control"
                                name={`blocks[${index}][value][description][${language}]`}
                                defaultValue={value.description[language] || ``}
                            />
                        </div>
                    </>
                )}
            />

            <VideoInput
                name={`blocks[${index}][value]`}
                defaultValue={{
                    file: value.file,
                    url: value.url,
                }}
            />
        </>
    );
}
