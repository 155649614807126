import { post } from "../api";
import { type Chapter } from "./models/Chapter";

async function createChapter(body: FormData): Promise<Chapter> {
    return await post({
        url: `/admin/chapters`,
        body: body,
    });
}

async function updateChapter(id: Chapter[`id`], body: FormData): Promise<Chapter> {
    return await post({
        url: `/admin/chapters/${id}`,
        body: body,
    });
}

export async function saveChapter(chapter: Chapter | null, body: FormData): Promise<Chapter> {
    return chapter ? updateChapter(chapter.id, body) : createChapter(body);
}
