import React, { useState } from "react";

import BreadCrumbs from "@admin/components/BreadCrumbs";
import { Override } from "@admin/utils/overrideType";
import { DeliveryMethodGeneralConditionModel } from "@admin/domain/deliveryMethods/models/DeliveryMethodGeneralConditionModel";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import DeliveryMethodGeneralConditionModal from "./DeliveryMethodGeneralConditionModal";
import { storeOrUpdateDeliveryMethodGeneralConditions } from "@admin/domain/deliveryMethods/api";
import Sortable from "@admin/components/Sortable";
import Editor from "@admin/components/Editor";

export type GeneralCondition = Override<DeliveryMethodGeneralConditionModel, { id: string | number }>;

export default function DeliveryMethodGeneralConditionsEditor() {
    const currentLanguage = useLanguageContext().currentLanguage;

    const [showModal, setShowModal] = useState(false);

    const [generalConditions, setGeneralConditions] = useState<GeneralCondition[]>(
        window.CMS.deliveryMethodGeneralConditions || [],
    );
    const [editableGeneralCondition, setEditableGeneralCondition] = useState<GeneralCondition | null>(null);

    const addGeneralCondition = (data: GeneralCondition) => setGeneralConditions((prev) => [...prev, data]);

    const editGeneralCondition = (data: GeneralCondition) => {
        setGeneralConditions((prev) =>
            prev.map((value) => {
                if (value.id === data.id) value = data;
                return value;
            }),
        );
        setEditableGeneralCondition(null);
    };

    const execute = async (body: FormData) => {
        await storeOrUpdateDeliveryMethodGeneralConditions(body);
    };

    return (
        <>
            <BreadCrumbs
                className="mb-5"
                breadCrumbs={[
                    {
                        title: `Способы доставки`,
                        url: `/admin/delivery_methods/menu`,
                    },
                    {
                        title: `Общие настройки доставки`,
                        url: `/admin/delivery_methods/settings`,
                    },
                    { title: `Общие условия` },
                ]}
            />

            <Editor onSubmit={execute}>
                <Sortable list={generalConditions} setList={setGeneralConditions}>
                    {generalConditions.map((generalCondition, index) => (
                        <div key={generalCondition.id} className="card rounded border mb-4">
                            <div className="card-body">
                                {typeof generalCondition.id === `number` && (
                                    <input
                                        type="hidden"
                                        name={`delivery_conditions[${index}][id]`}
                                        value={generalCondition.id}
                                    />
                                )}

                                <p className="card-text">{generalCondition.texts[currentLanguage]?.text}</p>

                                {Object.entries(generalCondition.texts).map(([language, value]) => (
                                    <input
                                        key={language}
                                        type="hidden"
                                        name={`delivery_conditions[${index}][text][${language}]`}
                                        value={value.text}
                                    />
                                ))}

                                <a
                                    className="card-link text-primary cursor-pointer"
                                    onClick={() => {
                                        setEditableGeneralCondition(generalCondition);
                                        setShowModal(true);
                                    }}
                                >
                                    Редактировать
                                </a>
                                <a
                                    className="card-link text-danger cursor-pointer"
                                    onClick={() =>
                                        setGeneralConditions((prev) =>
                                            prev.filter((value) => value.id !== generalCondition.id),
                                        )
                                    }
                                >
                                    Удалить
                                </a>
                            </div>
                        </div>
                    ))}
                </Sortable>

                <div className="d-flex justify-content-end mb-5">
                    <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                            setEditableGeneralCondition(null);
                            setShowModal(true);
                        }}
                    >
                        Добавить условие
                    </button>
                </div>
            </Editor>

            <DeliveryMethodGeneralConditionModal
                showModal={showModal}
                closeModal={() => setShowModal(false)}
                onSubmit={editableGeneralCondition ? editGeneralCondition : addGeneralCondition}
                editableGeneralCondition={editableGeneralCondition}
            />
        </>
    );
}
