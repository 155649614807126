import React, { useState } from "react";
import Input from "@admin/components/Input";
import isNumber from "lodash-es/isNumber";
import { v4 as uuid } from "uuid";
import { DeliveryMethodBasketAmountConditionModel } from "@admin/domain/deliveryMethods/models/DeliveryMethodModel";

type Condition = Partial<DeliveryMethodBasketAmountConditionModel>;

export default function Conditions() {
    const deliveryMethod = window.CMS.deliveryMethod;

    if (!deliveryMethod) {
        throw Error(`Delivery method undefined`);
    }

    const initConditions = (deliveryMethod.basket_amount_conditions || []).map((value) => ({ ...value, id: uuid() }));

    const [deliveryMethodConditions, setDeliveryMethodConditions] = useState<Condition[]>(initConditions);

    return (
        <div className="rounded border p-10 my-5">
            <h3>Условия</h3>
            {deliveryMethodConditions?.map((condition, index) => (
                <div key={index} className="rounded border p-10 my-5">
                    <input type="hidden" name={`basket_amount_conditions[${index}][id]`} value={condition?.id} />
                    <h4>Минимальное значение стоимости корзины</h4>
                    <div className="row mb-10">
                        <Input
                            label="RUB (в р.)"
                            name={`basket_amount_conditions[${index}][min_basket_amount][rub]`}
                            defaultValue={
                                isNumber(condition?.min_basket_amount?.rub)
                                    ? (condition?.min_basket_amount?.rub || 0) / 100
                                    : ``
                            }
                        />
                        <Input
                            label="BYN"
                            name={`basket_amount_conditions[${index}][min_basket_amount][byn]`}
                            defaultValue={
                                isNumber(condition?.min_basket_amount?.byn)
                                    ? (condition?.min_basket_amount?.byn || 0) / 100
                                    : ``
                            }
                        />
                        <Input
                            label="KZT"
                            name={`basket_amount_conditions[${index}][min_basket_amount][kzt]`}
                            defaultValue={
                                isNumber(condition?.min_basket_amount?.kzt)
                                    ? (condition?.min_basket_amount?.kzt || 0) / 100
                                    : ``
                            }
                        />
                    </div>
                    <h4>Максимальный значение стоимости корзины</h4>
                    <div className="row mb-5">
                        <Input
                            label="RUB (в р.)"
                            name={`basket_amount_conditions[${index}][max_basket_amount][rub]`}
                            defaultValue={
                                isNumber(condition?.max_basket_amount?.rub)
                                    ? (condition?.max_basket_amount?.rub || 0) / 100
                                    : ``
                            }
                        />
                        <Input
                            label="BYN"
                            name={`basket_amount_conditions[${index}][max_basket_amount][byn]`}
                            defaultValue={
                                isNumber(condition?.max_basket_amount?.byn)
                                    ? (condition?.max_basket_amount?.byn || 0) / 100
                                    : ``
                            }
                        />
                        <Input
                            label="KZT"
                            name={`basket_amount_conditions[${index}][max_basket_amount][kzt]`}
                            defaultValue={
                                isNumber(condition?.max_basket_amount?.kzt)
                                    ? (condition?.max_basket_amount?.kzt || 0) / 100
                                    : ``
                            }
                        />
                    </div>
                    <h4>Значение стоимости доставки</h4>
                    <div className="row">
                        <Input
                            label="RUB (в р.)"
                            name={`basket_amount_conditions[${index}][delivery_cost][rub]`}
                            defaultValue={
                                isNumber(condition?.delivery_cost?.rub)
                                    ? (condition?.delivery_cost?.rub || 0) / 100
                                    : ``
                            }
                        />
                        <Input
                            label="BYN"
                            name={`basket_amount_conditions[${index}][delivery_cost][byn]`}
                            defaultValue={
                                isNumber(condition?.delivery_cost?.byn)
                                    ? (condition?.delivery_cost?.byn || 0) / 100
                                    : ``
                            }
                        />
                        <Input
                            label="KZT"
                            name={`basket_amount_conditions[${index}][delivery_cost][kzt]`}
                            defaultValue={
                                isNumber(condition?.delivery_cost?.kzt)
                                    ? (condition?.delivery_cost?.kzt || 0) / 100
                                    : ``
                            }
                        />
                    </div>
                    <a
                        className="card-link text-danger"
                        onClick={(event) => {
                            event.preventDefault();
                            setDeliveryMethodConditions((prev) => prev.filter((_, prevIndex) => prevIndex !== index));
                        }}
                    >
                        Удалить
                    </a>
                </div>
            ))}
            <div className="d-flex justify-content-end mb-5">
                <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => {
                        setDeliveryMethodConditions((prevState) => [
                            ...prevState,
                            {
                                id: uuid(),
                            },
                        ]);
                    }}
                >
                    Добавить условие
                </button>
            </div>
        </div>
    );
}
