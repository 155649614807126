import React from "react";
import { OrderProductModel } from "@admin/domain/orders/models/OrderProductModel";
import { EditOrderData } from "@admin/domain/orders/dto/EditOrderData";
import formatMoney from "@admin/domain/orders/formatMoney";

type Props = {
    order: EditOrderData;
};

export default function Totals({ order }: Props) {
    return (
        <div>
            <h4 className="mb-5">Итоговая стоимость заказа</h4>
            <div className="mb-5">
                <span className="fw-bolder">Общее кол-во товаров: </span>
                <span>
                    {order.products.reduce(
                        (total: number, orderProduct: OrderProductModel): number => total + orderProduct.quantity,
                        0,
                    )}
                </span>
            </div>
            {order.is_child && order.creator_discount > 0 && (
                <div className="mb-5">
                    <span className="fw-bolder">Скидка консолидатора: </span>
                    <span>{formatMoney(order, `creator_discount`)}</span>
                </div>
            )}
            {order.is_child && order.target_discount > 0 && (
                <div className="mb-5">
                    <span className="fw-bolder">Скидка получателя: </span>
                    <span>{formatMoney(order, `target_discount`)}</span>
                </div>
            )}
            {!order.is_child && order.target_discount + order.creator_discount > 0 && (
                <div className="mb-5">
                    <span className="fw-bolder">Скидка дистрибьютора:{` `}</span>
                    <span>
                        {(order.target_discount + order.creator_discount) / 100}
                        {` `}
                        {order.currency_symbol}
                    </span>
                </div>
            )}
            {order.promo_code_discount > 0 && (
                <div className="mb-5">
                    <span className="fw-bolder">Скидка по промокоду: </span>
                    <span>{formatMoney(order, `promo_code_discount`)}</span>
                </div>
            )}
            {order.simple_promotion_discount > 0 && (
                <div className="mb-5">
                    <span className="fw-bolder">Скидка по простым акциям:{` `}</span>
                    <span>{formatMoney(order, `simple_promotion_discount`)}</span>
                </div>
            )}
            {order.complex_promotion_discount > 0 && (
                <div className="mb-5">
                    <span className="fw-bolder">Скидка по сложным акциям:{` `}</span>
                    <span>{formatMoney(order, `complex_promotion_discount`)}</span>
                </div>
            )}
            {order.electronic_gift_certificate_discount > 0 && (
                <div className="mb-5">
                    <span className="fw-bolder">Сертификат: </span>
                    <span>{formatMoney(order, `electronic_gift_certificate_discount`)}</span>
                </div>
            )}
            {!order.is_child && (
                <div className="mb-5">
                    <span className="fw-bolder">Доставка: </span>
                    <span>{order.delivery_cost > 0 ? formatMoney(order, `delivery_cost`) : `бесплатно`}</span>
                </div>
            )}
            <div className="mb-5">
                <span className="fw-bolder">Итоговая стоимость заказа: </span>
                <span>{formatMoney(order, `total_amount`)}</span>
            </div>
            <div className="mb-5">
                <span className="fw-bolder">Баллы: </span>
                <span>{order.total_points / 100}</span>
            </div>
        </div>
    );
}
