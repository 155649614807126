import { del, post } from "../api";
import { DeliveryMethodGroupModel } from "@admin/domain/deliveryMethods/models/DeliveryMethodGroupModel";

export const storeOrUpdateDeliveryMethodNotifications = async (body: FormData): Promise<number> => {
    return await post({
        url: `/admin/delivery_method_notifications/store_or_update`,
        body,
    });
};

export const deleteAllDeliveryMethodNotifications = async (): Promise<number> => {
    return await del({
        url: `/admin/delivery_method_notifications/delete_all`,
    });
};

export const storeOrUpdateDeliveryMethodGeneralConditions = async (body: FormData): Promise<number> => {
    return await post({
        url: `/admin/delivery_method_general_conditions/store_or_update`,
        body,
    });
};

export const updateDeliveryMethod = async (id: number, body: FormData): Promise<number> => {
    return await post({
        url: `/admin/delivery_methods/${id}/update`,
        body,
    });
};

export const createDeliveryMethodGroup = async (body: FormData): Promise<{ id: number }> => {
    return await post({
        url: `/admin/delivery_method_groups`,
        body,
    });
};

export const updateDeliveryMethodGroup = async (
    id: DeliveryMethodGroupModel[`id`],
    body: FormData,
): Promise<{ id: number }> => {
    return await post({
        url: `/admin/delivery_method_groups/${id}/update`,
        body,
    });
};

export const updateDeliveryMethodRegionConfig = async (configId: number, body: FormData): Promise<null> => {
    return await post({
        url: `/admin/delivery_method_region_configs/${configId}`,
        body,
    });
};
