import { LocalityModel } from "./models/LocalityModel";
import { request } from "../api";

export const searchLocalities = (query: string, countryId: number): Promise<LocalityModel[]> => {
    return request({
        url: `/admin/localities/search`,
        query: {
            query: query,
            country_id: countryId,
        },
    });
};
