import React, { FormEvent } from "react";
import BaseModal from "@admin/components/Modal";
import Input from "@admin/components/Input";
import { useOrderContext } from "@admin/pages/OrderEditor/OrderContext";
import DateTimeInput from "@admin/components/DateTimeInput";
import { useOrderHooks } from "@admin/pages/OrderEditor/hooks";
import { registerCustomPaymentInAccounting } from "@admin/domain/orders/api";

type Props = {
    orderId: number;
    closeModal: () => void;
};

export default function RegisterCustomPaymentInAccountingModal({ orderId, closeModal }: Props) {
    const { paymentMethods } = useOrderContext();
    const { orderRequest } = useOrderHooks();

    const register = async (event: FormEvent): Promise<void> => {
        event.preventDefault();

        orderRequest(async (): Promise<void> => {
            await registerCustomPaymentInAccounting(orderId, new FormData(event.target as HTMLFormElement));
            closeModal();
        });
    };

    return (
        <BaseModal
            closeModal={closeModal}
            title="Ручное формирование платежа"
            body={
                <form onSubmit={register} id="custom_payment_form">
                    <Input label="Внешний ID заказа" name="external_order_id" />

                    <div className="mb-5">
                        <h4>Способ оплаты</h4>
                        <select className="form-control" name="payment_method_id">
                            {[...paymentMethods].map(([, method]) => (
                                <option key={method.id} value={method.id}>
                                    {method.title}
                                </option>
                            ))}
                        </select>
                    </div>

                    <DateTimeInput label="Дата и время заказа" name="paid_at" />
                </form>
            }
            footer={
                <>
                    <button type="button" className="btn btn-secondary" onClick={closeModal}>
                        Закрыть
                    </button>
                    <button type="submit" className="btn btn-primary" form="custom_payment_form">
                        Подтвердить
                    </button>
                </>
            }
        />
    );
}
