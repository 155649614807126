import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import { DropDownElementValueModel, TextListModel } from "@admin/domain/articles/models/ArticleBlockValueModel";
import { DropDownElementsBlockType } from "@admin/domain/articles/enums/DropDownElementsBlockType";
import MultiLanguages from "@admin/components/MultiLanguage";

type List = Partial<TextListModel>;

type Props = {
    list: DropDownElementValueModel;
    blockIndex: number;
    index: number;
};

export default function DropDownElementsList(props: Props) {
    const initList = (props?.list?.values || []).map((value) => ({
        ...value,
        id: uuid(),
    }));
    const [currentType, setCurrentType] = useState(props?.list?.element_type || DropDownElementsBlockType.Text);
    const onChangeType = (type: DropDownElementsBlockType) => {
        setCurrentType(type);
    };
    const [list, setEl] = useState<List[]>(initList);

    return (
        <>
            <div className="mb-7">
                <label className="form-label">Тип</label>

                <select
                    className="form-control"
                    name={`blocks[${props.blockIndex}][value][dropdown_elements][${props.index}][element_type]`}
                    onChange={(e) => onChangeType(e.target.value as DropDownElementsBlockType)}
                    value={currentType}
                >
                    <option value={DropDownElementsBlockType.OrderedList}>Нумерованный список</option>
                    <option value={DropDownElementsBlockType.UnOrderedList}>Маркированный список</option>
                    <option value={DropDownElementsBlockType.Text}>Текст</option>
                </select>
            </div>

            {currentType === DropDownElementsBlockType.Text && (
                <div>
                    <MultiLanguages
                        languageElement={(language) => (
                            <div>
                                <div className="mb-5">
                                    <label className="form-label">Текст</label>
                                    <input
                                        className="form-control"
                                        name={`blocks[${props?.blockIndex}][value][dropdown_elements][${props?.index}][text][${language}]`}
                                        defaultValue={props?.list?.text?.[language] || ``}
                                    />
                                </div>
                            </div>
                        )}
                    />
                </div>
            )}

            {(currentType === DropDownElementsBlockType.OrderedList ||
                currentType === DropDownElementsBlockType.UnOrderedList) && (
                <div>
                    <div className="row row-cols-auto">
                        {list?.map((ol, index) => (
                            <div key={ol?.id} className="col mb-5">
                                <MultiLanguages
                                    languageElement={(language) => (
                                        <div className="mb-5">
                                            <label className="form-label">Элемент списка</label>
                                            <input
                                                className="form-control"
                                                name={`blocks[${props?.blockIndex}][value][dropdown_elements][${props.index}][values][${index}][title][${language}]`}
                                                defaultValue={ol?.title?.[language] || ``}
                                            />
                                        </div>
                                    )}
                                />

                                <div>
                                    <a
                                        className="card-link text-danger"
                                        onClick={() => {
                                            setEl((prev) => prev.filter((value) => value?.id !== ol?.id));
                                        }}
                                    >
                                        Удалить
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="mb-5 d-flex">
                        <div className="me-5">
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={() =>
                                    setEl((elState) => [
                                        ...elState,
                                        {
                                            id: uuid(),
                                            title: undefined,
                                        },
                                    ])
                                }
                            >
                                Добавить элемент списка
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
