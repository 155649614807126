import React, { type ChangeEvent, useState } from "react";

import {
    AccessType,
    ApplicationLimitType,
    ApplicationType,
    DiscountType,
} from "@admin/domain/promoCodes/models/PromoCodeModel";
import Input from "@admin/components/Input";
import TextArea from "@admin/components/TextArea";
import DateTimeInput from "@admin/components/DateTimeInput";
import Select from "@admin/components/Select";
import CheckBox from "@admin/components/CheckBox";
import ProductCategoriesSearch from "@admin/components/ProductCategoriesSearch";

const PromoCodeFields: React.FC = () => {
    const promoCode = window.CMS?.promoCode || null;
    const userPersonalNumbers = promoCode?.user_personal_numbers?.join(`,`);
    const productIds = promoCode?.product_ids?.join(`,`);
    const unsuitableProductIds = promoCode?.unsuitable_product_ids?.join(`,`);
    const [config, setConfig] = useState({
        discount_type: promoCode?.discount_type ? promoCode?.discount_type : DiscountType.FixedAmount,
        access_type: promoCode?.access_type ? promoCode?.access_type : ``,
        application_type: promoCode?.application_type ? promoCode?.application_type : ``,
        application_limit_type: promoCode?.application_limit_type ? promoCode?.application_limit_type : ``,
    });

    const onConfigChange = (event: ChangeEvent<HTMLSelectElement>) => {
        // TODO: Некоторые поля зависят от других и их значения нужно сбрасывать при изменениях "родителей"
        setConfig({
            ...config,
            [event.target.name]: event.target.value,
        });
    };

    return (
        <>
            <Input label="Код" defaultValue={promoCode?.code} name="code" disabled={Boolean(promoCode)} />

            <Select
                label="Тип лимита применений"
                defaultValue={promoCode?.application_limit_type}
                onChange={onConfigChange}
                options={{
                    [ApplicationLimitType.Unlimited]: `Безлимитный`,
                    [ApplicationLimitType.Limited]: `Ограниченный`,
                }}
                name="application_limit_type"
                required
            />

            <Input
                label="Общий лимит использования"
                defaultValue={promoCode?.total_usage_limit}
                name="total_usage_limit"
                disabled={config.application_limit_type !== ApplicationLimitType.Limited}
            />

            <Input
                label="Лимит на одного пользователя"
                defaultValue={promoCode?.max_uses_count}
                name="max_uses_count"
            />

            <TextArea label="Описание" defaultValue={promoCode?.description} name="description" />

            <Select
                label="Тип доступности"
                defaultValue={promoCode?.access_type}
                onChange={onConfigChange}
                options={{
                    [AccessType.All]: `Глобальный для всех пользователей`,
                    [AccessType.Auth]: `Глобальный для авторизованных пользователей`,
                    [AccessType.Individual]: `Индивидуальный`,
                }}
                name="access_type"
                required
            />

            <Input
                label="Номера Дистрибьюторов"
                defaultValue={userPersonalNumbers}
                name="user_personal_numbers"
                disabled={config.access_type !== AccessType.Individual}
            />

            <Select
                label="Тип скидки"
                defaultValue={promoCode?.discount_type}
                onChange={onConfigChange}
                options={{
                    [DiscountType.FixedAmount]: `В валюте`,
                    [DiscountType.Percentage]: `В процентах`,
                }}
                name="discount_type"
                required
            />

            <Input
                label={`Значение скидки (${config.discount_type === DiscountType.Percentage ? `в %` : `в р.`})`}
                defaultValue={
                    promoCode?.amount && promoCode.discount_type === DiscountType.FixedAmount
                        ? promoCode.amount / 100
                        : promoCode?.amount
                }
                name="amount"
            />

            <Select
                label="Тип области применения"
                defaultValue={promoCode?.application_type}
                onChange={onConfigChange}
                options={
                    ((config.discount_type === DiscountType.FixedAmount && {
                        [ApplicationType.Amount]: `На сумму корзины`,
                        [ApplicationType.Products]: `На определённые товары`,
                    }) ||
                        (config.discount_type === DiscountType.Percentage && {
                            [ApplicationType.Products]: `На определённые товары`,
                            [ApplicationType.Categories]: `На категорию товаров`,
                        }) ||
                        {}) as Record<string | number, string>
                }
                name="application_type"
                required
            />

            {config.application_type === ApplicationType.Products && (
                <>
                    <Input label="Товары" defaultValue={productIds} name="product_ids" />

                    <Input
                        label="Исключения (Товары)"
                        defaultValue={unsuitableProductIds}
                        name="unsuitable_product_ids"
                    />
                </>
            )}

            {config.application_type === ApplicationType.Categories && (
                <>
                    <ProductCategoriesSearch
                        inputName="product_category_ids[]"
                        categoryIds={promoCode?.product_category_ids || []}
                        label="Категории"
                    />

                    <ProductCategoriesSearch
                        inputName="unsuitable_product_category_ids[]"
                        categoryIds={promoCode?.unsuitable_product_category_ids || []}
                        label="Исключения (категории)"
                    />
                </>
            )}

            <DateTimeInput label="Срок действия" defaultValue={promoCode?.ended_at || ``} name="ended_at" />

            <CheckBox
                name="free_shipping"
                label="Разрешить бесплатную доставку"
                defaultChecked={Boolean(promoCode?.free_shipping)}
            />

            <CheckBox
                name="disable_promotional_products"
                label="Исключить товары по акциям"
                defaultChecked={Boolean(promoCode?.disable_promotional_products)}
            />

            <CheckBox name="is_visible" label="Активен" defaultChecked={Boolean(promoCode?.is_visible)} />
        </>
    );
};

export default PromoCodeFields;
