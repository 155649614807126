import React from "react";
import { ProductAdditionalAttributesModel } from "@admin/domain/products/models/edit/ProductAdditionalAttributesModel";
import Input from "@admin/components/Input";
import ProductVariation from "./ProductVariation";

export default function ProductAdditionalAttributes({
    productAdditionalAttributes,
}: {
    productAdditionalAttributes: ProductAdditionalAttributesModel;
}) {
    return (
        <div className="rounded border p-10 mb-5">
            <h2>Дополнительные атрибуты товара</h2>

            <Input
                label="ID товара"
                name="additional_product_attributes[id]"
                defaultValue={productAdditionalAttributes.id}
                disabled
            />

            <Input
                label="Артикул товара"
                name="additional_product_attributes[sku]"
                defaultValue={productAdditionalAttributes.sku}
            />

            <Input
                label="ID характеристики товара в 1С"
                name="additional_product_attributes[characteristic_id]"
                defaultValue={productAdditionalAttributes.characteristic_id}
            />

            <Input
                label="Характеристика товара в 1С"
                name="additional_product_attributes[characteristic_name]"
                defaultValue={productAdditionalAttributes.characteristic_name}
            />

            <ProductVariation productVariations={productAdditionalAttributes.variations} />
        </div>
    );
}
