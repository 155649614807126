import Input from "@admin/components/Input";
import { Language } from "@admin/domain/languages/Languages";
import { ProductExpertOpinionTextModel } from "@admin/domain/products/models/edit/ProductExpertOpinionModel";
import React from "react";

type TextsProps = {
    language: Language;
    texts?: Map<Language, ProductExpertOpinionTextModel>;
};

export default function Text(props: TextsProps) {
    return (
        <>
            <input type="hidden" name={`expert[texts][${props.language}][language]`} defaultValue={props.language} />

            <Input
                label="ФИО эксперта"
                name={`expert[texts][${props.language}][full_name]`}
                defaultValue={props.texts?.get(props.language)?.full_name || ``}
            />
            <label className="form-label">Должность/Заслуги эксперта</label>
            <textarea
                className="form-control"
                name={`expert[texts][${props.language}][achievement]`}
                defaultValue={props.texts?.get(props.language)?.achievement || ``}
            />
            <label className="form-label">Текст мнения эксперта</label>
            <textarea
                className="form-control"
                name={`expert[texts][${props.language}][text]`}
                defaultValue={props.texts?.get(props.language)?.text || ``}
            />
        </>
    );
}
