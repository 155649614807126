import React from "react";

import type { BlockProps } from "@admin/components/Content/ContentBlocks";
import type { NewsSubscribesBlockValueModel } from "@admin/domain/content/models/ContentBlockValueModel";
import MultiLanguages from "@admin/components/MultiLanguage";

const defaultValue: NewsSubscribesBlockValueModel = {
    title: {},
    text: {},
    button_text: {},
    result_title: {},
    result_text: {},
};

type Props = BlockProps<NewsSubscribesBlockValueModel>;

export default function NewsSubscribesBlock({ index, value = structuredClone(defaultValue) }: Props) {
    return (
        <MultiLanguages
            languageElement={(language) => (
                <>
                    <div className="mb-5">
                        <label className="form-label">Заголовок</label>
                        <textarea
                            className="form-control"
                            name={`blocks[${index}][value][title][${language}]`}
                            defaultValue={value.title[language] || ``}
                        />
                    </div>

                    <div className="mb-5">
                        <label className="form-label">Текст</label>
                        <textarea
                            className="form-control"
                            name={`blocks[${index}][value][text][${language}]`}
                            defaultValue={value.text[language] || ``}
                        />
                    </div>

                    <div className="mb-5">
                        <label className="form-label">Текст кнопки</label>
                        <textarea
                            className="form-control"
                            name={`blocks[${index}][value][button_text][${language}]`}
                            defaultValue={value.button_text[language] || ``}
                        />
                    </div>

                    <div className="mb-5">
                        <label className="form-label">Заголовок после подписки</label>
                        <textarea
                            className="form-control"
                            name={`blocks[${index}][value][result_title][${language}]`}
                            defaultValue={value.result_title[language] || ``}
                        />
                    </div>

                    <div className="mb-5">
                        <label className="form-label">Текст после подписки</label>
                        <textarea
                            className="form-control"
                            name={`blocks[${index}][value][result_text][${language}]`}
                            defaultValue={value.result_text[language] || ``}
                        />
                    </div>
                </>
            )}
        />
    );
}
