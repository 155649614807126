import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import type { BlockProps } from "@admin/components/Content/ContentBlocks";
import type { FaqBlockValueModel } from "@admin/domain/content/models/ContentBlockValueModel";
import MultiLanguages from "../../MultiLanguage";

const defaultValue: FaqBlockValueModel = {
    title: {},
    faq_banners: [],
};

type Props = BlockProps<FaqBlockValueModel>;

export default function FaqBlock({ index, value = structuredClone(defaultValue) }: Props) {
    const initBanners = (value.faq_banners || []).map((item) => ({
        id: uuid(),
        ...item,
    }));

    const [banners, setBanners] = useState(initBanners);

    return (
        <>
            <MultiLanguages
                languageElement={(language) => (
                    <div className="mb-5">
                        <label className="form-label">Заголовок</label>
                        <input
                            className="form-control"
                            name={`blocks[${index}][value][title][${language}]`}
                            defaultValue={value.title?.[language] || ``}
                        />
                    </div>
                )}
            />

            {banners.map((banner, bannerIndex) => (
                <div key={banner.id} className="mb-4">
                    <MultiLanguages
                        languageElement={(language) => (
                            <>
                                <div className="mb-2">
                                    <label className="form-label">Вопрос</label>
                                    <textarea
                                        name={`blocks[${index}][value][faq_banners][${bannerIndex}][question][${language}]`}
                                        defaultValue={banner?.question?.[language] || ``}
                                        className="form-control"
                                        rows={5}
                                        cols={50}
                                    />
                                </div>

                                <div className="mb-2">
                                    <label className="form-label">Ответ</label>
                                    <textarea
                                        name={`blocks[${index}][value][faq_banners][${bannerIndex}][answer][${language}]`}
                                        defaultValue={banner?.answer?.[language] || ``}
                                        className="form-control"
                                        rows={5}
                                        cols={50}
                                    />
                                </div>
                            </>
                        )}
                    />

                    <a
                        className="card-link text-danger"
                        onClick={() => {
                            setBanners(banners.filter((item) => item.id !== banner.id));
                        }}
                    >
                        Удалить
                    </a>
                </div>
            ))}

            <div className="mb-5 me-5 d-flex">
                <button
                    type="button"
                    className="btn btn-success"
                    onClick={() =>
                        setBanners([
                            ...banners,
                            {
                                id: uuid(),
                                question: {},
                                answer: {},
                            },
                        ])
                    }
                >
                    Добавить
                </button>
            </div>
        </>
    );
}
