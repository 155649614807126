import React, { useMemo, useState } from "react";
import MultiLanguages from "@admin/components/MultiLanguage";
import Input from "@admin/components/Input";
import TextArea from "@admin/components/TextArea";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { AboutPageFactoryRulandStepsModel, AboutPageModel } from "@admin/domain/uniquePages/models/AboutPageModel";
import { initLanguageKeyObject } from "@admin/domain/languages/Languages";
import { type UniquePageProps } from "../index";
import ImageInput from "@admin/components/ImageInput";

export default function AboutPageFactoryRuland({ page }: UniquePageProps<AboutPageModel>) {
    const [tab, setTab] = useState(`0`);

    const banners = useMemo(
        () => initBanners(page.contentObject.factory_ruland__steps),
        [page.contentObject.factory_ruland__steps],
    );

    return (
        <div className="mb-10">
            <h4 className="mb-5">Завод Ruland</h4>
            <MultiLanguages
                languageElement={(language) => (
                    <div>
                        <Input
                            label="Заголовок"
                            defaultValue={page.contentObject.factory_ruland__title[language]}
                            disabled
                        />
                        <TextArea
                            label="Описание"
                            name={`content[factory_ruland__description][${language}]`}
                            defaultValue={page.contentObject.factory_ruland__description[language]}
                        />
                    </div>
                )}
            />
            <div className="mb-5">
                <Tabs className="mb-3" activeKey={tab} onSelect={(key) => setTab(key as string)} fill>
                    {banners.map((banner, index) => (
                        <Tab key={index} title={`Этап ${index + 1}`} eventKey={index}>
                            <MultiLanguages
                                languageElement={(language) => (
                                    <div>
                                        <Input
                                            label="Название этапа"
                                            name={`content[factory_ruland__steps][${index}][title][${language}]`}
                                            defaultValue={banner.title[language]}
                                        />
                                        <Input
                                            label="Подзаголовок"
                                            name={`content[factory_ruland__steps][${index}][sub_title][${language}]`}
                                            defaultValue={banner.sub_title[language]}
                                        />
                                        <TextArea
                                            label="Описание этапа"
                                            name={`content[factory_ruland__steps][${index}][description][${language}]`}
                                            defaultValue={banner.description[language]}
                                        />
                                    </div>
                                )}
                            />

                            <ImageInput
                                label="Изображение"
                                name={`content[factory_ruland__steps][${index}][file_id]`}
                                defaultValue={banner.file}
                                width="660px"
                                height="440px"
                            />
                        </Tab>
                    ))}
                </Tabs>
            </div>
        </div>
    );
}

function initBanners(steps: AboutPageFactoryRulandStepsModel[] | null) {
    return (
        steps || [
            {
                file: undefined,
                title: initLanguageKeyObject(),
                sub_title: initLanguageKeyObject(),
                description: initLanguageKeyObject(),
            },
            {
                file: undefined,
                title: initLanguageKeyObject(),
                sub_title: initLanguageKeyObject(),
                description: initLanguageKeyObject(),
            },
            {
                file: undefined,
                title: initLanguageKeyObject(),
                sub_title: initLanguageKeyObject(),
                description: initLanguageKeyObject(),
            },
            {
                file: undefined,
                title: initLanguageKeyObject(),
                sub_title: initLanguageKeyObject(),
                description: initLanguageKeyObject(),
            },
            {
                file: undefined,
                title: initLanguageKeyObject(),
                sub_title: initLanguageKeyObject(),
                description: initLanguageKeyObject(),
            },
            {
                file: undefined,
                title: initLanguageKeyObject(),
                sub_title: initLanguageKeyObject(),
                description: initLanguageKeyObject(),
            },
        ]
    );
}
